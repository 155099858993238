import { ShippingType } from "./ShippingType"
import { ProductType, SaleTypeCode } from "./ProductType"
import { UserType } from "./UserType"
import { PaymentType } from "./PaymentType"
import { OrderType } from "./SellOrderType"
import { ShippingMethod } from "./ShippingMethod"

export const enum BuyOrderStatusCode {
  결제_대기 = "결제 대기",
  배송_준비 = "배송 준비",
  상품_준비 = "상품 준비",
  진단중 = "진단중",
  배송중 = "배송중",
  결제_만료 = "결제 만료",
  구매_완료 = "구매 완료",
  구매_취소 = "구매 취소",
  반품_요청완료 = "반품 요청 완료",
  반품_입고중 = "반품 입고중",
  반품_입고완료 = "반품 입고 완료",
  반품_완료 = "반품 완료",
  반품_불가 = "반품 불가",
  반송_완료 = "반송 완료",

  구매_실패 = "구매 실패",
  결제_취소 = "결제 취소",
  결제_실패 = "결제 실패",
}

export type BuyOrderStatusGroupType = "주문 완료" | "배송중" | "구매 완료"

export const enum BuyOrderFailedReasonTypeCode {
  결제_실패 = "결제 실패",
  상품_품절 = "상품 품절",
  포인트_부족 = "포인트 부족",
}

export const enum DiscountReasonTypeCode {
  이벤트 = "이벤트",
  진단비무료 = "진단비무료",
}

export declare interface BuyOrderType {
  id?: string
  buyer?: UserType
  buyOrderStatus?: BuyOrderStatusCode
  failedReasonType?: BuyOrderFailedReasonTypeCode
  product?: ProductType
  productName?: string
  productPrice?: number
  shippingFee?: number
  shippingFeeRate?: number
  diagnosisFee?: number
  advancedDiagnosisFee?: number
  advancedDiagnosis?: boolean
  buyingFee?: number
  buyingFeeRate?: number
  buyingFeeDiscountAmount?: number
  buyingFeeDiscountReasonType?: DiscountReasonTypeCode
  totalAmount?: number
  diagnosisFeeDiscountAmount?: number
  diagnosisFeeDiscountReasonType?: DiscountReasonTypeCode
  shippingFeeDiscountAmount?: number
  shippingFeeDiscountReasonType?: DiscountReasonTypeCode
  pointAmount?: number
  discountAmount?: number
  paymentAmount?: number
  taxAmount?: number
  taxFreeAmount?: number
  orderedAt?: string
  startShippingAt?: string
  completeShippingAt?: string
  completedAt?: string
  canceledAt?: string
  ipAddress?: string
  shipping?: ShippingType
  payment?: PaymentType
  payments?: PaymentType[]
  currency?: string
  paymentStatement?: BuyOrderPaymentStatements
}

export type BuyOrderListSearchParams = {
  productId?: string
  startDate?: string
  endDate?: string
  status?: BuyOrderStatusCode[]
  buyerId?: string
  productSaleType?: SaleTypeCode[]
  sellOrderType?: OrderType[]
  shippingMethod?: ShippingMethod
  sellerName?: string
  sellerPhone?: string
  productTitle?: string
  sellerId?: string
  foreigner?: string,
  overseasForeigner?: string,

  page?: number
  size?: number
}

export type BuyOrderPaymentStatements = {
  productPrice?: number
  pointAmount?: number
  paymentAmount?: number
  totalAmount?: number
  feeAmount?: number
  negotiatedAmount?: number
  cardDiscountAmount?: number
  discountAmount?: number
  currency?: number
  discounts?: BuyOrderDiscountType[]
}

export type BuyOrderDiscountType = {
  type?: string
  name?: string
  amount?: number
}
