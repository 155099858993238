import styled from "@emotion/styled"
import Modal from "components/Modal"
import React, { FC, useCallback, useRef, useState } from "react"
import { Input, MenuItem, Select } from "@mui/material"
import Button from "components/Button"
import { useModelRequestDetailData } from "./useModelRequestDetailData"
import { EditableImage } from "./EditableImage"

import { Link } from "react-router-dom"
import SearchDetailModelPopup from "components/Popup/SearchDetailModelPopup"
import { DetailModelType } from "types/DetailModelType"
import ModelRegistrationRequestRejectReasonPopup from "./ModelRegistrationRequestRejectReasonPopup"
import { toDateTimeStr } from "../../../utils/datetimeUtil"

type Props = {
  isOpen: boolean
  closeModal: () => void
  requestId: null | number
  onRefreshList: () => void
}

const ModelRegistrationRequestFormPopup: FC<Props> = ({ requestId, isOpen, closeModal, onRefreshList }) => {
  const [isOpenRegisteredModelFinder, setIsOpenRegisteredModelFinder] = useState<boolean>(false)
  const [isOpenRejectReasonPopup, setIsOpenRejectReasonPopup] = useState<boolean>(false)

  const refreshAndCloseModal = useCallback(() => {
    onRefreshList()
    closeModal()
  }, [onRefreshList, closeModal])

  const {
    buttonStatus,
    formState,
    originalRequestDetail,
    brandOptionList,
    modelOptionList,
    onSelectBrandOption,
    onSelectModelOption,
    onChangeFormState,
    onDeleteImage,
    resetFormData,
    onAddImage,
    handleSaveModelRequest,
    handleRejectModelRequest,
    handleAcceptModelRequest,
    handleCancelModelRequest,
  } = useModelRequestDetailData(requestId, refreshAndCloseModal)

  const ref = useRef<HTMLInputElement>(null)

  const handleClickInput = () => {
    if (!ref.current) return

    ref.current.click()
  }

  const handleChangeImage = async () => {
    const files = ref.current?.files

    if (files) onAddImage(files)
  }

  const closeAndClearModalForm = useCallback(() => {
    closeModal()
    resetFormData()
  }, [closeModal, resetFormData])

  return (
    <>
      {isOpenRegisteredModelFinder && (
        <SearchDetailModelPopup
          zIndex={10000}
          open={isOpenRegisteredModelFinder}
          handleClose={(model: DetailModelType) => {
            if (!!model) {
              handleCancelModelRequest(model.id?.toString() || "")
            }
            setIsOpenRegisteredModelFinder(false)
          }}
        />
      )}
      {isOpenRejectReasonPopup && (
        <ModelRegistrationRequestRejectReasonPopup
          open={isOpenRejectReasonPopup}
          zIndex={10000}
          handleClose={(rejectReason: string | null) => {
            if (!!rejectReason) {
              handleRejectModelRequest(rejectReason || "")
            }
            setIsOpenRejectReasonPopup(false)
          }}
        />
      )}
      <Modal isOpen={isOpen} closeModal={closeAndClearModalForm}>
        <ModalContent>
          <ModalContentHeader>
            <h2>모델 등록 요청</h2>
            <div className="buttonListWrapper">
              <ActionButton
                action="모델중복"
                disabled={!buttonStatus.activeCancel}
                onClick={() => {
                  setIsOpenRegisteredModelFinder(true)
                }}
              >
                등록 모델과 중복
              </ActionButton>
              <ActionButton action="거절" disabled={!buttonStatus.activeReject} onClick={() => {
                setIsOpenRejectReasonPopup(true)
              }}>
                거절
              </ActionButton>
              <ActionButton action="승인" disabled={!buttonStatus.activeAccept} onClick={handleAcceptModelRequest}>
                승인
              </ActionButton>
            </div>
          </ModalContentHeader>
          <ModalContentBody>
            {!!originalRequestDetail?.detailModel && (
              <div style={{ marginBottom: "16px" }}>
                <h3>
                  연결된 DB{" "}
                  <Link
                    target={"_blank"}
                    to={`/model-manager/model-db/${originalRequestDetail.model.id}?brandSid=${originalRequestDetail.brand.sid}&id=${originalRequestDetail.detailModel.id}`}
                  >
                    {originalRequestDetail.detailModel.id}
                  </Link>
                </h3>
              </div>
            )}
            <h3>모델 기본 정보</h3>
            <div className="inlineWrapper">
              <div className="label">브랜드</div>

              <div className="valueWrapper">
                <Select
                  variant="standard"
                  size={"small"}
                  required
                  value={formState.brand.sid}
                  onChange={(e) => onSelectBrandOption(e.target.value)}
                  style={{ width: "200px" }}
                  MenuProps={{
                    style: {
                      zIndex: 9999,
                    },
                  }}
                >
                  {brandOptionList.map((menu) => (
                    <MenuItem value={menu.value} key={menu.title}>
                      {menu.title}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="inlineWrapper">
              <div className="label">모델명</div>
              <div className="valueWrapper">
                <Select
                  variant="standard"
                  size={"small"}
                  value={formState.model.id}
                  required
                  onChange={(e) => {
                    onSelectModelOption(e.target.value)
                  }}
                  style={{ width: "200px" }}
                  MenuProps={{
                    style: {
                      zIndex: 9999,
                    },
                  }}
                >
                  {modelOptionList.map((menu) => (
                    <MenuItem value={menu.value.toString()} key={menu.title}>
                      {menu.title}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="inlineWrapper">
              <div className="label">상세모델명</div>
              <div className="valueWrapper">
                <Input
                  placeholder="ex) Submariner Date"
                  value={formState.name}
                  onChange={(e) => onChangeFormState("name", e.target.value)}
                />
              </div>
            </div>
            <div className="inlineWrapper">
              <div className="label">Ref No.</div>
              <div className="valueWrapper" style={{ flex: 1 }}>
                <Input
                  placeholder="ex) 126610LN"
                  style={{ width: "100%" }}
                  value={formState.refNo}
                  onChange={(e) => onChangeFormState("refNo", e.target.value)}
                />
              </div>
            </div>
            <div className="inlineWrapper">
              <div className="label">Full Ref.</div>
              <div className="valueWrapper" style={{ flex: 1 }}>
                <Input
                  placeholder="ex) 126610LN-00001"
                  style={{ width: "100%" }}
                  value={formState.fullRefNo}
                  onChange={(e) => onChangeFormState("fullRefNo", e.target.value)}
                />
              </div>
            </div>
            <div className="inlineWrapper" style={{ marginTop: "24px", alignItems: "flex-start" }}>
              <div className="label">
                요청
                <br />
                특이사항
              </div>
              <textarea
                placeholder="코멘트를 입력해주세요."
                value={formState.note}
                onChange={(e) => onChangeFormState("note", e.target.value)}
              />
            </div>
            <div className="inlineWrapper" style={{ marginTop: "8px" }}>
              <div className="label">사진</div>
              <div>
                <div>
                  <InvisibleInput ref={ref} type="file" onChange={handleChangeImage} />
                  <ImageUloadButton onClick={handleClickInput}>이미지 업로드</ImageUloadButton>
                </div>
                <ImageListWrapper>
                  {formState.resources.map((image) => {
                    return (
                      <EditableImage
                        key={image.id}
                        url={image.url}
                        id={image.id}
                        deleteImage={() => onDeleteImage(image.id)}
                      />
                    )
                  })}
                </ImageListWrapper>
              </div>
            </div>
            {formState.rejectedAt && (
              <div className="inlineWrapper" style={{ marginTop: "24px", alignItems: "flex-start" }}>
                <div className="label">
                  거절이력
                </div>
                <div className="valueWrapper" style={{ flex: 1 }}>
                  <table className={"basic"}>
                    <tbody>
                    <tr>
                      <td className={"header"} width={"30%"}>일시</td>
                      <td className={"header"}>사유</td>
                      <td className={"header"} width={"30%"}>담당자</td>
                    </tr>
                    <tr>
                      <td>{toDateTimeStr(formState.rejectedAt)}</td>
                      <td>{formState.rejectedReason}</td>
                      <td>{formState.rejectedBy}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>)}
          </ModalContentBody>
          <ModalContentFooter>
            <div className="buttonListWrapper">
              <Button
                style={{ width: "100px", marginRight: "8px" }}
                disabled={!buttonStatus.activeSave}
                onClick={handleSaveModelRequest}
              >
                저장
              </Button>
              <Button buttonStyle="black-border" style={{ width: "100px" }} onClick={closeAndClearModalForm}>
                닫기
              </Button>
            </div>
          </ModalContentFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModelRegistrationRequestFormPopup

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;

    width: 1000px;
    height: 90vh;
    flex-shrink: 0;
`

const ModalContentHeader = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    padding: 44px 16px 42px 50px;

    h2 {
        font-size: 24px;
        font-weight: 700;
        margin: 0;
    }

    div.buttonListWrapper {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        button {
            display: flex;
            align-items: center;
            border-radius: 6px;
            border: none;

            padding: 8px 16px;
            font-size: 15px;
            font-weight: 500;
            color: #fff;

            &:not(:last-child) {
                margin-right: 8px;
            }

            :disabled {
                background: #f7f8fa;
                color: #ced2d6;
            }
        }
    }
`

const ModalContentBody = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding: 0 48px;
    flex: 1;
    overflow: scroll;

    h3 {
        font-size: 15px;
        font-weight: 700;
        margin: 0 0 16px 0;
    }

    div.inlineWrapper {
        display: inline-flex;
        align-items: center;
        margin-bottom: 24px;
    }

    div.valueWrapper {
        height: 38px;
        display: flex;
        align-items: center;
    }

    div.label {
        width: 72px;
        font-size: 14px;
    }

    textarea {
        border-radius: 4px;
        outline: none;

        flex: 1;
        height: 160px;
        border: 1px solid #ced2d6;
        padding: 16px;
        font-family: Pretendard;

        ::placeholder {
            font-size: 16px;
            color: #8f98a1;
        }
    }
`

const ModalContentFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 16px;

    div.buttonListWrapper {
        display: inline-flex;
        align-items: center;
    }

    div.buttonWrapper {
        width: 100px;
    }
`

const InvisibleInput = styled.input`
    visibility: hidden;
    width: 0px;
    height: 0px;
`

const ImageUloadButton = styled.button`
    outline: none;
    border: 1px solid #b7bec4;
    border-radius: 6px;
    background: #fff;

    width: 95px;
    height: 28px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
`

const ImageListWrapper = styled.div`
    margin-top: 8px;
    display: grid;
    grid-template-columns: 3fr 3fr 3fr;
    grid-gap: 8px;
`

const ActionButton = styled.button<{ action: "거절" | "승인" | "모델중복" }>`
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: none;

    padding: 8px 16px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;

    &:not(:last-child) {
        margin-right: 8px;
    }

    :disabled {
        background: #f7f8fa;
        color: #ced2d6;
    }

    ${({ action }) => {
        switch (action) {
            case "모델중복":
                return `background: #0D578D;
        `
            case "거절":
                return "background: #D32F2F;"

            case "승인":
                return "background: #2E7D32;"
        }
    }}
`
