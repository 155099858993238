import styled from "@emotion/styled"
import {
  KindOfTermsType,
  TermsAgreementType,
  UserDetailType,
  UserTypeChangeParams,
  UserTypeChangeType,
  UserTypeCode,
} from "types/UserType"
import Button from "components/Button"
import DetailTableBody from "components/Table/Detail/TableBody"
import DetailTableRowCell from "components/Table/Detail/TableBody/TableCell/Row"
import * as React from "react"
import { useState } from "react"
import _, { isEmpty } from "lodash"
import UserStatusChangeModal from "./StatusChangeModal"
import UserTypeChangeModal from "./TypeChangeModal"
import UserNickNameChangeModal from "./NicknameChangeModal"
import UserPhonenNumberChangeModal from "./PhoneNumberChangeModal"
import UserMemoChangeModal from "./MemoChangeModal"
import { useNavigate } from "react-router-dom"
import { toDateStr } from "../../../../../utils/datetimeUtil"
import { postSuperLogin } from "../../../../../apis/userAPI"
import { toast } from "react-toastify"
import { serviceUrl } from "../../../../../utils/httpAPI"
import { TextField } from "@mui/material"
import CustomsCodeChangeModal from "./CustomsCodeChangeModal"
import { getFlagEmoji } from "../../../../../utils/countryUtils"
import EmailChangeModal from "./EmailChangeModal"
import { resetUserBuyOfferPenalty } from "../../../../../apis/penaltyAPI"
import SktUniversePassHistoryModal from "./SktUniversePassHistoryModal"

type Props = {
  user: UserDetailType
  userPoint: number
  changeNickname: (nickname: string) => void
  changePhoneNumber: (phoneNumber: string) => void
  saveMemo: (memo: string) => void
  deleteMemo: (memoId: number) => void
  setUserDetail: React.Dispatch<React.SetStateAction<UserDetailType>>
  setSuperLoginRefresh: React.Dispatch<React.SetStateAction<boolean>>
  transformToBusinessUser: (params: UserTypeChangeParams) => void
}

const UserInformationTable = ({
  user,
  userPoint,
  changeNickname,
  changePhoneNumber,
  saveMemo,
  deleteMemo,
  setUserDetail,
  setSuperLoginRefresh,
  transformToBusinessUser,
}: Props) => {
  const navigate = useNavigate()
  const [isUserStatusModalOpen, setIsUserStatusModalOpen] = useState(false)
  const [isUserTypeModalOpen, setIsUserTypeModalOpen] = useState(false)
  const [isUserNicnameChangeModalOpen, setIsUserNicknameChangeModalOpen] = useState(false)
  const [isUserPhoneNumberChangeModalOpen, setIsUserPhoneNumberChangeModalOpen] = useState(false)
  const [isUserMemoChangeModalOpen, setIsUserMemoChangeModalOpen] = useState(false)
  const [isUserCustomsCodeChangeModalOpen, setIsUserCustomsCodeChangeModalOpen] = useState(false)
  const [isSktUniversePassHistoryModalOpen, setIsSktUniversePassHistoryModalOpen] = useState(false)
  const [isUserEmailChangeModalOpen, setIsUserEmailChangeModalOpen] = useState(false)
  const [superLoginReason, setSuperLoginReason] = useState("")

  const parseTermsValue = (kindOfTerm: KindOfTermsType, parsingKey: keyof TermsAgreementType) => {
    if (!user.termsAgreement) {
      return
    }

    const parsedTerm = _.find(user.termsAgreement, (term) => term.terms.type === kindOfTerm)
    return parsedTerm?.[parsingKey]
  }

  const superLogin = (userId: number) => {
    if (isEmpty(superLoginReason)) {
      window.alert("회원 계정으로 로그인 시 사유 입력은\n필수입니다. 사유를 입력해 주세요.")
      return
    }

    if (
      window.confirm(
        "선택한 회원 계정으로 로그인합니다. 업무이외 용도로 사용 시 개인정보 유출에 따른 법적 처벌 대상이 될 수 있습니다.\n\n동의하시면 확인 버튼을 눌러주세요."
      )
    ) {
      setSuperLoginReason("")
      postSuperLogin(userId, superLoginReason)
        .then((data) => {
          window.open(`${serviceUrl}/login/repus?verifyKey=${data.verifyKey}`, "_blank")
          setSuperLoginRefresh((prev) => !prev)
        })
        .catch((error) => {
          toast.error(error.message)
        })
    }
  }

  const replaceBooleanValueToString = (boolean: boolean) => {
    return boolean ? "O" : "X"
  }

  const handleBuyOfferPenaltyReset = (id: number) => {
    if (window.confirm("패널티 해제 처리 하시겠습니까?")) {
      resetUserBuyOfferPenalty({ id })
        .then((buyOfferPenalty) => {
          setUserDetail((prev) => ({
            ...prev,
            buyOfferPenalty,
          }))
          toast.success("패널티 해제 처리했습니다.")
        })
        .catch((error) => {
          toast.error(`${error.message || error}`)
        })
    }
  }

  if (!user.id) {
    return <></>
  }

  return (
    <>
      <DetailTableBody>
        <TitleWrapper>회원정보</TitleWrapper>
        <ColumnWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="회원번호">
              <CellContentRowWrapper>
                {user.id}
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }}>
                  <SuperLoginInputWrapper>
                    <TextField
                      style={{ width: "240px" }}
                      InputProps={{
                        inputProps: {
                          style: { fontSize: 12 },
                        },
                      }}
                      placeholder={"로그인 사유 입력"}
                      size={"small"}
                      value={superLoginReason}
                      onChange={(e) => {
                        setSuperLoginReason(e.target.value)
                      }}
                    />
                  </SuperLoginInputWrapper>
                  <SuperLoginButtonWrapper>
                    <Button buttonStyle="blue-fill" buttonSize="S" onClick={() => superLogin(user.id)}>
                      회원 계정으로 로그인
                    </Button>
                  </SuperLoginButtonWrapper>
                </div>
              </CellContentRowWrapper>
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="회원 상태 / 구분">
              <CellContentRowWrapper>
                {user.status} / {user.userType}
                {/* 상세 기능 개발 후 추가 */}
                {/*<ButtonWrapper>*/}
                {/*  <Button buttonStyle="blue-fill" buttonSize="S" onClick={() => setIsUserStatusModalOpen(true)}>*/}
                {/*    관리*/}
                {/*  </Button>*/}
                {/*</ButtonWrapper>*/}
                <ButtonWrapper>
                  <Button
                    buttonStyle="blue-fill"
                    buttonSize="S"
                    disabled={user.userType != UserTypeCode.개인}
                    onClick={() => setIsUserTypeModalOpen(true)}
                  >
                    관리
                  </Button>
                </ButtonWrapper>
              </CellContentRowWrapper>
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="로그인 방식">
              {user.authType}
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="회원 국가">
              {getFlagEmoji(user.country?.id ?? "KR")} {user.country?.korName}
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="회원가입일">
              {user.signupAt ? `${toDateStr(user.signupAt)}` : "-"}
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="이메일">
              <CellContentRowWrapper>
                {user.email}
                <ButtonWrapperLong>
                  <Button
                    buttonStyle="blue-fill"
                    disabled={user.isOverseasForeigner}
                    buttonSize={"S"}
                    onClick={() => setIsUserEmailChangeModalOpen(true)}
                  >
                    이메일 주소 변경
                  </Button>
                </ButtonWrapperLong>
              </CellContentRowWrapper>
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="약관동의일">
              {user.termsAgreement && toDateStr(parseTermsValue("이용약관", "updatedAt") as string)}
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="닉네임">
              <CellContentRowWrapper>
                {user.nickname}
                {/*<ButtonWrapper>*/}
                {/*  <Button buttonStyle="blue-fill" buttonSize="S" onClick={() => setIsUserNicknameChangeModalOpen(true)}>*/}
                {/*    변경*/}
                {/*  </Button>*/}
                {/*</ButtonWrapper>*/}
              </CellContentRowWrapper>
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="개인정보 수집·이용동의일">
              {user.termsAgreement && toDateStr(parseTermsValue("개인정보", "updatedAt") as string)}
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="이름">
              {user.name}
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="광고 수신 동의">
              {replaceBooleanValueToString(user.receiveAdPush)}
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="기본 주소">
              {user.address?.address} {user.address?.addressDetail}
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="거래 알림 수신">
              {replaceBooleanValueToString(user.receiveOrderPush)}
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="연락처 국가">
              {getFlagEmoji(user.phoneCountry?.id ?? "KR")} {user.phoneCountry?.korName}
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="연락처">
              <CellContentRowWrapper>
                {user.phoneCountry?.dialCode} {user.carrier} {user.phone}
                {/*<ButtonWrapper>*/}
                {/*  <Button*/}
                {/*    buttonStyle="blue-fill"*/}
                {/*    buttonSize="S"*/}
                {/*    onClick={() => setIsUserPhoneNumberChangeModalOpen(true)}*/}
                {/*  >*/}
                {/*    변경*/}
                {/*  </Button>*/}
                {/*</ButtonWrapper>*/}
              </CellContentRowWrapper>
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="휴면전환 안내일">
              {user.dormantScheduledDate}
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="바이버 페이">
              {replaceBooleanValueToString(user.hasViverPayDefaultCard)}
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="포인트">
              <CellContentRowWrapper>
                {userPoint}
                <ButtonWrapper>
                  <Button
                    buttonStyle="blue-fill"
                    disabled={user.isOverseasForeigner}
                    buttonSize="S"
                    onClick={() => navigate(`/points/${user.id}`)}
                  >
                    관리
                  </Button>
                </ButtonWrapper>
              </CellContentRowWrapper>
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="정산계좌">
              {user.bankAccount?.bank.name} {user.bankAccount?.accountNumber} {user.bankAccount?.accountHolder}
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="미납 패널티">
              패널티
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="해외 판매 동의 설정">
              {replaceBooleanValueToString(user.globalSale)}
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="통관고유부호">
              <CellContentRowWrapper>
                {user.customsCode || "-"}
                <ButtonWrapper>
                  <Button
                    buttonStyle="blue-fill"
                    buttonSize="S"
                    onClick={() => setIsUserCustomsCodeChangeModalOpen(true)}
                  >
                    관리
                  </Button>
                </ButtonWrapper>
              </CellContentRowWrapper>
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth="150px" title="메모">
              <CellContentRowWrapper>
                <MemoListWRapper>
                  {!!user.memos.length &&
                    user.memos.map((memoObject) => <MemoWrapper key={memoObject.id}>{memoObject.memo}</MemoWrapper>)}
                </MemoListWRapper>
                <ButtonWrapper>
                  <Button buttonStyle="blue-fill" buttonSize="S" onClick={() => setIsUserMemoChangeModalOpen(true)}>
                    관리
                  </Button>
                </ButtonWrapper>
              </CellContentRowWrapper>
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="구매 입찰 패널티">
              <CellContentRowWrapper>
                <Cell>{user.buyOfferPenalty?.penaltyStatus ? `${user.buyOfferPenalty?.penaltyStatus}` : "-"}</Cell>
                <Cell>
                  {user.buyOfferPenalty?.penaltyAt ? `${toDateStr(user.buyOfferPenalty?.penaltyAt)}` : "-"}
                  {user.buyOfferPenalty?.penaltyExpireAt
                    ? ` ~ ${toDateStr(user.buyOfferPenalty?.penaltyExpireAt)}`
                    : ""}
                </Cell>
                <Cell>
                  {user.buyOfferPenalty?.penaltyStatus ? `누적${user.buyOfferPenalty?.totalPenaltyCount}회` : "-"}
                </Cell>
                {user.buyOfferPenalty?.penaltyExpireAt ? (
                  <ButtonWrapper>
                    <Button
                      buttonStyle="blue-fill"
                      buttonSize={"M"}
                      onClick={() => handleBuyOfferPenaltyReset(user.id)}
                    >
                      패널티 해제
                    </Button>
                  </ButtonWrapper>
                ) : (
                  ""
                )}
              </CellContentRowWrapper>
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth="150px" title={"우주패스 구독"}>
              <CellContentRowWrapper>
                {user.sktUniversePass ? user.sktUniversePass.startDate + " ~ " + user.sktUniversePass.endDate : "-"}
                {user.sktUniversePass && (
                  <ButtonWrapperMiddle>
                    <Button
                      buttonStyle="blue-fill"
                      buttonSize="S"
                      onClick={() => setIsSktUniversePassHistoryModalOpen(true)}
                    >
                      이력 보기
                    </Button>
                  </ButtonWrapperMiddle>
                )}
              </CellContentRowWrapper>
            </DetailTableRowCell>
          </RowWrapper>
        </ColumnWrapper>
      </DetailTableBody>
      <UserStatusChangeModal isOpen={isUserStatusModalOpen} closeModal={() => setIsUserStatusModalOpen(false)} />
      <UserTypeChangeModal
        isOpen={isUserTypeModalOpen}
        closeModal={() => setIsUserTypeModalOpen(false)}
        transformToBusinessUser={transformToBusinessUser}
        userId={user.id}
      />
      <UserNickNameChangeModal
        isOpen={isUserNicnameChangeModalOpen}
        closeModal={() => setIsUserNicknameChangeModalOpen(false)}
        changeNickname={changeNickname}
        nickname={user.nickname || ""}
      />
      <UserPhonenNumberChangeModal
        isOpen={isUserPhoneNumberChangeModalOpen}
        closeModal={() => setIsUserPhoneNumberChangeModalOpen(false)}
        changePhoneNumber={changePhoneNumber}
        phoneNumber={user.phone || ""}
      />
      <UserMemoChangeModal
        isOpen={isUserMemoChangeModalOpen}
        closeModal={() => setIsUserMemoChangeModalOpen(false)}
        saveMemo={saveMemo}
        deleteMemo={deleteMemo}
        memos={user.memos}
      />
      <CustomsCodeChangeModal
        user={user}
        isOpen={isUserCustomsCodeChangeModalOpen}
        closeModal={() => setIsUserCustomsCodeChangeModalOpen(false)}
        setUserDetail={setUserDetail}
        customsCode={user.customsCode || ""}
      ></CustomsCodeChangeModal>
      <EmailChangeModal
        user={user}
        isOpen={isUserEmailChangeModalOpen}
        closeModal={() => setIsUserEmailChangeModalOpen(false)}
        setUserDetail={setUserDetail}
      ></EmailChangeModal>
      <SktUniversePassHistoryModal
        userId={user.id}
        isOpen={isSktUniversePassHistoryModalOpen}
        closeModal={() => setIsSktUniversePassHistoryModalOpen(false)}
      ></SktUniversePassHistoryModal>
    </>
  )
}

export default UserInformationTable

const TitleWrapper = styled.div`
  padding: 10px;
  background: #eee;
  border-bottom: 1px solid #ccc;
  font-weight: 800;
`

const RowWrapper = styled.div`
  width: 100%;
  display: inline-flex;

  :not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`

const ColumnWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ButtonWrapper = styled.div`
  width: 50px;
`

const ButtonWrapperMiddle = styled.div`
  width: 80px;
`

const ButtonWrapperLong = styled.div`
  width: 150px;
`

const SuperLoginButtonWrapper = styled.div`
  width: 140px;
`

const SuperLoginInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
`

const CellContentRowWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`

const MemoListWRapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
`

const MemoWrapper = styled.div`
  flex: 1;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;

  :not(:last-child) {
    margin-bottom: 4px;
  }
`
const Cell = styled.div`
  flex: 1;
  padding: 8px; /* 셀 내부에 여백 추가 */
  text-align: left; /* 왼쪽 정렬 */
  border-right: 1px solid #ccc; /* 셀 사이에 구분선 추가 */

  /* 마지막 셀에는 구분선이 보이지 않도록 설정 */
  &:last-child {
    border-right: none;
  }
`
