import React, { useEffect, useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  Select, MenuItem, FormControl,
} from "@mui/material"
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import { DateTime } from "luxon"
import { toast } from "react-toastify"
import { findUserByPhoneNumber, getUserDetail } from "../../apis/userAPI"

interface RequestPayoutPopupProps {
  open: boolean;
  onClose: () => void;
  onRequestAll: (payoutDate: string, sellerId: number | null) => Promise<any>;
  onRequestUser: (payoutDate: string, sellerId: number | null) => Promise<any>;
}

const shouldDisableDate = (date: DateTime) => {
  if (!date) return false;
  const day = date.weekday;
  return day === 6 || day === 7; // 일(7), 토(6) 비활성
};

export const RequestPayoutPopup: React.FC<RequestPayoutPopupProps> = ({ open, onClose, onRequestAll, onRequestUser }) => {
  const [allSelectedDate, setAllSelectedDate] = useState<any>('');
  const [userSelectedDate, setUserSelectedDate] = useState<any>('');
  const [userId, setUserId] = useState<number>();
  const [searchKey, setSearchKey] = useState('userId');
  const [searchValue, setSearchValue] = useState('');
  const [userInfo, setUserInfo] = useState<{id:number;name:string;phone:string;email:string}|null>(null);
  const [loadingAll, setLoadingAll] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);

  useEffect(() => {
    if (open) {
      setAllSelectedDate('');
      setUserSelectedDate('');
      setUserId(undefined);
      setSearchKey('userId');
      setSearchValue('');
      setUserInfo(null);
      setLoadingAll(false);
      setLoadingUser(false);
    }
  }, [open]);

  const handleAllRequest = () => {
    if (!allSelectedDate) {
      toast.error("날짜를 선택해주세요.")
      return;
    }
    setLoadingAll(true);
    // 미지급 건 전체 지급 요청
    onRequestAll(allSelectedDate, null).finally(() => setLoadingAll(false));
  };

  const handleUserRequest = () => {
    if (!userSelectedDate || !userId) {
      toast.error("날짜를 선택해주세요.")
      return;
    }
    setLoadingUser(true);
    // 사용자 지정 지급 요청
    onRequestUser(userSelectedDate, userId).finally(() => setLoadingUser(false));
  };

  const handleUserSearch = async () => {
    if (!searchKey) {
      return toast.error( "조회 항목을 선택해주세요.")
    }
    if (!searchValue) {
      return toast.error( "검색어를 입력해주세요.")
    }

    let userId = searchValue
    if (searchKey == "phone") {
      const [user] = await findUserByPhoneNumber([searchValue])
      if (!user.id) {
        setUserInfo(null)
        return toast.error("사용자 정보를 찾을 수 없습니다.")
      }
      userId = user.id.toString();
    }

    const userInfo = await getUserDetail(userId, false)

    if (!userInfo.id) {
      setUserInfo(null)
      return toast.error("사용자 정보를 찾을 수 없습니다.")
    }

    setUserId(userInfo.id)

    setUserInfo({
      id: userInfo.id,
      name: userInfo.name ?? "-",
      phone: userInfo.phone ?? "-",
      email: userInfo.email
    })
  };

  // @ts-ignore
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>정산 지급 요청</DialogTitle>
      <DialogContent>
        <Typography variant="h6" sx={{ mt: 2 }}>미지급 건 전체 지급 요청</Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          선택한 날짜 기준으로 모든 미지급 건에 대해 정산 지급 요청을 합니다.
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="body1">
            날짜 선택
          </Typography>
          <DesktopDatePicker
            inputFormat="yyyy-MM-dd"
            value={allSelectedDate || null}
            onChange={(e) => {
              setAllSelectedDate(e?.toISODate())
            }}
            shouldDisableDate={shouldDisableDate}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <LoadingButton variant="contained" onClick={handleAllRequest} loading={loadingAll}>
            지급 요청
          </LoadingButton>
        </Stack>
        <Typography variant="body2" sx={{ mt: 1 }}>
          • 오전 11시 전까지만 당일 지급 요청 가능<br />
          • 주말 및 캘린더에서 선택할 수 없는 날짜는 지급 요청 불가
        </Typography>

        <Box sx={{ mt: 4, borderTop: '1px solid #ddd', pt: 2 }}>
          <Typography variant="h6">사용자 지정 지급 요청</Typography>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 2 }}>
            <Typography variant="body1">
              사용자 조회
            </Typography>
            <FormControl sx={{ width: "160px" }} size={"small"}>
              <Select
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
              >
                <MenuItem value={"userId"}>사용자 ID</MenuItem>
                <MenuItem value={"phone"}>전화번호</MenuItem>
              </Select>
            </FormControl>
            <TextField
              size="small"
              sx={{ width: "200px" }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Button variant="outlined" size="small" onClick={handleUserSearch}>확인</Button>
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 2 }}>
            {userInfo && (
              <Typography variant="body1">
                사용자 정보
              </Typography>
            )}
            {userInfo && (
              <Paper variant="outlined" sx={{ mb: 2 }}>
                <TableContainer style={{minWidth: 500}} >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>사용자 ID</TableCell>
                        <TableCell>이름</TableCell>
                        <TableCell>연락처</TableCell>
                        <TableCell>바이버 계정</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{userInfo?.id}</TableCell>
                        <TableCell>{userInfo?.name}</TableCell>
                        <TableCell>{userInfo?.phone}</TableCell>
                        <TableCell>{userInfo?.email}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body1">
              날짜 선택
            </Typography>
            <DesktopDatePicker
              inputFormat="yyyy-MM-dd"
              value={userSelectedDate || null}
              onChange={(e) => setUserSelectedDate(e?.toISODate())}
              shouldDisableDate={shouldDisableDate}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
            <LoadingButton variant="contained" onClick={handleUserRequest} disabled={!userInfo || loadingUser} loading={loadingUser}>
              지급 요청
            </LoadingButton>
          </Stack>
          <Typography variant="body2" sx={{ mt: 1 }}>
            • 오전 11시 전까지만 당일 지급 요청 가능<br/>
            • 주말 및 캘린더에서 선택할 수 없는 날짜는 지급 요청 불가
          </Typography>
        </Box>

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>닫기</Button>
      </DialogActions>
    </Dialog>
  );
};
