import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import {
  downloadSettlementExcel,
  getSettlementList,
  requestPayout,
  SettlementListSearchParams,
} from "../../apis/settlementAPI"
import { SettlementStatusCode, SettlementType } from "../../types/SettlementType"
import { toast } from "react-toastify"
import { PageType } from "../../types/PageType"
import { CountType } from "../../types/CountType"
import { DateTime } from "luxon"

export type SettlementState = {
  selectIds: string[]
  settlements: PageType<SettlementType>
  settlementCount: CountType[]
  searchFilterKey: "productId" | "userId" | "payoutId"
  searchFilterValue: string
  refresh: boolean
  loading: boolean
  dateFilterKey: "paymentRequestDate" | "paymentApproveDate"
  startDateFilter?: DateTime | null
  endDateFilter?: DateTime | null
  status?: string
}

const initialState:SettlementState = {
  selectIds: [],
  settlements: {
    content: [],
    pageable: { pageSize: 25, pageNumber: 0 },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  },
  settlementCount: [],
  searchFilterKey: "productId",
  searchFilterValue: "",
  refresh: false,
  loading: false,
  dateFilterKey: "paymentRequestDate",
  startDateFilter: undefined,
  endDateFilter: undefined,
  status: "전체"
}

const settlementStatus: SettlementStatusCode[] = [
  SettlementStatusCode.지급_대기,
  SettlementStatusCode.지급_처리중,
  SettlementStatusCode.지급_요청_완료,
  SettlementStatusCode.지급_취소,
  SettlementStatusCode.지급_완료,
  SettlementStatusCode.지급_거절
]

export function useSettlementManager() {
  const [state, setState] = useState<SettlementState>(initialState)
  const [currentSearchParams] = useSearchParams()

  const fetchSettlementList = (searchParams:SettlementListSearchParams = {})=>{

    let newParams: SettlementListSearchParams = {
      ...searchParams,
      dateCriteria: state.dateFilterKey,
      startDate: state.startDateFilter?.toISODate(),
      endDate: state.endDateFilter?.toISODate(),
      status: (state.status?.split(",") as SettlementStatusCode[]) || settlementStatus
    }
    if (state.searchFilterKey === "productId") newParams.productId = state.searchFilterValue
    if (state.searchFilterKey === "userId") newParams.userId = state.searchFilterValue
    if (state.searchFilterKey === "payoutId") newParams.payoutId = state.searchFilterValue

    if (state.status === "전체") newParams.status = undefined

    getSettlementList(newParams).then((settlementItem)=>{
      setState((prevState)=>({
        ...prevState,
        settlements:settlementItem
      }))
    })
  }

  useEffect(()=>{
    fetchSettlementList({
      page: state.settlements.pageable?.pageNumber ?? 0,
      size: state.settlements.pageable?.pageSize ?? 25
    })
  }, [state.refresh, currentSearchParams])

  const handleExcelDownload = (params: any = {}) => {
    if (state.startDateFilter === undefined) {
      toast.error("시작일을 선택해주세요.")
      return false
    }
    if (state.endDateFilter === undefined) {
      toast.error("종료일을 선택해주세요.")
      return false
    }

    let newParams: SettlementListSearchParams = {
      ...params,
      dateCriteria: state.dateFilterKey,
      startDate: state.startDateFilter?.toISODate(),
      endDate: state.endDateFilter?.toISODate(),
      status: (state.status?.split(",") as SettlementStatusCode[]) || settlementStatus
    }
    if (state.searchFilterKey === "productId") newParams.productId = state.searchFilterValue
    if (state.searchFilterKey === "userId") newParams.userId = state.searchFilterValue
    if (state.searchFilterKey === "payoutId") newParams.payoutId = state.searchFilterValue

    if (state.status === "전체") newParams.status = undefined

    downloadSettlementExcel(newParams).then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `정산관리_정산.xlsx`)
      document.body.appendChild(link)
      link.click()
    })
  }

  const handleRequestPayout = (payoutDate: string, sellerId: number | null) => {
    if (payoutDate === undefined) {
      toast.error("날짜를 선택해주세요.")
      return Promise.reject("no date selected");
    }

    return requestPayout(payoutDate, sellerId)
      .then((response) => {
        alert("지급 요청이 완료되었습니다.")
        return response;
      })
      .catch(error => {
        if (error.httpStatus && error.message) {
          alert(error.message)
        } else {
          console.error('Unknown error:', error);
        }
        return Promise.reject(error);
      })
  }

  return {
    state,
    setState,
    fetchSettlementList,
    handleExcelDownload,
    handleRequestPayout
  }
}