import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import {
  CreateSellOrderFormType,
  CreateSellOrderRequestBodyType,
  OrderType,
  SellOrderProductType,
  ShippingMethodType,
} from "types/SellOrderType"
import { DetailModelType } from "types/DetailModelType"
import { getDetailModels } from "../../apis/detailModelAPI"
import { getResellPrice } from "../../apis/estimationAPI"
import { createSellOrder } from "../../apis/sellOrderAPI"
import { numberWithCommas, truncate, truncateWithCommas } from "../../utils/NumberUtils"
import { UserIdSelectPopup } from "./UserIdSelectPopup"
import { isEmpty } from "lodash"
import { isNotEmpty, isNotNil } from "utils/validationUtils"
import UserIdButton from "components/UserIdButton"
import { commonResourceUploadFile } from "apis/commonResourceAPI"
import { ShippingRegeditPopup } from "components/Popup/ShippingRegeditPopup"
import { getCodeDetailByCode, getDetailCodeValue } from "../../apis/codeDetailAPI"
import { CodeDetailType } from "../../types/CodeDetailType"
import { Switch } from "@mui/material"
import { UserDetailType } from "../../types/UserType"

type CreateSellOrderPopupProps = {
  open: boolean
  skipDiagnosis: boolean
  handleClose: Function
}

const initialSellOrderForm: CreateSellOrderFormType = {
  price: 0,
  sellOrderType: null,
  detailModelId: null,
  sellOrderProductType: SellOrderProductType.바이버,
  brandSid: null,
  mainProductCondition: null,
  officialGuarantee: null,
  accessoriesCondition: null,
  originalBoxCondition: null,
  accessories: {
    outerBox: null,
    innerBox: null,
    coscCert: null,
    userManual: null,
    guaranteeManual: null,
    guaranteeCase: null,
    medal: null,
    etc: null,
  },
  stampingYear: null,
  userId: null,
  shippingMethod: null,
  createShipping: null,
  selfCheck: {
    stab: null,
    glassScratch: null,
    braceletLinks: null,
    protectingFilm: null,
  },
  gallery: [],
  userDescription: "",
  productTitle: "",
  globalSale: false,
}

const CreateSellOrderPopup: React.FC<CreateSellOrderPopupProps> = ({ open, skipDiagnosis = false, handleClose }) => {
  const [sellOrderForm, setSellOrderForm] = useState<CreateSellOrderFormType>(initialSellOrderForm)
  const [detailModelInputMode, setDetailModelInputMode] = useState<"노출중모델" | "기타모델">("노출중모델")
  const [detailModels, setDetailModels] = useState<DetailModelType[]>([])
  const [detailModelsEtc, setDetailModelsEtc] = useState<DetailModelType[]>([])
  const [selectedDetailModel, setSelectedDetailModel] = useState<DetailModelType | null>(null)
  const [sellOrderProductType, setSellOrderProductType] = useState<CodeDetailType[] | null>(null)

  const [estimatedPrice, setEstimatedPrice] = useState<string>("")
  const [saving, setSaving] = useState<boolean>(false)
  const [openUserIdSelectPopup, setOpenUserIdSelectPopup] = useState(false)
  const [openShippingRegeditPopup, setOpenShippingRegeditPopup] = useState(false)
  const [seller, setSeller] = useState<UserDetailType | null>(null)
  const [globalSaleCap, setGlobalSaleCap] = useState<number>(0)

  const handleCreate = () => {
    if (!sellOrderForm.sellOrderType) return toast.error("판매방식을 선택해주세요")
    if (!sellOrderForm.mainProductCondition) return toast.error("제품상태를 선택해주세요")
    if (!sellOrderForm.officialGuarantee) return toast.error("보증서 유무를 선택해주세요")
    if (!sellOrderForm.originalBoxCondition) return toast.error("정품박스 유무를 선택해주세요.")
    if (!sellOrderForm.accessoriesCondition) return toast.error("부속품 상태를 선택해주세요")
    if (sellOrderForm.officialGuarantee === "있음" && !sellOrderForm.stampingYear) {
      return toast.error("보증서의 스탬핑 연도를 입력해주세요")
    }

    if (sellOrderForm.sellOrderType === OrderType.직매입) {
      if (!sellOrderForm.detailModelId) return toast.error("상세모델을 선택해주세요")
      const sellOrderRequestBody: CreateSellOrderRequestBodyType = {
        skipDiagnosis,
        sellOrderType: sellOrderForm.sellOrderType,
        sellOrderProductType: sellOrderForm.sellOrderProductType,
        detailModelId: sellOrderForm.detailModelId,
        brandSid: sellOrderForm.brandSid,
        price: sellOrderForm.price,
        mainProductCondition: sellOrderForm.mainProductCondition,
        originalBoxCondition: sellOrderForm.originalBoxCondition,
        officialGuarantee: sellOrderForm.officialGuarantee,
        accessoriesCondition: sellOrderForm.accessoriesCondition,
        accessories: sellOrderForm.accessories,
        stampingYear: sellOrderForm.stampingYear,
        globalSale: sellOrderForm.globalSale,
      }
      setSaving(true)
      createSellOrder(sellOrderRequestBody)
        .then(() => {
          toast.success("직매입 입고 완료")
          handleClose()
        })
        .catch((error) => toast.error(`직매입 입고 실패 (${error.message || error})`))
        .finally(() => setSaving(false))
    }

    if (sellOrderForm.sellOrderType === OrderType.위탁) {
      if (!sellOrderForm.detailModelId) return toast.error("상세모델을 선택해주세요")
      if (!sellOrderForm.userId) return toast.error("판매자ID를 입력해주세요")
      if (!sellOrderForm.shippingMethod) return toast.error("배송방식을 선택해주세요")

      const sellOrderRequestBody: CreateSellOrderRequestBodyType = {
        sellOrderType: sellOrderForm.sellOrderType,
        sellOrderProductType: sellOrderForm.sellOrderProductType,
        detailModelId: sellOrderForm.detailModelId,
        brandSid: sellOrderForm.brandSid,
        price: sellOrderForm.price,
        mainProductCondition: sellOrderForm.mainProductCondition,
        officialGuarantee: sellOrderForm.officialGuarantee,
        originalBoxCondition: sellOrderForm.originalBoxCondition,
        accessoriesCondition: sellOrderForm.accessoriesCondition,
        accessories: sellOrderForm.accessories,
        stampingYear: sellOrderForm.stampingYear,
        userId: sellOrderForm.userId,
        shippingMethod: sellOrderForm.shippingMethod,
        globalSale: sellOrderForm.globalSale,
      }
      if (isNotNil(sellOrderForm.createShipping)) {
        sellOrderRequestBody.createShipping = sellOrderForm.createShipping
      }

      setSaving(true)
      createSellOrder(sellOrderRequestBody)
        .then(() => {
          toast.success("위탁판매 신청 완료")
          handleClose()
        })
        .catch((error) => toast.error(`위탁판매 신청 실패 (${error.message || error})`))
        .finally(() => setSaving(false))
    }

    if (sellOrderForm.sellOrderType === OrderType.진단전) {
      // 진단전판매 기타모델인 경우 detailModelId: null, brandSid 포함해서 요청
      // if (!sellOrderForm.detailModelId) return toast.error("상품명을 선택해주세요")
      if (!sellOrderForm.userId) return toast.error("판매자ID를 입력해주세요")
      if (!sellOrderForm.shippingMethod) return toast.error("배송방식을 선택해주세요")

      if (!sellOrderForm.selfCheck.stab) return toast.error("찍힘/눌림 상태를 입력해주세요")
      if (!sellOrderForm.selfCheck.glassScratch) return toast.error("글라스 흠집 상태를 입력해주세요")
      if (!sellOrderForm.selfCheck.braceletLinks) return toast.error("링크 상태를 입력해주세요")
      if (!sellOrderForm.selfCheck.protectingFilm) return toast.error("보호필름 상태를 입력해주세요")
      if (isEmpty(sellOrderForm.gallery)) return toast.error("사진을 1장 이상 등록해주세요.")
      if (sellOrderForm.userDescription.length < 20) return toast.error("설명을 20자 이상 입력해주세요.")

      const sellOrderRequestBody: CreateSellOrderRequestBodyType = {
        sellOrderType: sellOrderForm.sellOrderType,
        sellOrderProductType: sellOrderForm.sellOrderProductType,
        detailModelId: sellOrderForm.detailModelId,
        brandSid: sellOrderForm.brandSid,
        price: sellOrderForm.price,
        mainProductCondition: sellOrderForm.mainProductCondition,
        officialGuarantee: sellOrderForm.officialGuarantee,
        accessoriesCondition: sellOrderForm.accessoriesCondition,
        originalBoxCondition: sellOrderForm.originalBoxCondition,
        accessories: sellOrderForm.accessories,
        stampingYear: sellOrderForm.stampingYear,
        userId: sellOrderForm.userId,
        shippingMethod: sellOrderForm.shippingMethod,
        selfCheck: {
          stab: sellOrderForm.selfCheck.stab,
          glassScratch: sellOrderForm.selfCheck.glassScratch,
          braceletLinks: sellOrderForm.selfCheck.braceletLinks,
          protectingFilm: sellOrderForm.selfCheck.protectingFilm,
        },
        gallery: sellOrderForm.gallery,
        userDescription: sellOrderForm.userDescription,
        productTitle: sellOrderForm.productTitle,
        globalSale: sellOrderForm.globalSale,
      }
      if (isNotNil(sellOrderForm.createShipping)) {
        sellOrderRequestBody.createShipping = sellOrderForm.createShipping
      }

      setSaving(true)
      createSellOrder(sellOrderRequestBody)
        .then(() => {
          toast.success("진단전판매 신청 완료")
          handleClose()
        })
        .catch((error) => toast.error(`진단전판매 신청 실패 (${error.message || error})`))
        .finally(() => setSaving(false))
    }
  }

  const handleDetailModelChanged = async (detailModel: DetailModelType | null) => {
    setSelectedDetailModel(detailModel)
    setSellOrderForm((sellOrder) => ({
      ...sellOrder,
      // fullRefNo에 `-ETC` 포함된 경우 기타모델로 등록
      detailModelId: detailModel?.fullRefNo?.includes("-ETC") ? null : detailModel?.id ?? null,
      brandSid: detailModel?.brand?.sid ?? null,
    }))
  }

  const updateEstimatedPrice = () => {
    if (!sellOrderForm.detailModelId) return toast.error("상품명을 선택해주세요")
    else if (!sellOrderForm.mainProductCondition) return toast.error("제품상태를 선택해주세요")
    else if (!sellOrderForm.officialGuarantee) return toast.error("보증서 유무를 선택해주세요")
    if (!sellOrderForm.originalBoxCondition) return toast.error("정품박스 유무를 선택해주세요.")
    else if (!sellOrderForm.accessoriesCondition) return toast.error("부속품 상태를 선택해주세요")
    else if (sellOrderForm.officialGuarantee === "있음" && !sellOrderForm.stampingYear) {
      return toast.error("보증서의 스탬핑 연도를 입력해주세요")
    }

    getResellPrice({
      detailModel: { id: sellOrderForm.detailModelId },
      mainProductCondition: sellOrderForm.mainProductCondition,
      accessoriesCondition: sellOrderForm.accessoriesCondition,
      officialGuarantee: sellOrderForm.officialGuarantee,
      stampingYear: sellOrderForm.stampingYear ?? undefined,
      originalBoxCondition: sellOrderForm.originalBoxCondition ?? undefined,
    }).then((res) => {
      // @ts-ignore
      if (res?.correctedEstimatedPrice?.min && res?.correctedEstimatedPrice?.max)
        // @ts-ignore
        setEstimatedPrice(
          `${numberWithCommas(truncate(res?.correctedEstimatedPrice?.min, 4))} ~ ${numberWithCommas(
            truncate(res?.correctedEstimatedPrice?.max, 4)
          )}`
        )
      else setEstimatedPrice("시세 정보 없음")
    })
  }

  const fetchDetailModels = (fullRefNo: String) => {
    getDetailModels({ fullRefNo, onDisplay: true, size: 500 }).then((res) => setDetailModels(res.content))
  }

  const fetchDetailModelsEtc = () => {
    getDetailModels({ fullRefNo: "etc", size: 500 }).then((res) => setDetailModelsEtc(res.content))
  }

  const fetchSellOrderProductType = () => {
    getCodeDetailByCode("PRODUCT_0002").then((res) => setSellOrderProductType(res))
  }

  const getGlobalSaleCap = () => {
    getDetailCodeValue("GLOBAL_SALE", "GLOBAL_SALE_CAP").then((res) => {
      if (res && res != null) {
        console.log("상한가 조회  : " + parseFloat(res))
        setGlobalSaleCap(parseFloat(res))
      } else {
        toast.success("상품 해외 판매 상한가 정보 가져오기 실패")
      }
    })
  }

  useEffect(() => {
    if (open) {
      setSellOrderForm(initialSellOrderForm)
      setDetailModels([])
      setSelectedDetailModel(null)
      setSeller(null)

      getGlobalSaleCap()
      fetchDetailModelsEtc()
      fetchSellOrderProductType()
    }
  }, [open, skipDiagnosis])

  return (
    <>
      <Dialog open={open} maxWidth={"xl"} fullWidth>
        <DialogTitle>
          <Typography fontWeight={"bold"} fontSize={"30px"}>
            판매주문 생성
          </Typography>
        </DialogTitle>
        <DialogContent>
          <table className={"basic"}>
            <tbody>
              <tr>
                <td className={"header"}>판매주문번호 / 상품번호</td>
                <td className={"header"}>판매신청 일시</td>
                <td className={"header"}>픽업요청 일시</td>
                <td className={"header"}>입고확인 일시</td>
                <td className={"header"}>판매방식</td>
                <td className={"header"}>판매자ID</td>
              </tr>
              <tr>
                <td>등록 후 생성</td>
                <td>등록 후 생성</td>
                <td>등록 후 생성</td>
                <td>등록 후 생성</td>
                <td>
                  <FormControl fullWidth>
                    <Select
                      required
                      value={sellOrderForm.sellOrderType}
                      onChange={(event) => {
                        setSellOrderForm({ ...initialSellOrderForm, sellOrderType: event.target.value as OrderType })
                        setSelectedDetailModel(null)
                      }}
                    >
                      {[OrderType.위탁, OrderType.진단전, OrderType.직매입].map((it) => (
                        <MenuItem value={it}>{it}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </td>
                <td className={"ta-center"}>
                  {sellOrderForm.userId && (
                    <div style={{ display: "inline-block", marginRight: 8 }}>
                      <UserIdButton id={sellOrderForm.userId} /> {seller?.name}
                    </div>
                  )}
                  <Button
                    style={{ display: "inline-block" }}
                    variant={"outlined"}
                    color={"success"}
                    size={"small"}
                    onClick={() => {
                      setOpenUserIdSelectPopup(true)
                    }}
                    disabled={
                      sellOrderForm.sellOrderType !== OrderType.위탁 && sellOrderForm.sellOrderType !== OrderType.진단전
                    }
                  >
                    판매자 조회
                  </Button>
                </td>
              </tr>
              {(sellOrderForm.sellOrderType === OrderType.위탁 || sellOrderForm.sellOrderType === OrderType.진단전) && (
                <>
                  <tr>
                    <td className={"header"} colSpan={1}>
                      배송방식
                    </td>
                    <td className={"header"} colSpan={5}>
                      배송주소
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1}>
                      <FormControl fullWidth>
                        <Select
                          required
                          value={sellOrderForm.shippingMethod}
                          onChange={(event) => {
                            setSellOrderForm({
                              ...sellOrderForm,
                              shippingMethod: event.target.value as ShippingMethodType,
                            })
                          }}
                        >
                          {[
                            { key: ShippingMethodType.쇼룸방문, label: "쇼룸방문" },
                            { key: ShippingMethodType.안전배송, label: "발렉스" },
                            { key: ShippingMethodType.안전배송_2, label: "일양배송" },
                          ].map((it) => (
                            <MenuItem value={it.key}>{it.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </td>
                    <td colSpan={5}>
                      {sellOrderForm.shippingMethod !== ShippingMethodType.쇼룸방문 ? (
                        <>
                          {sellOrderForm.createShipping
                            ? `${sellOrderForm.createShipping.name} ${sellOrderForm.createShipping.phone}\n${sellOrderForm.createShipping.address} ${sellOrderForm.createShipping.addressDetail}`
                            : `기본배송지 사용`}
                          <> </>
                          <Button
                            variant={"outlined"}
                            color={"success"}
                            size={"small"}
                            onClick={() => {
                              setOpenShippingRegeditPopup(true)
                            }}
                          >
                            수정
                          </Button>
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>

          <h2>상품 정보</h2>
          <table className={"basic"}>
            <tbody>
              <tr>
                <td className={"header"} colSpan={3}>
                  상세모델
                </td>
                {detailModelInputMode === "기타모델" && (
                  <td className={"header"} colSpan={2}>
                    기타모델 제품명
                  </td>
                )}
                <td className={"header"}>상품구분</td>
                <td className={"header"}>희망 판매가 (만원)</td>
                {detailModelInputMode === "노출중모델" && <td className={"header"}>예상 가격 범위 (만원)</td>}
                <td className={"header"}>해외 판매 동의</td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <Checkbox
                    checked={detailModelInputMode === "기타모델"}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setDetailModelInputMode("기타모델")
                        setSelectedDetailModel(null)
                      } else {
                        setDetailModelInputMode("노출중모델")
                        setSelectedDetailModel(null)
                      }
                    }}
                  />
                  기타모델
                  {detailModelInputMode === "노출중모델" && (
                    <Autocomplete
                      disablePortal
                      options={detailModels}
                      fullWidth
                      value={selectedDetailModel}
                      renderInput={(params) => (
                        <>
                          {selectedDetailModel?.thumbnail && (
                            <img
                              src={selectedDetailModel.thumbnail ?? undefined}
                              style={{ height: "180px", width: "500px", marginRight: "10px", objectFit: "cover" }}
                              alt="모델 썸네일"
                            />
                          )}
                          <TextField {...params} label="레퍼런스 번호로 검색" />
                        </>
                      )}
                      getOptionLabel={(detailModel) => {
                        return `${detailModel.title} ${detailModel.titleSecondary} [${detailModel.fullRefNo}]`
                      }}
                      onInputChange={(_, inputValue) => fetchDetailModels(inputValue)}
                      onChange={(_, detailModel) => {
                        handleDetailModelChanged(detailModel)
                      }}
                    />
                  )}
                  {detailModelInputMode === "기타모델" && (
                    <Select
                      fullWidth
                      value={selectedDetailModel?.id}
                      onChange={(event) => {
                        const detailModelId = event.target.value as DetailModelType["id"]
                        handleDetailModelChanged(
                          detailModelsEtc.find((detailModelEtc) => detailModelEtc.id === detailModelId) ?? null
                        )
                      }}
                    >
                      {detailModelsEtc.map((detailModel, index) => {
                        return (
                          <MenuItem key={index} value={detailModel.id}>
                            {detailModel.title} {detailModel.titleSecondary} [{detailModel.fullRefNo}]
                          </MenuItem>
                        )
                      })}
                    </Select>
                  )}
                </td>
                {detailModelInputMode === "기타모델" && (
                  <td className={"ta-center"} colSpan={2}>
                    <TextField
                      value={sellOrderForm.productTitle}
                      fullWidth
                      onChange={(event) => {
                        setSellOrderForm({ ...sellOrderForm, productTitle: event.target.value })
                      }}
                    />
                  </td>
                )}
                <td className={"ta-center"}>
                  <Select
                    required
                    value={sellOrderForm.sellOrderProductType}
                    onChange={(event) => {
                      setSellOrderForm({
                        ...sellOrderForm,
                        sellOrderProductType: event.target.value as SellOrderProductType,
                      })
                    }}
                    defaultValue={SellOrderProductType.바이버}
                  >
                    {sellOrderProductType?.map((it) => (
                      <MenuItem key={it.id} value={it.detailCode}>
                        {it.detailCodeName}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
                <td className={"ta-center"}>
                  <TextField
                    value={(sellOrderForm.price || 0) / 10000}
                    fullWidth
                    type={"number"}
                    onChange={(event) => {
                      // 해외 판매 동의 상한가 체크
                      if (sellOrderForm.globalSale && parseInt(event.target.value) * 10000 >= globalSaleCap) {
                        setSellOrderForm({ ...sellOrderForm, price: 0 })
                        toast.error(
                          `판매 가격이 ${truncateWithCommas(globalSaleCap, 4)} 만원 이상인 상품은 해외 판매가 불가합니다.`
                        )
                      } else {
                        setSellOrderForm({ ...sellOrderForm, price: parseInt(event.target.value) * 10000 })
                      }
                    }}
                  />
                </td>
                {detailModelInputMode === "노출중모델" && (
                  <td className={"ta-center"}>
                    <Typography>{estimatedPrice}</Typography>
                    <Button variant={"outlined"} color={"success"} size={"small"} onClick={updateEstimatedPrice}>
                      시세 조회
                    </Button>
                  </td>
                )}
                <td>
                  <Switch
                    disabled={sellOrderForm.sellOrderType !== OrderType.직매입 && !seller}
                    checked={sellOrderForm.globalSale ? true : false}
                    onChange={(e, check) => {
                      if (sellOrderForm.sellOrderType !== OrderType.직매입 && check) {
                        // 판매자 통관고유부호 체크
                        if (seller?.customsCode) {
                          setSellOrderForm({ ...sellOrderForm, globalSale: check })
                        } else {
                          setSellOrderForm({ ...sellOrderForm, globalSale: false })
                          toast.error(`사용자 정보에서 고객의 통관고유부호를 등록해 주세요.`)
                        }
                        // 해외 판매 동의 상한가 체크
                        if (check && sellOrderForm.price >= globalSaleCap) {
                          setSellOrderForm({ ...sellOrderForm, globalSale: false })
                          toast.error(
                            `판매 가격이 ${truncateWithCommas(globalSaleCap, 4)} 만원 이상인 상품은 해외 판매가 불가합니다.`
                          )
                        }
                      } else {
                        setSellOrderForm({ ...sellOrderForm, globalSale: check })
                      }
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <h2>상품상태</h2>
          <table className={"basic"}>
            <tbody>
              <tr>
                <td className={"header"}>제품상태</td>
                <td className={"header"}>보증서</td>
                <td className={"header"}>정품박스</td>
                <td className={"header"}>부속품</td>
                <td className={"header"}>스탬핑 연도</td>
              </tr>
              <tr>
                <td>
                  <FormControl fullWidth>
                    <Select
                      required
                      value={sellOrderForm.mainProductCondition}
                      onChange={(event) =>
                        // @ts-ignore
                        setSellOrderForm({ ...sellOrderForm, mainProductCondition: event.target.value })
                      }
                    >
                      {["미착용", "중고"].map((it) => (
                        <MenuItem value={it}>{it}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </td>
                <td>
                  <FormControl fullWidth>
                    <Select
                      required
                      value={sellOrderForm.officialGuarantee}
                      onChange={(event) =>
                        // @ts-ignore
                        setSellOrderForm({ ...sellOrderForm, officialGuarantee: event.target.value })
                      }
                    >
                      {["있음", "없음"].map((it) => (
                        <MenuItem value={it}>{it}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </td>
                <td>
                  <FormControl fullWidth>
                    <Select
                      required
                      value={sellOrderForm.originalBoxCondition}
                      onChange={(event) =>
                        // @ts-ignore
                        setSellOrderForm({ ...sellOrderForm, originalBoxCondition: event.target.value })
                      }
                    >
                      {["있음", "없음"].map((it) => (
                        <MenuItem value={it}>{it}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </td>
                <td key={`${sellOrderForm.detailModelId}-${sellOrderForm.accessoriesCondition}`}>
                  <FormControl fullWidth>
                    <Select
                      required
                      value={sellOrderForm.accessoriesCondition}
                      onChange={(event) => {
                        // @ts-ignore
                        const accessoriesCondition: CreateSellOrderFormType["accessoriesCondition"] = event.target.value

                        setSellOrderForm({ ...sellOrderForm, accessoriesCondition })
                      }}
                    >
                      {[
                        { value: "있음", label: "있음" },
                        { value: "없음", label: "없음" },
                        { value: "일부파손", label: "일부만 있음" },
                      ].map(({ value, label }) => (
                        <MenuItem value={value}>{label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </td>
                <td>
                  <TextField
                    value={sellOrderForm.stampingYear}
                    fullWidth
                    type={"number"}
                    onChange={(event) => {
                      // @ts-ignore
                      let year = parseInt(event.target.value)
                      if (year > 1950 && year < 2999) setSellOrderForm({ ...sellOrderForm, stampingYear: year })
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {sellOrderForm.sellOrderType === "진단전" && (
            <>
              <table className={"basic"}>
                <tbody>
                  <tr>
                    <td className={"header"}>찍힘/눌림</td>
                    <td className={"header"}>글라스 흠집</td>
                    <td className={"header"}>링크</td>
                    <td className={"header"}>보호 필름</td>
                  </tr>
                  <tr>
                    <td>
                      <FormControl fullWidth>
                        <Select
                          required
                          value={sellOrderForm.selfCheck.stab}
                          onChange={(event) =>
                            // @ts-ignore
                            setSellOrderForm((sellOrder) => ({
                              ...sellOrder,
                              selfCheck: { ...sellOrder.selfCheck, stab: event.target.value },
                            }))
                          }
                        >
                          {["있음", "없음"].map((it) => (
                            <MenuItem value={it}>{it}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </td>
                    <td>
                      <FormControl fullWidth>
                        <Select
                          required
                          value={sellOrderForm.selfCheck.glassScratch}
                          onChange={(event) =>
                            // @ts-ignore
                            setSellOrderForm((sellOrder) => ({
                              ...sellOrder,
                              selfCheck: { ...sellOrder.selfCheck, glassScratch: event.target.value },
                            }))
                          }
                        >
                          {["있음", "없음"].map((it) => (
                            <MenuItem value={it}>{it}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </td>
                    <td>
                      <FormControl fullWidth>
                        <Select
                          required
                          value={sellOrderForm.selfCheck.braceletLinks}
                          onChange={(event) =>
                            // @ts-ignore
                            setSellOrderForm((sellOrder) => ({
                              ...sellOrder,
                              selfCheck: { ...sellOrder.selfCheck, braceletLinks: event.target.value },
                            }))
                          }
                        >
                          {["모름", "해당 없음", "사진첨부"].map((it) => (
                            <MenuItem value={it}>{it}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </td>
                    <td>
                      <FormControl fullWidth>
                        <Select
                          required
                          value={sellOrderForm.selfCheck.protectingFilm}
                          onChange={(event) =>
                            // @ts-ignore
                            setSellOrderForm((sellOrder) => ({
                              ...sellOrder,
                              selfCheck: { ...sellOrder.selfCheck, protectingFilm: event.target.value },
                            }))
                          }
                        >
                          {["있음", "없음"].map((it) => (
                            <MenuItem value={it}>{it}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className={"basic"}>
                <tbody>
                  <tr>
                    <td className={"header"}>사진</td>
                  </tr>
                  <tr>
                    <td className={"ta-left"} style={{ padding: 20 }}>
                      <label
                        style={{
                          display: "inline-block",
                          padding: 8,
                          marginBottom: 10,
                          border: "1px solid #CED2D6",
                          borderRadius: 6,
                        }}
                      >
                        <input
                          style={{ display: "none" }}
                          type="file"
                          multiple
                          accept="image/*"
                          onChange={async (event) => {
                            if (event.target.files && isNotEmpty(event.target.files)) {
                              const gallery = (await Promise.all(
                                Array.from(event.target.files as FileList).map((file: File) =>
                                  commonResourceUploadFile(file, [{ key: "path", value: "resources" }])
                                )
                              )) as { id: number; url: string }[]
                              setSellOrderForm((sellOrderForm) => ({
                                ...sellOrderForm,
                                gallery: [...sellOrderForm.gallery, ...gallery],
                              }))
                            }
                            event.target.value = ""
                          }}
                        />
                        이미지 업로드
                      </label>
                      <div>
                        {sellOrderForm.gallery.map((resource) => (
                          <div
                            key={resource.id}
                            style={{
                              display: "inline-block",
                              position: "relative",
                              width: 158,
                              height: 158,
                              marginRight: 12,
                              border: "1px solid #CED2D6",
                              borderRadius: 6,
                              overflow: "hidden",
                            }}
                          >
                            <img
                              style={{ width: "100%", height: "100%", objectFit: "cover" }}
                              src={resource.url}
                              alt="이미지"
                            />
                            <button
                              style={{ position: "absolute", bottom: 0, right: 0 }}
                              onClick={() => {
                                setSellOrderForm((sellOrderForm) => ({
                                  ...sellOrderForm,
                                  gallery: sellOrderForm.gallery.filter(({ id }) => id !== resource.id),
                                }))
                              }}
                            >
                              X
                            </button>
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className={"basic"}>
                <tbody>
                  <tr>
                    <td className={"header"}>설명</td>
                  </tr>
                  <tr>
                    <td>
                      <TextareaAutosize
                        minRows={5}
                        style={{ width: "100%" }}
                        value={sellOrderForm.userDescription}
                        onChange={(event) => {
                          setSellOrderForm((sellOrderForm) => ({
                            ...sellOrderForm,
                            userDescription: event.target.value,
                          }))
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton size={"medium"} loading={saving} variant={"contained"} color={"error"} onClick={handleCreate}>
            판매 주문 생성
          </LoadingButton>
          <Button size={"medium"} variant={"outlined"} color={"error"} onClick={() => handleClose()}>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      {openUserIdSelectPopup && (
        <UserIdSelectPopup
          onClickCancel={() => setOpenUserIdSelectPopup(false)}
          onClickConfirm={(userId, user) => {
            setOpenUserIdSelectPopup(false)
            setSellOrderForm((sellOrder) => ({ ...sellOrder, userId }))
            setSeller(user)
            if (user) {
              // 판매자 선택 시 -> 판매자 정보의 해와판매동의여부값을 초기값으로 설정
              setSellOrderForm((sellOrderForm) => ({ ...sellOrderForm, globalSale: user.globalSale }))
            } else {
              setSellOrderForm((sellOrderForm) => ({ ...sellOrderForm, globalSale: false }))
            }
          }}
        />
      )}
      {openShippingRegeditPopup && (
        <ShippingRegeditPopup
          shipping={sellOrderForm.createShipping}
          onClickCancel={() => setOpenShippingRegeditPopup(false)}
          onClickConfirm={(shipping) => {
            setSellOrderForm((sellOrderForm) => ({ ...sellOrderForm, createShipping: shipping }))
            setOpenShippingRegeditPopup(false)
          }}
        />
      )}
    </>
  )
}

export default CreateSellOrderPopup
