import { DataGrid } from "@mui/x-data-grid"
import { Box, Button, Stack } from "@mui/material"
import React, { useMemo, useState } from "react"
import styled from "@emotion/styled"
import { CouponApplyTargetInfo, CouponApplyTargetTypeCode } from "../../../../types/CouponType"
import { toDateStr, toTimeStr } from "../../../../utils/datetimeUtil"
import { currencyFormat } from "../../../../utils/NumberUtils"
import { isNotNil } from "../../../../utils/validationUtils"

type Props = {
  applyTargetType: CouponApplyTargetTypeCode
  applyTargetInfo: CouponApplyTargetInfo[] | null
  handleOnChangeState: ( targets: CouponApplyTargetInfo[]) => void
  openShowTargetSelectPopup?: () => void
}

const ApplyTargetInfoTable = ({ applyTargetType, applyTargetInfo, handleOnChangeState, openShowTargetSelectPopup  }: Props) => {
  const [selectionTargetIds, setSelectionTargetIds] = useState<string[]>([])

  const columns = useMemo(() => {

    if (applyTargetType ==  CouponApplyTargetTypeCode.USER) {
      return [
        {
          field: "userId",
          headerName: "ID",
          width: 70,
          valueGetter: (params: any) => `${params.row.userId}`,
        },
        {
          field: "userName",
          headerName: "이름",
          width: 100,
          valueGetter: (params: any) => `${params.row.userName}`,
        },
        {
          field: "nickname",
          headerName: "닉네임",
          width: 100,
          flex: 1,
        },
        {
          field: "phone",
          headerName: "핸드폰 번호",
          width: 130,
        },
        {
          field: "email",
          headerName: "이메일",
          flex: 1,
          width: 150,
        },
        {
          field: "status",
          headerName: "상태",
          width: 100,
        },
        {
          field: "authType",
          headerName: "가입 경로",
          width: 100,
        },
      ]
    }
    else if (applyTargetType ==  CouponApplyTargetTypeCode.BRAND) {
      return [
        {
          field: "brandId",
          headerName: "ID",
          width: 200,
          valueGetter: (params: any) => `${params.row.brandId}`,
        },
        {
          field: "brandName",
          headerName: "브랜드명",
          width: 300,
          flex: 1,
          valueGetter: (params: any) => `${params.row.brandName}`,
        },
        {
          field: "koName",
          headerName: "한글 브랜드명",
          width: 300,
          valueGetter: (params: any) => `${params.row.koName}`,
        },
        {
          field: "onDisplay",
          headerName: "노출 여부",
          width: 100,
          valueGetter: (params: any) => `${params.row.onDisplay == true ? "예" : "아니오"}`,
        },
      ]
    }
    else if ( applyTargetType == CouponApplyTargetTypeCode.DETAIL_MODEL) {
      return [
        {field: "detailModelId", headerName: "ID", width: 70},
        {
          field: "thumbnail",
          headerName: "썸네일",
          width: 100,
          renderCell: (params: any) => (
            <Box>
              <img style={{width: "100%"}} src={params.row.thumbnail} alt={""}/>
            </Box>
          ),
        },
        {field: "fullRefNo", headerName: "레퍼런스", width: 160},
        {field: "title", headerName: "상세 모델명", flex: 1},
        {field: "titleSecondary", headerName: "상세 모델명", flex: 1}
      ]
    }    else if ( applyTargetType == CouponApplyTargetTypeCode.MODEL) {
      return [
        { field: "modelId", headerName: "ID", editable: false, minWidth: 100, flex: 0.1 },
        {
          field: "thumbnail", headerName: "대표사진", editable: false, minWidth: 100, flex: 0.3,
          renderCell: (params: any) => {
            return isNotNil(params.value) &&
              <img style={{ width: 104, height: 100 }} src={params.row.thumbnail} alt={params.row.name} />
          },
        },
        { field: "name", headerName: "모델명", editable: false, minWidth: 100, flex: 1 },
      ]
    }
    else if ( applyTargetType == CouponApplyTargetTypeCode.PRODUCT) {
      return [
        { field: "productId", headerName: "ID", width: 60 },
        {
          field: "startSaleAt",
          headerName: "등록일시",
          width: 100,
          renderCell: (params: any) => (
            <div style={{ textAlign: "center", lineHeight: 0.6 }}>
              <p>{toDateStr(params.row.startSaleAt, false)}</p>
              <p>{toTimeStr(params.row.startSaleAt)}</p>
            </div>
          ),
        },
        {
          field: "status",
          headerName: "상태",
          sortable: false,
          width: 90,
          renderCell: (params: any) => (
            <div style={{ textAlign: "center", lineHeight: 0.6 }}>
              <p style={{ color: params.row.status === "판매중" ? "#2e7d32" : "#d32f2f", fontWeight: 700 }}>
                {params.row.status}
              </p>
              <p style={{ color: params.row.onDisplay ? "#2e7d32" : "#d32f2f" }}>
                {params.row.onDisplay ? "노출" : "숨김"}
              </p>
            </div>
          ),
        },
        {
          field: "thumbnail",
          headerName: "썸네일",
          sortable: false,
          width: 100,
          renderCell: (params: any) => (
            <Box>
              <img style={{ width: "100%" }} src={params.row.thumbnail} alt={""} />
            </Box>
          ),
        },
        {
          field: "title",
          headerName: "상품명",
          flex: 1,
          sortable: false,
          renderCell: (params: any) => (
            <div style={{ lineHeight: 0.6 }}>
              <p>
                {params.row.saleType} / {params.row.productCondition}
              </p>
              <p style={{ fontSize: "16px", fontWeight: 700 }}>{params.row.title}</p>
              <p>{params.row.titleSecondary}</p>
              <p>{params.row.detailModel.fullRefNo}</p>
            </div>
          ),
        },
        {
          field: "price",
          headerName: "가격",
          sortable: false,
          width: 100,
          valueGetter: (params: any): string => `${currencyFormat(params.row.price)}`,
        },
        {
          field: "globalSale",
          headerName: "해외판매",
          sortable: false,
          width: 80,
          valueGetter: (params: any): string => `${params.row.globalSale ? "Y" : "N"}`,
        },
      ]
    }
    return []
  }, [ applyTargetType, applyTargetInfo, handleOnChangeState])

  return (
    <Box>
      <Stack height={"100%"}>
        <ButtonWrapper>
          <Button
            variant={"contained"}
            color={"primary"}
            size={"small"}
            onClick={openShowTargetSelectPopup}
            style={{ marginRight: 10 }}
          >
            추가
          </Button>
          <Button
            variant={"contained"}
            color={"error"}
            size={"small"}
            disabled={selectionTargetIds.length < 1}
            onClick={() => {
              if (!applyTargetInfo?.length) return

              const nextTargets: CouponApplyTargetInfo[] = applyTargetInfo.filter(
                ( target ) => target && !selectionTargetIds.includes(target.targetId)
              )
              handleOnChangeState(nextTargets!!)
            }}
          >
            삭제
          </Button>
        </ButtonWrapper>
      </Stack>

      <DataGrid
        getRowId={(row: any) => row.targetId}
        style={{
          height: "300px",
        }}
        checkboxSelection={true}
        columns={columns}
        onSelectionModelChange={(ids) => {
          setSelectionTargetIds(ids as string[])
        }}
        getRowHeight={() =>  ( applyTargetType ==  CouponApplyTargetTypeCode.PRODUCT ) ? 100 : 50}
        rows={applyTargetInfo ?? []}
        hideFooter
      />
    </Box>
  )
}

export default ApplyTargetInfoTable

const ButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
`