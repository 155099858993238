import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from "@mui/material"
// import { CouponListTableRow } from "types/CouponType"
import { PageType } from "types/PageType"
import {
  CouponApplyTargetTypeCode, CouponApplyTargetTypeTitleMap,
  CouponListSearchParams,
  CouponTargetTypeCode, CouponTargetTypeTitleMap,
  CouponType,
} from "../../../types/CouponType"
import React, { useEffect, useReducer } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { getCouponList } from "../../../apis/couponAPI"
import CouponTable from "./CouponTable"
import EditCouponPopup from "./EditCouponPopup"

export interface CouponState {
  selectedCoupon?: CouponType
  couponList: PageType<CouponType>
  searchFilterKey?: keyof CouponListSearchParams
  searchFilterValue?: string | null
  searchFilterIsUse?: string
  searchFilterApplyTargetType?: CouponApplyTargetTypeCode | "all"
  searchFilterCouponTargetType?: CouponTargetTypeCode | "all"
  isShowEditCouponPopup: boolean
  refresh: boolean
  loading: boolean
}

const initState: (couponId?: string | null) => CouponState = (couponId) => {
  let state: CouponState = {
    selectedCoupon: undefined,
    couponList: {
      content: [],
      pageable: { pageSize: 25, pageNumber: 0 },
      totalPages: 0,
      totalElements: 0,
      numberOfElements: 0,
    },
    searchFilterKey: "couponName",
    searchFilterValue: undefined,
    searchFilterIsUse: "1",
    searchFilterApplyTargetType: "all",
    searchFilterCouponTargetType: "all",
    isShowEditCouponPopup: false,
    refresh: false,
    loading: false,
  }
  if (couponId) {
    state.searchFilterKey = "couponId"
    state.searchFilterValue = couponId
    state.searchFilterIsUse = "all"
    state.searchFilterApplyTargetType = "all"
    state.searchFilterCouponTargetType = "all"
  }

  return state
}

interface CouponAction {
  type: string
  payload?: any
}

function reducer(state: CouponState, action: CouponAction): CouponState {

  switch (action.type) {
    case "FETCH_COUPON":
      return { ...state, couponList: action.payload }
    case "REFRESH":
      return { ...state, refresh: !state.refresh }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
    case "CHANGE_SEARCH_FILTER_KEY":
      return { ...state, searchFilterKey: action.payload, searchFilterValue: null }
    case "CHANGE_SEARCH_FILTER_VALUE":
      return { ...state, searchFilterValue: action.payload }
    case "CHANGE_SEARCH_FILTER_ISUSE":
      return { ...state, searchFilterIsUse: action.payload }
    case "CHANGE_SEARCH_FILTER_APPLYTARGETTYPE":
      return { ...state, searchFilterApplyTargetType: action.payload }
    case "CHANGE_SEARCH_FILTER_COUPONTARGETTYPE":
      return { ...state, searchFilterCouponTargetType: action.payload }
    case "TOGGLE_EDIT_COUPON_POPUP":
      return { ...state, selectedCoupon: action.payload, isShowEditCouponPopup: !state.isShowEditCouponPopup }
    case "TOGGLE_CREATE_COUPON_POPUP":
      return { ...state, selectedCoupon: action.payload, isShowEditCouponPopup: !state.isShowEditCouponPopup }
  }
  return state
}

const CouponList = () => {
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)

  const [currentSearchParams] = useSearchParams()
  const [state, dispatch] = useReducer(
    reducer,
    initState(currentSearchParams.get("counponId"))
  )
   const numericFields = [
    { key: "couponId", type: "number" }
  ]

  const fetchCouponList = (searchParams: CouponListSearchParams = {}) => {
    let newParams: CouponListSearchParams = {
      ...searchParams,
      isUse: state.searchFilterIsUse == "all" ? null : (state.searchFilterIsUse == "1" ? "true" : "false"),
      applyTargetType: state.searchFilterApplyTargetType == "all" ? null : state.searchFilterApplyTargetType as CouponApplyTargetTypeCode,
      couponTargetType: state.searchFilterCouponTargetType == "all" ? null : state.searchFilterCouponTargetType as CouponTargetTypeCode
    }

    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    dispatch({ type: "START_LOADING" })
    getCouponList(newParams)
      .then((couponList) => {
        dispatch({ type: "FETCH_COUPON", payload: couponList })
      })
      .finally(() => dispatch({ type: "END_LOADING" }))
  }


  useEffect(() => {
    fetchCouponList({
      page: state.couponList.pageable?.pageNumber || 0,
      size: state.couponList.pageable?.pageSize || 25,
    })
  }, [state.refresh, currentSearchParams])

  return (
    <Grid container spacing={2}>
      <EditCouponPopup
        selectedCoupon={state.selectedCoupon}
        isOpen={state.isShowEditCouponPopup}
        closePopup={() => {
          dispatch({ type: "TOGGLE_EDIT_COUPON_POPUP", payload: null })
          dispatch({ type: "REFRESH" })
        }}
      />

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", width: "100%" }}>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch({ type: "TOGGLE_CREATE_COUPON_POPUP", payload: null })
                }}
              >
                쿠폰 생성
              </Button>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end" spacing={0}>
              <FormControl sx={{ width: "100px" }} size={"small"}>
                <InputLabel id="search_key">사용여부</InputLabel>
                <Select
                  value={state.searchFilterIsUse}
                  onChange={(params) => {
                    dispatch({ type: "CHANGE_SEARCH_FILTER_ISUSE", payload: params.target.value })
                    dispatch({ type: "REFRESH" })
                  }}
                >
                  <MenuItem value={"all"}>전체</MenuItem>
                  <MenuItem value={"1"}>사용</MenuItem>
                  <MenuItem value={"0"}>미사용</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "120px" }} size={"small"}>
                <InputLabel id="search_key">적용대상</InputLabel>
                <Select
                  value={state.searchFilterApplyTargetType}
                  onChange={(params) => {
                    dispatch({ type: "CHANGE_SEARCH_FILTER_APPLYTARGETTYPE", payload: params.target.value })
                    dispatch({ type: "REFRESH" })
                  }}
                >
                  <MenuItem value={"all"}>선택</MenuItem>
                  {Object.entries(CouponApplyTargetTypeTitleMap).map(([code, title]) => (
                    <MenuItem value={code} key={code}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "110px" }} size={"small"}>
                <InputLabel id="search_key">쿠폰대상</InputLabel>
                <Select
                  value={state.searchFilterCouponTargetType}
                  onChange={(params) => {
                    dispatch({ type: "CHANGE_SEARCH_FILTER_COUPONTARGETTYPE", payload: params.target.value })
                    dispatch({ type: "REFRESH" })
                  }}
                >
                  <MenuItem value={"all"}>선택</MenuItem>
                  {Object.entries(CouponTargetTypeTitleMap).map(([code, title]) => (
                    <MenuItem value={code} key={code}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100px" }} size={"small"}>
                <InputLabel id="search_key">검색항목</InputLabel>
                <Select
                  value={state.searchFilterKey || ""}
                  onChange={(params) => {
                    dispatch({ type: "CHANGE_SEARCH_FILTER_KEY", payload: params.target.value })
                    dispatch({ type: "REFRESH" })
                  }}
                >
                  <MenuItem value={"couponId"}>쿠폰ID</MenuItem>
                  <MenuItem value={"couponName"}>쿠폰명</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={{ width: "150px" }}
                size={"small"}
                value={state.searchFilterValue || ""}
                onChange={(params) => {
                  const field = numericFields.find((item) => item.key === (state.searchFilterKey || ""))

                  let value = params.target.value

                  if (field) {
                    if (field.type === "number") {
                      value = value.replace(/[^0-9]/g, "")
                    } else if (field.type === "numberList") {
                      value = value.replace(/[^0-9,]/g, "")
                    }
                  }
                  dispatch({ type: "CHANGE_SEARCH_FILTER_VALUE", payload: value })
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") dispatch({ type: "REFRESH" })
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CouponTable state={state} dispatch={dispatch} fetch={fetchCouponList} components={[]} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default CouponList
