import { DataGrid, GridColumnHeaderParams, GridToolbarContainer } from "@mui/x-data-grid"
import React, { Dispatch, SetStateAction } from "react"
import { DateTime } from "luxon"
import { CouponState } from "./index"
import { numberWithCommas, truncateWithCommas } from "../../../utils/NumberUtils"
import { toDateStr } from "../../../utils/datetimeUtil"
import { Button, Stack } from "@mui/material"
import {
    CouponApplySaleTypeTitleMap,
    CouponApplyTargetTypeTitleMap,
    CouponCalculationTypeTitleMap,
    CouponIssueTargetTypeTitleMap,
    CouponIssueTypeTitleMap,
    CouponSaleTypeTitleMap,
    CouponTargetTypeTitleMap,
    CouponUsageDateTypeTitleMap,
} from "types/CouponType"
import { getEnumTitleByCode } from "../../../utils/enumUtils"
import { FeePolicyState } from "../../FeePolicy"


function createColumns(): any[] {
    return [
        {
            field: "id",
            headerName: "쿠폰ID",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 60,
            maxWidth: 60,
            renderHeader: (params: GridColumnHeaderParams) => (
              <p style={{lineHeight: 1}}>쿠폰ID</p>
            ),
            valueGetter: (params: any) => `${params.row.id}`
        },
        {
            field: "couponName",
            headerName: "쿠폰명",
            editable: false,
            sortable: false,
            align: "left",
            flex: 1,
            minWidth: 120,
            maxWidth: 150,
            valueGetter: (params: any) => {
                return `${params.row.couponName}`
            }
        },
        {
            field: "isUse",
            headerName: "사용여부",
            editable: false,
            sortable: false,
            align: "center",
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => `${params.row.isUse ? "사용" : "미사용"}`,
            renderCell: (params: any) => (
              <div>
                  <Button size={"small"} variant={"text"} color={params.row.isUse ? "success" : "error"}>
                      {params.row.isUse ? "사용" : "미사용"}
                  </Button>
              </div>
            ),
        },
        {
            field: "couponPreIssueCount",
            headerName: "선발행 쿠폰",
            editable: false,
            sortable: false,
            align: "center",
            minWidth: 100,
            maxWidth: 120,
            renderHeader: (params: GridColumnHeaderParams) => (
              <p style={{lineHeight: 1}}>
                  선발행
                  <br/>
                  쿠폰내역
              </p>
            ),
            renderCell: (params: any) => (
              <>
              {
                  params.row?.couponPreIssueCount > 0 &&
                    <div>
                        <Button
                          size={"small"}
                          variant={"outlined"}
                          style={{ fontWeight: "bold" }}
                          onClick={() => {
                             window.open(`${window.location.origin}/coupon?tab=preissuelist&counponId=${params.row?.id}`, "_self")
                          }}
                          color={"info"}
                        >
                            {params.row.couponPreIssueUseCount} / {params.row?.couponPreIssueCount}
                        </Button>
                    </div>
              }
              </>
            ),
        },
        {
            field: "couponIssueCount",
            headerName: "발행 쿠폰",
            editable: false,
            sortable: false,
            align: "center",
            minWidth: 100,
            maxWidth: 120,
            renderCell: (params: any) => (
              <>
                  {
                    params.row?.couponIssueCount > 0 &&
                    <div>
                        <Button
                          size={"small"}
                          variant={"outlined"}
                          style={{ fontWeight: "bold" }}
                          onClick={() => {
                              window.open(`${window.location.origin}/coupon?tab=issuelist&counponId=${params.row?.id}`, "_self")
                          }}
                          color={"info"}
                        >
                            {params.row.couponIssueUseCount} / {params.row?.couponIssueCount}
                        </Button>
                    </div>
                  }
              </>
            ),
        },
        {
            field: "issueTargetType",
            headerName: "발급대상",
            editable: false,
            sortable: false,
            align: "left",
            flex: 1,
            minWidth: 120,
            maxWidth: 140,
            valueGetter: (params: any) => `${getEnumTitleByCode( CouponIssueTargetTypeTitleMap, params.row.issueTargetType)}`,
        },
        {
            field: "applySaleType",
            headerName: "적용판매방식",
            editable: false,
            sortable: false,
            align: "left",
            flex: 1,
            minWidth: 100,
            maxWidth: 140,
            renderHeader: (params: GridColumnHeaderParams) => (
              <p style={{lineHeight: 1}}>
                  적용
                  <br/>
                  판매방식
              </p>
            ),

            valueGetter: (params: any) => `${getEnumTitleByCode( CouponApplySaleTypeTitleMap, params.row.applySaleType)}`,
        },
        {
            field: "applyTargetType",
            headerName: "적용대상",
            editable: false,
            sortable: false,
            align: "left",
            flex: 1,
            minWidth: 100,
            maxWidth: 140,
            valueGetter: (params: any) => `${getEnumTitleByCode( CouponApplyTargetTypeTitleMap, params.row.applyTargetType)}`,
        },
        {
            field: "couponType",
            headerName: "쿠폰타입",
            editable: false,
            sortable: false,
            align: "left",
            flex: 1,
            minWidth: 100,
            maxWidth: 140,
            valueGetter: (params: any) => `${getEnumTitleByCode( CouponSaleTypeTitleMap, params.row.couponType)}`,
        },
        {
            field: "couponTargetType",
            headerName: "쿠폰대상",
            editable: false,
            sortable: false,
            align: "left",
            flex: 1,
            minWidth: 100,
            maxWidth: 100,
            valueGetter: (params: any) => `${getEnumTitleByCode( CouponTargetTypeTitleMap, params.row.couponTargetType)}`,
        },
        {
            field: "issueType",
            headerName: "발급타입",
            editable: false,
            sortable: false,
            align: "left",
            flex: 1,
            minWidth: 100,
            maxWidth: 140,
            valueGetter: (params: any) => `${getEnumTitleByCode( CouponIssueTypeTitleMap, params.row.issueType)}`,
        },
        {
            field: "calculationType",
            headerName: "계산타입",
            editable: false,
            sortable: false,
            align: "left",
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => `${getEnumTitleByCode( CouponCalculationTypeTitleMap, params.row.calculationType)}`,
        },
        {
            field: "discountRate",
            headerName: "할인율",
            editable: false,
            sortable: false,
            align: "right",
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => {
                const discountRate = params.row.discountRate
                return discountRate ? `${discountRate}%` : "-"
            },
        },
        {
            field: "discountAmount",
            headerName: "할인금액",
            editable: false,
            sortable: false,
            align: "right",
            flex: 1,
            minWidth: 100,
            maxWidth: 100,
            valueGetter: (params: any) => {
                const discountAmount = params.row.discountAmount
                return discountAmount ? `${numberWithCommas(discountAmount)}원` : "-"
            },
        },
        {
            field: "usageDateType",
            headerName: "사용기간타입",
            editable: false,
            sortable: false,
            align: "left",
            flex: 1,
            minWidth: 90,
            maxWidth: 90,
            renderHeader: (params: GridColumnHeaderParams) => (
              <p style={{lineHeight: 1}}>
                  사용기간
                  <br/>
                  타입
              </p>
            ),

            valueGetter: (params: any) => `${getEnumTitleByCode( CouponUsageDateTypeTitleMap, params.row.usageDateType)}`,
        },
        {
            field: "issueCount",
            headerName: "발급건수",
            editable: false,
            sortable: false,
            align: "right",
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => {
                const issueCount = params.row.issueCount
                return issueCount ? `${truncateWithCommas(issueCount, 4)}` : "-"
            },
        },
        {
            field: "issueStartDate",
            headerName: "발급기간",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 100,
            maxWidth: 100,
            valueGetter: (params: any) =>
              params.value ? DateTime.fromISO(params.value)?.toFormat("yyyy-MM-dd HH:mm:ss") : "",
            renderCell: (params: any) => (
              <div style={{textAlign: "center"}}>
                  <p>
                      {toDateStr(params.row.issueStartDate)}
                      <br/>
                      {toDateStr(params.row.issueEndDate)}
                  </p>
              </div>
            ),
        },
        {
            field: "expireDate",
            headerName: "만료일자",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 80,
            maxWidth: 100,
            valueGetter: (params: any) =>
              params.row.expireDate
                ? DateTime.fromISO(params.row.expireDate).toFormat("yyyy-MM-dd HH:mm:ss")
                : "",
            renderCell: (params: any) => (
              <div style={{textAlign: "center"}}>
                  <p>
                      {toDateStr(params.row.expireDate, false)}
                  </p>
              </div>
            ),
        },
        {
            field: "validPeriod",
            headerName: "유효기간",
            editable: false,
            sortable: false,
            align: "right",
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => {
                const validPeriod = params.row.validPeriod
                return validPeriod ? `${truncateWithCommas(validPeriod, 4)}` : "-"
            },
        },
    ]
}

type CouponProps = {
    state: CouponState
    dispatch: any
    fetch: Function
    components: any[]
}

const CouponTable: React.FC<CouponProps> = ({state, dispatch, fetch, components}) => {
    function CustomToolbar() {
        return <GridToolbarContainer style={{justifyContent: "flex-end"}}>{components}</GridToolbarContainer>
    }

    return (
      <>
          <Stack spacing={1}>
              <div style={{height: 1200, width: "100%"}}>
                  <DataGrid
                    components={{Toolbar: CustomToolbar}}
                    getRowId={(row) => row.id!}
                    columns={createColumns()}
                    pagination
                    paginationMode="server"
                    onPageChange={(page) => {
                        fetch({page, size: state.couponList.pageable?.pageSize || 25})
                    }}
                    onPageSizeChange={(size) => {
                        fetch({page: 0, size})
                    }}
                    onCellClick={(params, event) => {
                        if ( !(params.field == 'couponPreIssueCount' && params.row?.couponPreIssueCount > 0)
                          && !(params.field == 'couponIssueCount'  && params.row?.couponIssueCount > 0 ) ) {
                            dispatch({ type: "TOGGLE_CREATE_COUPON_POPUP", payload: params.row })
                        }
                    }}
                    page={state.couponList.pageable?.pageNumber}
                    pageSize={state.couponList.pageable?.pageSize}
                    rowCount={state.couponList?.totalElements}
                    rows={state.couponList?.content}
                    //   selectionModel={state.selectedIds}
                    //    checkboxSelection
                    disableSelectionOnClick={true}
                    //   onSelectionModelChange={handleSelection}
                    loading={state.loading}
                  />
              </div>
          </Stack>
      </>
    )
}

export default CouponTable