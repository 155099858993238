import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { SellOrderType } from "types/SellOrderType"
import { useDiagnosisData } from "pages/Diagnoses/EditPopup/useDiagnosisData"
import SellOrderInformation from "pages/Diagnoses/EditPopup/SellOrderInformation"
import ProductCondition from "pages/Diagnoses/EditPopup/ProductCondition"
import DiagnosisImages from "pages/Diagnoses/EditPopup/DiagnosisImages"
import OverAllStatus from "pages/Diagnoses/EditPopup/OverallStatus"
import PenaltyInformation from "pages/Diagnoses/EditPopup/PenaltyInformation"
import DiagnosisResult from "pages/Diagnoses/EditPopup/DiagnosisResult"
import DetailedDiagnosis from "pages/Diagnoses/EditPopup/DetailedDiagnosis"
import { toDateTimeStr } from "utils/datetimeUtil"
import PreviousProductCondition from "./PreviousProductCondition"
import { DiagnosisGradeCode, DiagnosisResultCode, DiagnosisStatusCode } from "types/DiagnosisType"
import styled from "@emotion/styled"
import RePurchaseGuaranteedPriceInformation from "./PriceInformation"
import { getRepurchaseUntil } from "utils/rePurchaseGuaranteedUtils"
import { UserMemoTargetTypeCode } from "../../../types/UserType"
import QuickMemoButton from "../../../components/QuickMemo/QuickMemoButton"
import { AdminUserAuthorityTypeCode } from "../../../types/AdminUserType"
import { AuthContext } from "../../../AuthContext"

type EditRePurchaseGuaranteedDiagnosisPopupProps = {
  open: boolean
  selectedSellOrder: SellOrderType
  handleClose: Function
}

const EditRePurchaseGuaranteedDiagnosisPopup: React.FC<EditRePurchaseGuaranteedDiagnosisPopupProps> = ({
  open,
  selectedSellOrder,
  handleClose,
}) => {
  const {
    penalty,
    brand,
    diagnosis,
    setDiagnosis,
    saving,
    generateGeneralReview,
    checkAccessoriesAll,
    checkAccessoriesNone,
    formattedDate,
    setFormattedDate,
    setComplete,
    setCompleteRollback,
    setConfirm,
    setConfirmRollback,
    update,
    setUpdateDiagnosisRollback,
    setGuideResultRollback,
    changeDiagnosisStateAndRequestResellPrice,
    changeDiagnosisState,
  } = useDiagnosisData({ sellOrder: selectedSellOrder, isResellPriceNotRequested: true })
  const { user } = useContext(AuthContext)

  if (!open) {
    return <></>
  }

  return (
    <Dialog open={open} maxWidth={"xl"} fullWidth>
      <DialogTitle>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td colSpan={3} className={"no-border ta-left"}>
                <Typography fontWeight={"bold"} fontSize={"30px"}>
                  진단 체크리스트 ({selectedSellOrder.sellOrderType}) {diagnosis.version}
                </Typography>
              </td>
              <td className={"header"}>상품번호</td>
              <td className={"header"}>담당자</td>
              <td className={"header"}>결과전달</td>
              <td className={"header"}>진단서 상태</td>
            </tr>
            <tr>
              <td colSpan={3} className={"no-border"}></td>
              <td>
                {selectedSellOrder?.product?.id}
                <QuickMemoButton
                  target={UserMemoTargetTypeCode.PRODUCT}
                  targetId={selectedSellOrder?.product?.id}
                  small
                />
              </td>
              <td>{diagnosis?.diagnosedBy?.name}</td>

              <td>{!!diagnosis?.notifiedAt ? "완료" : "필요"}</td>
              <td className={"bold"}>{saving ? "저장중..." : diagnosis?.status}</td>
            </tr>
          </tbody>
        </table>
      </DialogTitle>
      <DialogContent>
        <SellOrderInformation sellOrder={selectedSellOrder} diagnosis={diagnosis} />
        {!!diagnosis.purchasedBuyOrder && (
          <>
            <div style={{ marginTop: 20 }}>
              <table className={"basic"}>
                <tbody>
                  <tr>
                    <td className={"header"}>상품번호 (이전)</td>
                    <td className={"header"}>구매주문 번호</td>
                    <td className={"header"}>매입보증 구매일시</td>
                    <td className={"header"}>매입보증 만료일시</td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href={`/buy-orders?productId=${diagnosis.purchasedBuyOrder.product.id}`}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        {diagnosis.purchasedBuyOrder.product.id}
                      </a>
                    </td>
                    <td>{diagnosis.purchasedBuyOrder.id}</td>
                    <td>{toDateTimeStr(diagnosis.purchasedBuyOrder.completedAt)}</td>
                    <td>
                      {diagnosis.purchasedBuyOrder.completedAt &&
                      diagnosis.purchasedBuyOrder.product.repurchaseGuaranteePeriod
                        ? toDateTimeStr(
                            getRepurchaseUntil(
                              diagnosis.purchasedBuyOrder.completedAt,
                              diagnosis.purchasedBuyOrder.product.repurchaseGuaranteePeriod
                            )
                          )
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h2>구매 시 상품 상태</h2>
            <PreviousProductCondition
              brand={brand!}
              accessories={diagnosis.purchasedBuyOrder.product.exposedProductInfo?.accessories}
              serialNo={diagnosis.purchasedBuyOrder.product.serialNo || ""}
              stampingDate={diagnosis.purchasedBuyOrder.product.exposedProductInfo?.stampingDate || ""}
              productCondition={diagnosis.purchasedBuyOrder.product.exposedProductInfo?.productCondition}
              officialGuarantee={diagnosis.purchasedBuyOrder.product.exposedProductInfo?.officialGuarantee}
              originalBoxCondition={diagnosis.purchasedBuyOrder.product.exposedProductInfo?.originalBoxCondition}
              accessoriesCondition={diagnosis.purchasedBuyOrder.product.exposedProductInfo?.accessoriesCondition}
            />
          </>
        )}
        <ProductCondition
          brand={brand!}
          serialNo={diagnosis.serialNo || ""}
          components={diagnosis.components}
          isGuaranteedProduct
          formattedDate={formattedDate}
          changeFormattedDate={setFormattedDate}
          changeDiagnosisStateAndRequestResellPrice={changeDiagnosisStateAndRequestResellPrice}
          checkAccessoriesAll={checkAccessoriesAll}
          checkAccessoriesNone={checkAccessoriesNone}
          changeDiagnosisState={changeDiagnosisState}
        />

        <DetailedDiagnosis
          appearanceCondition={diagnosis.appearanceCondition}
          internalCondition={diagnosis.internalCondition}
          changeDiagnosisState={changeDiagnosisState}
        />
        <OverAllStatus grade={diagnosis.grade} failedReason={diagnosis.failedReason} setDiagnosis={setDiagnosis} />
        {diagnosis.grade === DiagnosisGradeCode.판매_불가 && (
          <PenaltyInformation
            penaltyReasonType={diagnosis.penaltyReasonType}
            isRePurchaseGuaranteed
            changeDiagnosisState={changeDiagnosisState}
          />
        )}
        <DiagnosisResult isRePurchaseGuaranteed result={diagnosis.result} changeDiagnosisState={changeDiagnosisState} />
        <DiagnosisImages
          changeDiagnosisState={changeDiagnosisState}
          images={diagnosis.images || []}
          disabled={saving || diagnosis.status === DiagnosisStatusCode.진단서_확정}
        />

        <RePurchaseGuaranteedPriceInformation
          penaltyPrice={penalty}
          isPenaltyRequired={diagnosis.grade === DiagnosisGradeCode.판매_불가}
          productPrice={diagnosis.purchasedBuyOrder?.product.price || 0}
          viverPrices={diagnosis.viverPrices}
          changeDiagnosisState={changeDiagnosisState}
          repurchaseGuaranteeRate={diagnosis.purchasedBuyOrder?.product.repurchaseGuaranteeRate}
        />

        <h2>진단 총평</h2>
        <TextareaAutosize
          minRows={5}
          style={{ width: "100%" }}
          value={diagnosis?.generalReview}
          onChange={(event) => {
            setDiagnosis({ ...diagnosis, generalReview: event.target.value })
          }}
        />
        <Button
          size={"medium"}
          disabled={diagnosis?.status === DiagnosisStatusCode.진단서_확정}
          variant={"outlined"}
          onClick={() => generateGeneralReview(diagnosis)}
        >
          자동 작성
        </Button>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          padding: "8px 24px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {user?.authorities?.find((auth) => auth === AdminUserAuthorityTypeCode.DIAGNOSIS_ROLLBACK) ? (
          <div style={{ display: "flex", gap: "8px" }}>
            {/*{diagnosis?.status === DiagnosisStatusCode.진단서_작성중 && diagnosis.notifiedBy === null && (*/}
            {/*  <Button*/}
            {/*    size={"medium"}*/}
            {/*    disabled={saving}*/}
            {/*    variant={"contained"}*/}
            {/*    color={"error"}*/}
            {/*    onClick={() => setUpdateDiagnosisRollback()}*/}
            {/*  >*/}
            {/*    {"작성 대기 ⇦ 작성중"}*/}
            {/*  </Button>*/}
            {/*)}*/}
            {diagnosis?.status === DiagnosisStatusCode.진단서_작성_완료 && diagnosis.notifiedBy === null && (
              <Button
                size={"medium"}
                disabled={saving}
                variant={"contained"}
                color={"error"}
                onClick={() => setCompleteRollback()}
              >
                {"작성중 ⇦ 작성 완료"}
              </Button>
            )}
            {diagnosis?.status === DiagnosisStatusCode.진단서_확정 && diagnosis.notifiedBy === null && (
              <Button
                size={"medium"}
                disabled={saving}
                variant={"contained"}
                color={"error"}
                onClick={() => setConfirmRollback()}
              >
                {"작성 완료 ⇦ 확정"}
              </Button>
            )}
            {diagnosis?.status === DiagnosisStatusCode.진단서_확정 &&
              diagnosis.notifiedBy !== null &&
              diagnosis.result !== DiagnosisResultCode.불합격 && (
                <Button
                  size={"medium"}
                  disabled={saving}
                  variant={"contained"}
                  color={"error"}
                  onClick={() => setGuideResultRollback()}
                >
                  {"고객에게 미전달 ⇦ 고객에게 전달"}
                </Button>
              )}
          </div>
        ) : (
          <div></div>
        )}
        <div
          style={{
            display: "flex",
            gap: "2px",
            alignItems: "center",
            textAlign: "right",
          }}
        >
          {diagnosis?.purchasedBuyOrder?.product && (
            <a
              href={`/products?productId=${diagnosis.purchasedBuyOrder.product.id}`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Button size={"medium"} disabled={saving} variant={"outlined"}>
                이전 상품정보 보기
              </Button>
            </a>
          )}
          <Space />
          <Button size={"medium"} disabled={saving} variant={"contained"} onClick={() => update(diagnosis)}>
            진단서 변경내역 저장
          </Button>
          <Space />
          <Button
            size={"medium"}
            disabled={
              saving ||
              diagnosis?.status === DiagnosisStatusCode.진단서_작성_완료 ||
              diagnosis?.status === DiagnosisStatusCode.진단서_확정
            }
            variant={"contained"}
            color={"success"}
            onClick={() => setComplete()}
          >
            진단서 작성완료
          </Button>
          <Space />
          <Button
            size={"medium"}
            disabled={saving || diagnosis?.status !== DiagnosisStatusCode.진단서_작성_완료}
            variant={"contained"}
            color={"error"}
            onClick={() => setConfirm()}
          >
            진단서 확정
          </Button>
          <Space />

          <Button size={"medium"} variant={"outlined"} color={"error"} onClick={() => handleClose()}>
            닫기
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default EditRePurchaseGuaranteedDiagnosisPopup

const ButtonListWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 10px;
`

const Space = styled.div`
  width: 10px;
`
