import { toast } from "react-toastify"
import styled from "@emotion/styled"
import { findUserByCsv } from "apis/userAPI"
import { Button } from "@mui/material"
import React from "react"

type Props = {
  changeState: (key: string, value: any) => void
}

const CsvFileUploader = ({ changeState }: Props) => {
  const handleSubmitCsv = (fileList: FileList) => {
    if (!fileList) {
      return
    }

    findUserByCsv(fileList)
      .then((res) => {
        changeState(
          "userIds",
          res.map(({ id }) => id)
        )
      })
      .catch((e) => toast.error(e.message))
  }

  return (
    <OptionWrapper>
      <FileDownloader>
        <h3>CSV 파일 선택</h3>
        <a href={`${process.env.PUBLIC_URL}/excel/user아이디검색.csv`} download target="_blank" rel="noreferrer">
          <Button
            variant={"outlined"}
            size={"small"}
            color={"error"}
            style={{ margin: "0 2px" }}>
            엑셀 양식 다운로드(사용자ID)
          </Button>
        </a>
        <a href={`${process.env.PUBLIC_URL}/excel/user전화번호검색.csv`} download target="_blank" rel="noreferrer">
          <Button
            variant={"outlined"}
            size={"small"}
            color={"error"}
            style={{ margin: "0 2px" }}>
            엑셀 양식 다운로드(사용자 전화번호)
          </Button>
        </a>
      </FileDownloader>
      <InlineWrapper>
        <FileUploder
          type={"file"}
          accept=".csv"
          onChange={(e) => {
            if (e.target.files) {
              handleSubmitCsv(e.target.files)
            }
          }}
        />
      </InlineWrapper>
    </OptionWrapper>
  )
}

export default CsvFileUploader

const FileDownloader = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    padding-bottom: 20px;
`

const FileUploder = styled.input`
    flex: 1;
    height: 40px;
    padding: 5px 10px;
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 4px;
`

const InlineWrapper = styled.div`
    width: 100%;
    display: inline-flex;
    align-items: center;
`

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  h3 {
    margin: 5px 0;
    font-size: 16px;
  }
`
