import * as React from "react"
import { useEffect, useRef, CSSProperties } from "react"

type props = {
  key: string,
  videoUrl: string,
  style?: CSSProperties
}
const VideoPlayer: React.FC<props> = ({key, videoUrl, style}) => {


  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.play().catch((err) => console.error("Error playing video:", err));
    }
  }, [videoUrl])

  return (
    <video
      ref={videoRef}
      key={key}
      autoPlay
      playsInline
      loop
      muted
      style={{...style}}
    >
      <source src={videoUrl} />
    </video>
  )
}

export default VideoPlayer