import { httpGet, httpGetToBlob, httpPost } from "utils/httpAPI"
import { PageType } from "types/PageType"
import {
  CouponIssueCouponItem,
  CouponIssueListSearchParams,
  CouponPreIssueItem,
  CouponPreIssueListSearchParams,
  CouponType,
  PreIssueCouponsParams,
} from "types/CouponType"

export const getCouponList: (params?: CouponPreIssueListSearchParams) => Promise<PageType<CouponType>> = (params) =>
  httpGet("/coupons", params)

export const getCoupon: (couponId?: number) => Promise<CouponType> = (couponId) =>
  httpGet(`/coupons/${couponId}`)

export const setCoupon: (body: CouponType) => Promise<CouponType> = (
  body: CouponType
) => httpPost("/coupons", body)
export const createPreIssueCoupons: (body: PreIssueCouponsParams) => Promise<void> = (
  body: PreIssueCouponsParams
) => httpPost("/coupons/preissuecoupons", body)

export const getPreIssueCouponList: (params?: CouponPreIssueListSearchParams) => Promise<PageType<CouponPreIssueItem>> = (params) =>
  httpGet("/coupons/preissuecoupons", params)

export const downloadPreIssueCouponExcel = (params?: { [key: string]: any }): Promise<Blob> =>
  httpGetToBlob("/coupons/preissuecoupons/excel", params)

export const getIssueCouponList: (params?: CouponIssueListSearchParams) => Promise<PageType<CouponIssueCouponItem>> = (params) =>
  httpGet("/coupons/issuecoupons", params)

export const downloadIssueCouponExcel = (params?: { [key: string]: any }): Promise<Blob> =>
  httpGetToBlob("/coupons/issuecoupons/excel", params)
