import React, { useEffect, useState } from "react"
import { getSktUniversePassHistory } from "../../../../../../apis/sktUniversePassAPI"
import { SktUniversePassStatus, SktUniversePassHistoryType } from "../../../../../../types/SktUniversePassType"
import styled from "@emotion/styled"
import Modal from "components/Modal"
import Button from "components/Button"

type Props = {
  userId: number
  isOpen: boolean
  closeModal: () => void
}

const SktUniversePassHistoryModal = ({ userId, isOpen, closeModal }: Props) => {
  const [sktUniversePassHistory, setSktUniversePassHistory] = useState<SktUniversePassHistoryType[]>([])

  const fetchSktUniversePassHistory = (userId: number) => {
    getSktUniversePassHistory(userId).then((value) => setSktUniversePassHistory(value))
  }

  const getCancelReason = (pass: SktUniversePassHistoryType) => {
    switch (pass.cancelReasonCode) {
      case "ZN":
        return "고객요청즉시해지"
        return
      case "ZR":
        return "고객요청예약해지"

      case "ZS":
        return "정기결제실패해지"

      case "ZT":
        return "반품/철회해지"
    }
  }

  const getRevokeReason = (pass: SktUniversePassHistoryType) => {
    switch (pass.revokeCancelReasonCode) {
      case "01":
        return "고객요청 취소"

      case "02":
        return "미인증 일괄 취소"
    }
  }

  useEffect(() => {
    if (isOpen) {
      fetchSktUniversePassHistory(userId)
    }
  }, [userId, isOpen])

  return (
    <Modal isOpen={isOpen}>
      <ModalContentWrapper>
        <ModalContentHeader>
          <h3>SKT 우주패스 구독 이력</h3>
        </ModalContentHeader>

        <ModalContentBody>
          <Table className="basic">
            <thead>
              <Tr>
                <Th className="header">항목</Th>
                <Th className="header">기간 및 날짜</Th>
                <Th className="header">사유</Th>
              </Tr>
            </thead>
            <tbody>
              {sktUniversePassHistory.map((pass) => (
                <Tr key={pass.id}>
                  <Td>{pass.status}</Td>
                  <Td>
                    {(() => {
                      switch (pass.status) {
                        case SktUniversePassStatus.구독:
                          return pass.startDate + " ~ " + pass.endDate

                        case SktUniversePassStatus.해지:
                        case SktUniversePassStatus.해지_예약:
                          return pass.cancelRequestDate

                        case SktUniversePassStatus.해지_취소:
                          return pass.revokeCancelRequestDate
                      }
                    })()}
                  </Td>
                  <Td>
                    {(() => {
                      switch (pass.status) {
                        case SktUniversePassStatus.해지:
                        case SktUniversePassStatus.해지_예약:
                          return getCancelReason(pass)

                        case SktUniversePassStatus.해지_취소:
                          return getRevokeReason(pass)
                      }
                    })()}
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </ModalContentBody>

        <ModalContentFooter>
          <Button buttonStyle="black-border" buttonSize="M" onClick={closeModal}>
            닫기
          </Button>
        </ModalContentFooter>
      </ModalContentWrapper>
    </Modal>
  )
}

export default SktUniversePassHistoryModal

const ModalContentWrapper = styled.div`
  width: 600px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px;
`

const ModalContentHeader = styled.div`
  flex-shrink: 0;
  margin-bottom: 16px;

  h3 {
    margin: 0;
    font-size: 1.125rem;
    font-weight: 700;
  }
`

const ModalContentBody = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 16px;
`

const ModalContentFooter = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;

  thead {
    background-color: #f0f0f0;
  }
`

const Tr = styled.tr``

const Th = styled.th`
  &.header {
    font-weight: 700;
  }

  border: 1px solid #ddd;
  padding: 8px;
`

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  vertical-align: middle;
  font-size: 0.875rem;
`
