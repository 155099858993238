import styled from "@emotion/styled"
import React, { useCallback, useState } from "react"
import { useMount } from "react-use"
import { PaginationParamsType } from "types/PageType"
import { toast } from "react-toastify"
import PriceSummaryTable from "./PriceSummaryTable"
import { DetailModelPriceSummaryType } from "../../../types/DetailModelPriceSummaryType"
import { downloadDetailModelPriceSummary, getDetailModelPriceSummaryList } from "../../../apis/detailModelPriceSummaryAPI"
import { DetailModelPriceSummarySearchBar } from "./SearchBar"
import { Button } from "@mui/material"


export type DetailModelPriceSummaryListItem = DetailModelPriceSummaryType

const initSearchParams = {
  brandSid: "ALL",
  searchFilter: "fullRefNo",
  searchWord: "",
  nation: "ALL",
  searchYearMonth: "ALL",
}

const PriceSummaryTab = () => {
  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: 25,
  })

  const [downloading, setDownloading] = useState<boolean>(false)

  const [searchParams, setSearchParams] = useState<any>(initSearchParams)

  const [detailModelPriceSummaryList, setDetailModelPriceSummaryList] = useState<DetailModelPriceSummaryListItem[]>([])

  const changeBrandSid = useCallback((brandSid: any) => {
    setSearchParams((prev: any) => ({ ...prev, brandSid: brandSid }))
  }, [])

  const changeSearchFilter = useCallback((searchFilter: any) => {
    setSearchParams((prev: any) => ({ ...prev, searchFilter: searchFilter }))
  }, [])

  const changeSearchWord = useCallback((searchWord: any) => {
    setSearchParams((prev: any) => ({ ...prev, searchWord: searchWord }))
  }, [])

  const changeNation = useCallback((nation: any) => {
    setSearchParams((prev: any) => ({ ...prev, nation: nation }))
  }, [])

  const changeSearchYearMonth = useCallback((searchYearMonth: any) => {
    setSearchParams((prev: any) => ({ ...prev, searchYearMonth: searchYearMonth }))
  }, [])


  const parseSearchParams = (searchParams?: any) => {
    if (!searchParams) {
      return {}
    }

    return {
      brandSid: searchParams.brandSid === "ALL" ? null : searchParams.brandSid,
      [searchParams.searchFilter]: searchParams.searchWord,
      nation: searchParams.nation === "ALL" ? null : searchParams.nation,
      priceType: searchParams.priceType === "ALL" ? null : searchParams.priceType,
      productCondition: searchParams.productCondition === "ALL" ? null : searchParams.productCondition,
      searchYearMonth: searchParams.searchYearMonth === "ALL" ? null : searchParams.searchYearMonth,
    }
  }


  function fetchList({ pageNumber, pageSize, searchParams }: { pageNumber?: number; pageSize?: number; searchParams?: any; }) {
    getDetailModelPriceSummaryList({
      size: pageSize ?? paginationParams.size,
      page: pageNumber,
      ...parseSearchParams(searchParams),
    })
      .then(({ content, totalElements = 0, totalPages = 0, first, size = 25, number = 0 }) => {
        setDetailModelPriceSummaryList(content)
        setPaginationParams((prev) => ({
          ...prev,
          number,
          totalElements,
          totalPages,
          first,
          size,
        }))
      })
      .catch(() => {
        toast.error("공식 출시가 내역을 불러오는데 실패했습니다.")
      })
  }

  const onPageChange = useCallback(
    (pageNumber: number) => {
      setPaginationParams((prev) => ({ ...prev, number: pageNumber }))
      fetchList({ pageNumber: pageNumber, searchParams: searchParams })
    },
    [searchParams, fetchList],
  )

  const onChangePageSize = useCallback((pageSize: number) => {
      setPaginationParams((prev) => ({ ...prev, size: pageSize }))
      fetchList({
        pageNumber: 0,
        pageSize: pageSize,
        searchParams: searchParams,
      })
    },
    [paginationParams],
  )

  const onSearch = useCallback(() => {
    fetchList({ pageNumber: 0, searchParams: searchParams })
    setPaginationParams((prev) => ({ ...prev, number: 0 }))
  }, [fetchList, searchParams])


  const onDownloadExcel = async () => {
    if (downloading) {
      toast.info("공식 출시가 내역을 다운로드 중입니다.")
      return
    }

    setDownloading(true)
    try {
      const blob = await downloadDetailModelPriceSummary({ ...parseSearchParams(searchParams) })
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `price_summary_list.xlsx`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      toast.error("공식 출시가 내역을 다운로드 하지 못했습니다.")
    }
    setDownloading(false)
  }

  useMount(() => {
    fetchList({})
  })

  return (
    <PageBodyWrapper>
      <SearchBarWrapper>
        <DetailModelPriceSummarySearchBar
          changeBrandSid={changeBrandSid}
          changeSearchFilter={changeSearchFilter}
          changeSearchWord={changeSearchWord}
          changeNation={changeNation}
          changeSearchYearMonth={changeSearchYearMonth}
          searchParams={searchParams}
        />
      </SearchBarWrapper>
      <SearchBarWrapper>
        <Button
          color={"primary"}
          variant={"contained"}
          style={{ marginRight: "15px" }}
          onClick={onSearch}>
          검색
        </Button>
        <Button
          color={downloading ? "warning" : "primary"}
          variant={downloading ? "contained" : "outlined"}
          onClick={onDownloadExcel}>
          {downloading ? "다운로드 중" : "엑셀 다운로드"}
        </Button>
      </SearchBarWrapper>
      <PriceSummaryTable
        rows={detailModelPriceSummaryList ?? []}
        onPageChange={onPageChange}
        onPageSizeChange={onChangePageSize}
        page={paginationParams.number}
        size={paginationParams.size}
        totalCount={paginationParams.totalElements ?? 0}
      />
    </PageBodyWrapper>
  )
}

const PageBodyWrapper = styled.div`
    width: 100%;
    background: #fff;
    padding: 24px 16px;
    border-radius: 4px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
`

const SearchBarWrapper = styled.div`
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 28px;
`
export default PriceSummaryTab