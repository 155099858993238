import { DataGrid, GridColumnHeaderParams, GridToolbarContainer } from "@mui/x-data-grid"
import React from "react"
import { DateTime } from "luxon"
import { IssueCouponState } from "./index"
import { numberWithCommas } from "../../../utils/NumberUtils"
import { toDateStr, toDateTimeStr, toTimeStr } from "../../../utils/datetimeUtil"
import { Button, Stack } from "@mui/material"
import { CouponIssueCouponItem, CouponPreIssueItem, PreIssueTypeTitleMap } from "types/CouponType"
import { getEnumTitleByCode } from "../../../utils/enumUtils"


function createColumns( openBuyOrderPopup: Function): any[] {
    return [
        {
            field: "id",
            headerName: "쿠폰ID",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 60,
            maxWidth: 60,
            renderHeader: (params: GridColumnHeaderParams) => (
              <p style={{lineHeight: 1}}>쿠폰ID</p>
            ),
            valueGetter: (params: any) => `${params.row.coupon.id}`
        },
        {
            field: "couponName",
            headerName: "쿠폰명",
            editable: false,
            sortable: false,
            align: "left",
            flex: 1,
            minWidth: 120,
            maxWidth: 150,
            valueGetter: (params: any) => {
                return `${params.row.coupon.couponName}`
            },
            renderCell: (params: any) => (
              <>
                  {
                      <div>
                          <Button
                            size={"small"}
                            variant={"outlined"}
                            style={{ fontWeight: "bold" }}
                            onClick={() => {
                                window.open(`${window.location.origin}/coupon?tab=list&counponId=${params.row.coupon.id}`, "_self")
                            }}
                            color={"info"}
                          >
                              {params.row.coupon.couponName}
                          </Button>
                      </div>
                  }
              </>
            ),
        },
        {
            field: "couponAmount",
            headerName: "쿠폰금액",
            editable: false,
            sortable: false,
            align: "right",
            flex: 1,
            minWidth: 110,
            maxWidth: 150,
            valueGetter: (params: any) => {
                if ( params.row.couponAmount != null ) {
                        return `${numberWithCommas(params.row.couponAmount)}원`
                }
                else return "-"
            },
        },
        {
            field: "expireDate",
            headerName: "만료일",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 180,
            maxWidth: 180,
            valueGetter: (params: any) =>  params.row.expireDate ? `${DateTime.fromISO(params.row.expireDate).toFormat("yyyy-MM-dd HH:mm:ss")}` : "-"
        },
        {
            field: "createdAt",
            headerName: "발행일",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 180,
            maxWidth: 180,
            valueGetter: (params: any) =>  params.row.createdAt ? `${DateTime.fromISO(params.row.createdAt).toFormat("yyyy-MM-dd HH:mm:ss")}` : "-"
        },
        {
            field: "isUse",
            headerName: "사용여부",
            editable: false,
            sortable: false,
            align: "center",
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => `${params.row.isUse ? "사용" : "미사용"}`,
            renderCell: (params: any) => (
              <div>
                  <Button size={"small"} variant={"text"} color={params.row.isUse ? "success" : "error"}>
                      { params.row.isUse ? "사용" : "미사용" }
                  </Button>
              </div>
            ),
        },
        {
            field: "userId",
            headerName: "사용자",
            editable: false,
            sortable: false,
            align: "center",
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => `${params.row.user.id ?? "-"}`,
        },
        {
            field: "buyOrderId",
            headerName: "구매주문번호",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 160,
            maxWidth: 160,
            valueGetter: (params: any) => `${params.row.buyOrder?.id ?? "-"}`,
            renderCell: (params: any) => (
              <div>
                  {
                    params.row.buyOrder?.id &&
                    <Button
                      size={"small"}
                      variant={"outlined"}
                      style={{fontWeight: "bold"}}
                      onClick={() => {
                          openBuyOrderPopup(params.row)
                      }}
                      color={"info"}
                    >
                        {params.row.buyOrder?.id}
                    </Button>
                  }
              </div>
            ),
        },
        {
            field: "productId",
            headerName: "상품번호",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => `${params.row?.buyOrder?.product?.id ?? "-"}`,
        },
        {
            field: "saleType",
            headerName: "판매방식",
            editable: false,
            sortable: false,
            align: "center",
            flex: 1,
            minWidth: 90,
            maxWidth: 120,
            valueGetter: (params: any) => `${params.row.buyOrder.product?.saleType ?? "-"}`,
        },
        {
            field: "buyOrderStatus",
            headerName: "진행상태",
            editable: false,
            sortable: false,
            align: "center",
            flex: 1,
            minWidth: 90,
            maxWidth: 120,
            valueGetter: (params: any) => {
                return `${params.row.buyOrder?.buyOrderStatus ?? "-"}`
            },
            // renderCell: (params: any) => (
            //   <div>
            //       <Button
            //         size={"small"}
            //         variant={"outlined"}
            //         style={{ fontWeight: "bold" }}
            //         onClick={() => {
            //             openBuyOrderPopup(params.row)
            //         }}
            //         color={getColorByStatus(params.row.buyOrderStatus)}
            //       >
            //           {params.row.buyOrderStatus}
            //       </Button>
            //   </div>
            // ),
        },
        {
            field: "title",
            headerName: "상품명",
            editable: false,
            sortable: false,
            minWidth: 220,
            flex: 1,
            valueGetter: (params: any) => `${params.row.buyOrder.product?.title ?? "-"}`,
        },
        {
            field: "productPrice",
            headerName: "구매가",
            editable: false,
            sortable: false,
            align: "right",
            flex: 1,
            minWidth: 110,
            maxWidth: 150,
            renderCell: (params: any) => (
              <div style={{ textAlign: "center" }}>
                  <p>
                      {params.row.buyOrder.id == null && "-" }
                      {params.row.buyOrder.id != null && params.row.buyOrder.product?.currency == 'KRW' ? numberWithCommas(params.row.buyOrder.product?.price) + '원' : (params.row.buyOrder.product?.currency == 'USD' ? '$' + numberWithCommas(params.row.buyOrder.product?.price) : numberWithCommas(params.row.buyOrder.product?.price))}
                      <br />
                      {params.row.buyOrder.id != null && params.row.buyOrder.currency != 'KRW' ? (params.row.buyOrder.currency + (params.row.buyOrder.currency == 'USD' ? ' $' : '') + numberWithCommas(params.row.buyOrder.productPrice)) : ''}
                  </p>
              </div>
            ),
        },
        {
            field: "amount",
            headerName: "결제 금액",
            editable: false,
            sortable: false,
            align: "right",
            flex: 1,
            minWidth: 110,
            maxWidth: 150,
            valueGetter: (params: any) => {
                if ( params.row.buyOrder.id != null ) {
                    const price = params.row.buyOrder.paymentAmount
                    const currency = params.row.buyOrder.currency
                    if (currency == 'KRW') {
                        return `${numberWithCommas(price)}원`
                    } else if (currency == 'USD') {
                        return `${currency} $${numberWithCommas(price)}`
                    } else {
                        return `${currency} ${numberWithCommas(price)}`
                    }
                }
                else return "-"
            },
        },
        {
            field: "buyerId",
            headerName: "구매자 ID",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 120,
            maxWidth: 160,
            valueGetter: (params: any) => `${params.row.buyOrder.buyer?.id ?? "-"}`,
        },
        {
            field: "orderedAt",
            headerName: "구매일",
            editable: false,
            sortable: false,
            flex: 1,
            minWidth: 180,
            maxWidth: 180,
            valueGetter: (params: any) =>  params.row.buyOrder?.orderedAt ? `${DateTime.fromISO(params.row.buyOrder?.orderedAt).toFormat("yyyy-MM-dd HH:mm:ss")}` : "-"
        },
        {
            field: "sid",
            headerName: "사전발행 쿠폰코드",
            editable: false,
            sortable: false,
            align: "center",
            flex: 1,
            minWidth: 100,
            maxWidth: 100,
            renderHeader: (params: GridColumnHeaderParams) => (
              <p style={{lineHeight: 1}}>
                  사전발행
                  <br/>
                  쿠폰코드
              </p>
            ),
            valueGetter: (params: any) => `${params.row.couponPreIssue.sid ?? "-"}`,
        },
        {
            field: "couponPreIssueIsUse",
            headerName: "사용여부",
            editable: false,
            sortable: false,
            align: "center",
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => `${params.row.couponPreIssue?.isUse ? "사용" : "미사용"}`,
            renderCell: (params: any) => (
              <div>
                  <Button size={"small"} variant={"text"} color={params.row.couponPreIssue?.isUse ? "success" : "error"}>
                      { params.row.couponPreIssue?.isUse ? (params.row.couponPreIssue?.isUse ? "사용" : "미사용" ) : "-"}
                  </Button>
              </div>
            ),
        },
        {
            field: "preIssueType",
            headerName: "발행타입",
            editable: false,
            sortable: false,
            align: "left",
            flex: 1,
            minWidth: 90,
            maxWidth: 90,
            valueGetter: (params: any) => `${params.row.couponPreIssue.preIssueType ? getEnumTitleByCode( PreIssueTypeTitleMap, params.row.couponPreIssue.preIssueType) : "-"}`,
        },
        {
            field: "couponPreIssueCreatedAt",
            headerName: "선발행 쿠폰 발행일자",
            width: 180,
            align: "center",
            valueGetter: (params: any) => `${params.row.couponPreIssue.createdAt ? DateTime.fromISO(params.row.couponPreIssue.createdAt).toFormat("yyyy-MM-dd HH:mm:ss") : "-"}`
        },
        // {
        //     field: "transactionAt",
        //     headerName: "결제일",
        //     editable: false,
        //     sortable: false,
        //     flex: 1,
        //     minWidth: 80,
        //     maxWidth: 100,
        //     valueGetter: (params: any) => toDateTimeStr(params.value),
        //     renderCell: (params: any) => (
        //       <div style={{ textAlign: "center" }}>
        //           <p>
        //               {toDateStr(params.row.payment?.transactionAt, false)}
        //               <br />
        //               {toTimeStr(params.row.payment?.transactionAt)}
        //           </p>
        //       </div>
        //     ),
        // },
    ]
}

type IssueCouponProps = {
    state: IssueCouponState
    dispatch: any
    fetch: Function
    components: any[]
}

const IssueCouponTable: React.FC<IssueCouponProps> = ({state, dispatch, fetch, components}) => {
    function CustomToolbar() {
        return <GridToolbarContainer style={{justifyContent: "flex-end"}}>{components}</GridToolbarContainer>
    }

    const openBuyOrderPopup = (buyOrder?: CouponIssueCouponItem) => {
        dispatch({type: "TOGGLE_BUY_ORDER_POPUP", payload: buyOrder})
    }

    return (
      <>
          <Stack spacing={1}>
              <div style={{height: 1200, width: "100%"}}>
                  <DataGrid
                    components={{Toolbar: CustomToolbar}}
                    getRowId={(row) => row.id!}
                    columns={createColumns(openBuyOrderPopup)}
                    pagination
                    paginationMode="server"
                    onPageChange={(page) => {
                        fetch({page, size: state.issueCouponList.pageable?.pageSize || 25})
                    }}
                    onPageSizeChange={(size) => {
                        fetch({page: 0, size})
                    }}
                    onRowClick={(params, event, details) => {
                        dispatch({ type: "TOGGLE_CREATE_COUPON_POPUP", payload: params.row })
                    }}
                    page={state.issueCouponList.pageable?.pageNumber}
                    pageSize={state.issueCouponList.pageable?.pageSize}
                    rowCount={state.issueCouponList?.totalElements}
                    rows={state.issueCouponList?.content}
                    //   selectionModel={state.selectedIds}
                    //    checkboxSelection
                    disableSelectionOnClick={true}
                    //   onSelectionModelChange={handleSelection}
                    loading={state.loading}
                  />
              </div>
          </Stack>
      </>
    )
}

export default IssueCouponTable