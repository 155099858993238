import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextareaAutosize, Typography } from "@mui/material"
import React, { useState } from "react"

type ModelRegistrationRequestRejectReasonPopupProps = {
  open: boolean
  zIndex: number
  handleClose: Function
}

const ModelRegistrationRequestRejectReasonPopup: React.FC<ModelRegistrationRequestRejectReasonPopupProps> = ({
                                                                                                               open,
                                                                                                               zIndex,
                                                                                                               handleClose,
                                                                                                             }) => {


  const reasonTypeList: string[] = [
    "선택",
    "미취급 브랜드",
    "입력 정보 불일치",
    "입력 정보 불명확",
    "내부사정에 의한 등록 보류",
    "기타 (직접입력)",
  ]

  const [rejectReasonTypeIndex, setRejectReasonTypeIndex] = useState<number>(0)
  const [rejectReason, setRejectReason] = useState<string>("")
  const [detailRejectReason, setDetailRejectReason] = useState<string>("")

  const onChangeReasonType = (index: number) => {
    setRejectReasonTypeIndex(index)
    if (index !== 0 && index !== 5) {
      setRejectReason(reasonTypeList[index])
    } else if (index === 0) {
      setRejectReason("")
    }
  }

  const onChangeDetailReason = (text: string) => {
    setDetailRejectReason(text)
    setRejectReason(text)
  }

  const handleReject = async () => {
    if (!window.confirm("해당 요청 건을 ‘거절’로 처리하시겠습니까?\n사용자에게 푸시 및 알림톡이 발송됩니다.")) {
      return
    }
    setRejectReasonTypeIndex(0)
    handleClose(rejectReason)
  }

  const onClose = () => {
    setRejectReasonTypeIndex(0)
    handleClose(null)
  }

  return (
    <>
      <Dialog open={open} maxWidth="md" sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} style={{ zIndex }}>
        <DialogTitle>
          <Typography fontWeight={"bold"} fontSize={"24px"}>
            사유 선택 (필수)
          </Typography>
        </DialogTitle>
        <DialogContent>
          <table className={"basic"}>
            <tbody>
            <tr>
              <td style={{ textAlign: "start" }}>
                <Select
                  size={"small"}
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  required
                  value={rejectReasonTypeIndex}
                  onChange={(e) => {
                    const value = e.target.value as number
                    onChangeReasonType(value)
                  }}
                  MenuProps={{
                    style: {
                      zIndex: zIndex,
                    },
                  }}
                >
                  {reasonTypeList.map((row, index) => (
                    <MenuItem value={index}>
                      {row}
                    </MenuItem>
                  ))}
                </Select>

              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "start" }}>
                <TextareaAutosize
                  style={{
                    width: "100%",
                  }}
                  minRows={5}
                  maxLength={40}
                  disabled={rejectReasonTypeIndex !== 5}
                  value={detailRejectReason}
                  onChange={(e) => {
                    const value = e.target.value as string
                    onChangeDetailReason(value)
                  }}
                />
              </td>
            </tr>
            </tbody>
          </table>

        </DialogContent>
        <DialogActions>
          <Button size={"medium"} disabled={rejectReason === ""} variant={"contained"} color={"error"} onClick={handleReject}>
            거절
          </Button>
          &nbsp;&nbsp;
          <Button size={"medium"} variant={"outlined"} color={"error"} onClick={onClose}>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ModelRegistrationRequestRejectReasonPopup
