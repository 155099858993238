import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from "@mui/material"
// import { CouponListTableRow } from "types/CouponType"
import { PageType } from "types/PageType"
import { CouponPreIssueItem, CouponPreIssueListSearchParams } from "../../../types/CouponType"
import React, { useEffect, useReducer } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { downloadPreIssueCouponExcel, getPreIssueCouponList } from "../../../apis/couponAPI"
import PreIssueCouponTable from "./PreIssueCouponTable"
import { toast } from "react-toastify"
import ViewBuyOrderPopup from "../../BuyOrder/ViewBuyOrderPopup"

export interface PreIssueCouponState {
  selectedCoupon?: CouponPreIssueItem
  list: PageType<CouponPreIssueItem>
  searchFilterKey?: keyof CouponPreIssueListSearchParams
  searchFilterValue?: string | null
  searchFilterIsUse?: string
  isShowBuyOrderPopup: boolean
  refresh: boolean
  loading: boolean
}

const initState: (couponId?: string | null) => PreIssueCouponState = (couponId) => {
  let state: PreIssueCouponState = {
    selectedCoupon: undefined,
    list: {
      content: [],
      pageable: { pageSize: 25, pageNumber: 0 },
      totalPages: 0,
      totalElements: 0,
      numberOfElements: 0,
    },
    searchFilterKey: "couponName",
    searchFilterValue: undefined,
    searchFilterIsUse: "all",
    isShowBuyOrderPopup: false,
    refresh: false,
    loading: false,
  }
  if (couponId) {
    state.searchFilterKey = "couponId"
    state.searchFilterValue = couponId
    state.searchFilterIsUse = "all"
  }

  return state
}

interface CouponAction {
  type: string
  payload?: any
}

function reducer(state: PreIssueCouponState, action: CouponAction): PreIssueCouponState {
  switch (action.type) {
    case "FETCH_COUPON":
      return { ...state, list: action.payload }
    case "REFRESH":
      return { ...state, refresh: !state.refresh }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
    case "CHANGE_SEARCH_FILTER_KEY":
      return { ...state, searchFilterKey: action.payload, searchFilterValue: null }
    case "CHANGE_SEARCH_FILTER_VALUE":
      return { ...state, searchFilterValue: action.payload }
    case "CHANGE_SEARCH_FILTER_ISUSE":
      return { ...state, searchFilterIsUse: action.payload }
    case "TOGGLE_BUY_ORDER_POPUP":
      return { ...state, selectedCoupon: action.payload, isShowBuyOrderPopup: !state.isShowBuyOrderPopup }
  }
  return state
}

const PreIssueCouponList = () => {
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)

  const [currentSearchParams] = useSearchParams()
  const [state, dispatch] = useReducer(
    reducer,
    initState(currentSearchParams.get("counponId"))
  )
   const numericFields = [
    { key: "couponId", type: "number" }
  ]

  const fetchList = (searchParams: CouponPreIssueListSearchParams = {}) => {
    let newParams: CouponPreIssueListSearchParams = {
      ...searchParams,
      isUse: state.searchFilterIsUse == "all" ? null : (state.searchFilterIsUse == "1" ? "true" : "false")
    }

    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    dispatch({ type: "START_LOADING" })
    getPreIssueCouponList(newParams)
      .then((preIssueCouponList) => {
        dispatch({ type: "FETCH_COUPON", payload: preIssueCouponList })
      })
      .finally(() => dispatch({ type: "END_LOADING" }))
  }


  useEffect(() => {
    fetchList({
      page: state.list.pageable?.pageNumber || 0,
      size: state.list.pageable?.pageSize || 25,
    })
  }, [state.refresh, currentSearchParams])

  const handleExcelDownloadClick = (params: any = {}) => {
    if (state.searchFilterValue === "") {
      toast.error("조건을 입력해 주세요.")
      return false
    }

    let newParams: CouponPreIssueListSearchParams = {
      isUse: state.searchFilterIsUse == "all" ? null : (state.searchFilterIsUse == "1" ? "true" : "false")
    }

    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    downloadPreIssueCouponExcel(newParams).then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `선발행 쿠폰 내역.xlsx`)
      document.body.appendChild(link)
      link.click()
    })
  }

  return (
    <Grid container spacing={2}>

      <ViewBuyOrderPopup
        open={state.isShowBuyOrderPopup}
        selectedBuyOrderId={state.selectedCoupon?.buyOrder?.id}
        handleClose={() => {
          dispatch({ type: "TOGGLE_BUY_ORDER_POPUP" })
          dispatch({ type: "REFRESH" })
        }}
      />

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", width: "100%" }}>
          <Grid item xs={12} container spacing={2}>

            <Grid item container xs={12} justifyContent="flex-end" spacing={0}>
              <FormControl sx={{ width: "150px" }} size={"small"}>
                <Button
                  variant={"outlined"}
                  size={"small"}
                  style={{ margin: "5px 20px 5px 5px" }}
                  onClick={handleExcelDownloadClick}
                >
                  엑셀 다운로드
                </Button>
              </FormControl>
              <FormControl sx={{ width: "100px" }} size={"small"}>
                <InputLabel id="search_key">사용여부</InputLabel>
                <Select
                  value={state.searchFilterIsUse}
                  onChange={(params) => {
                    dispatch({ type: "CHANGE_SEARCH_FILTER_ISUSE", payload: params.target.value })
                    dispatch({ type: "REFRESH" })
                  }}
                >
                  <MenuItem value={"all"}>전체</MenuItem>
                  <MenuItem value={"1"}>사용</MenuItem>
                  <MenuItem value={"0"}>미사용</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ width: "200px" }} size={"small"}>
                <InputLabel id="search_key">검색항목</InputLabel>
                <Select
                  value={state.searchFilterKey || ""}
                  onChange={(params) => {
                    dispatch({ type: "CHANGE_SEARCH_FILTER_KEY", payload: params.target.value })
                    dispatch({ type: "REFRESH" })
                  }}
                >
                  <MenuItem value={"couponId"}>쿠폰ID</MenuItem>
                  <MenuItem value={"couponName"}>쿠폰명</MenuItem>
                  <MenuItem value={"userId"}>구매자ID</MenuItem>
                  <MenuItem value={"preIssueSid"}>선발행 쿠폰코드</MenuItem>
                  <MenuItem value={"buyOrderId"}>구매번호</MenuItem>
                  <MenuItem value={"productId"}>상품번호</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={{ width: "150px" }}
                size={"small"}
                value={state.searchFilterValue || ""}
                onChange={(params) => {
                  const field = numericFields.find((item) => item.key === (state.searchFilterKey || ""))

                  let value = params.target.value

                  if (field) {
                    if (field.type === "number") {
                      value = value.replace(/[^0-9]/g, "")
                    } else if (field.type === "numberList") {
                      value = value.replace(/[^0-9,]/g, "")
                    }
                  }
                  dispatch({ type: "CHANGE_SEARCH_FILTER_VALUE", payload: value })
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") dispatch({ type: "REFRESH" })
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <PreIssueCouponTable state={state} dispatch={dispatch} fetch={fetchList} components={[]} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default PreIssueCouponList
