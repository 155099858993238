import { DataGrid, GridColumnHeaderParams } from "@mui/x-data-grid"
import React from "react"
import { numberWithCommas } from "../../../utils/NumberUtils"

//@ts-ignore
const PriceSummaryTable = ({ rows, onPageChange, onPageSizeChange, page, size, totalCount }) => {
  const columns = [
    {
      field: "sellDate",
      editable: false,
      sortable: false,
      width: 100,
      headerName: "인상월",
      valueGetter: (params: any) => `${params.row.sellDate}`,
    },
    {
      field: "nation",
      editable: false,
      sortable: false,
      type: "string",
      width: 80,
      headerName: "통화",
      valueGetter: (params: any) => `${params.row.nation}`,
    },
    {
      field: "brandSid",
      editable: false,
      sortable: false,
      type: "string",
      width: 120,
      headerName: "브랜드",
      valueGetter: (params: any) => `${params.row.brandSid}`,
    },
    {
      field: "fullRefNo",
      editable: false,
      sortable: false,
      type: "string",
      minWidth: 150,
      headerName: "Full Ref No.",
      renderCell: (params: any) => {
        return <div style={{ whiteSpace: "normal", wordWrap: "break-word", overflow: "hidden", wordBreak: "break-all", maxWidth: "100%" }}>{params.row.fullRefNo}</div>
      },
    },
    {
      field: "modelName",
      editable: false,
      sortable: false,
      type: "string",
      flex : 1,
      headerName: "모델명",
      valueGetter: (params: any) => `${params.row.modelName}`,
    },
    {
      field: "retailPrice",
      headerName: "공식출시가",
      editable: false,
      sortable: false,
      align: "right" as const,
      minWidth: 150,
      valueGetter: (params: any) => `${numberWithCommas(params.row.retailPrice)} ${params.row.nation === 'KR' ? " 원" : " 엔"}    `,
    },
    {
      field: "updatedAt",
      editable: false,
      sortable: false,
      width: 130,
      align: "center" as const,
      headerName: "수정일시",
      valueGetter: (params: any) => `${params.row.updatedAt ?? "-"}`,
    },
  ]

  return (
    <div style={{ height: 1200, width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        getRowId={(row) => `${row.sellDate}:${row.detailModelId}:${row.nation}`}
        pagination
        page={page}
        pageSize={size}
        rowCount={totalCount}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        paginationMode={"server"}
        disableExtendRowFullWidth={true}
        disableColumnMenu={true}
        getRowHeight={() => null}
      />

    </div>
  )
}

export default PriceSummaryTable