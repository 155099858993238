import styled from "@emotion/styled"
import Modal from "components/Modal"
import { MenuItem, Select } from "@mui/material"
import Button from "components/Button"
import React, { useCallback, useEffect, useState } from "react"
import SearchInput from "../../../../../../components/Search/SearchBar/SearchInput"
import ImageUploadButton from "../../../../../../components/ImageUploadButton"
import { upload } from "../../../../../../apis/resourceAPI"
import { UserTypeChangeParams } from "../../../../../../types/UserType"
import { isNil } from "../../../../../../utils/validationUtils"
import { toast } from "react-toastify"
import { getBankList } from "../../../../../../apis/bankAPI"
import { BankType } from "../../../../../../types/BankType"
import { isEmpty } from "lodash"
import { DateTime } from "luxon"

type Props = {
  isOpen: boolean
  closeModal: () => void
  transformToBusinessUser: (params: UserTypeChangeParams) => void
  userId: number
}

const userTypeMenuList = [
  { value: "INDIVIDUAL_BUSINESS", title: "개인사업자" },
  { value: "CORPORATE", title: "법인사업자" }
]

const UserTypeChangeModal = ({ isOpen, closeModal, transformToBusinessUser, userId }: Props) => {
  const [userType, setUserType] = useState("")
  const [subMallType, setSubMallType] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [businessNumber, setBusinessNumber] = useState("")
  const [bankCode, setBankCode] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [accountHolder, setAccountHolder] = useState("")
  const [businessLicense, setBusinessLicense] = useState<any|null>(null)
  const [businessLicenseUrl, setBusinessLicenseUrl] = useState("")
  const [bankAccount, setBankAccount] = useState<any|null>(null)
  const [bankAccountUrl, setBankAccountUrl] = useState("")
  const [bankList, setBankList] = useState<BankType[]>([])

  const fetchBankList = useCallback(async () => {
    try {
      const response = await getBankList()
      setBankList(response)
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      setSubMallType("")
      setCompanyName("")
      setBusinessNumber("")
      setBankCode("")
      setAccountNumber("")
      setAccountHolder("")
      setBusinessLicense("")
      setBusinessLicenseUrl("")
      setBankAccount("")
      setBankAccountUrl("")
      fetchBankList()
    }
  }, [isOpen, fetchBankList])

  const handleBusinessLicenseImage = (files: FileList | null) => {
    if (files) upload?.(files, [{key: "path", value: "business-user"}]).then(result => {
        setBusinessLicense(result)
        setBusinessLicenseUrl(result.url)
      })
    else {
      setBusinessLicense(null)
      setBusinessLicenseUrl("")
    }
  }

  const handleBankAccountImage = (files: FileList | null) => {
    if (files) upload?.(files, [{key: "path", value: "business-user"}]).then(result => {
      setBankAccount(result)
      setBankAccountUrl(result.url)
    })
    else {
      setBankAccount(null)
      setBankAccountUrl("")
    }
  }

  const clickChangeTypeButton = () => {
    if (isEmpty(userType)) {
      toast.error("구분을 선택하세요.")
      return
    }
    if (isEmpty(companyName)) {
      toast.error("사업자명을 입력하세요.")
      return
    }
    if (isEmpty(businessNumber)) {
      toast.error("사업자등록번호를 입력하세요.")
      return
    }
    if (isEmpty(bankCode)) {
      toast.error("은행을 선택하세요.")
      return
    }
    if (isEmpty(accountNumber)) {
      toast.error("계좌번호를 입력하세요.")
      return
    }
    if (isEmpty(accountHolder)) {
      toast.error("예금주를 입력하세요.")
      return
    }
    if (isEmpty(businessLicense)) {
      toast.error("사업자등록증을 등록하세요.")
      return
    }
    if (isEmpty(bankAccount)) {
      toast.error("통장사본을 등록하세요.")
      return
    }

    const params: UserTypeChangeParams= {
      userType: userType,
      subMallType: subMallType,
      companyName: companyName,
      businessNumber: businessNumber,
      businessLicenseUrl: businessLicenseUrl,
      businessBankAccount: {
        accountHolder: accountHolder,
        accountNumber: accountNumber,
        bank: {
          code: bankCode,
        },
        accountUrl: bankAccountUrl
      }
    }

    transformToBusinessUser(params)

    setUserType("")
    setSubMallType("")
    setCompanyName("")
    setBusinessNumber("")
    setBankCode("")
    setAccountNumber("")
    setAccountHolder("")
    setBusinessLicense("")
    setBusinessLicenseUrl("")
    setBankAccount("")
    setBankAccountUrl("")
    closeModal()
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalContentWrapper>
        <ModalContentHeader>
          <h3>회원 구분 관리</h3>
          <div>개인 회원을 사업자로 변경할 수 있습니다.</div>
        </ModalContentHeader>
        <ModalContentBody>
          <InlineWrapper>
            <LabelWrapper>구분</LabelWrapper>
            <SelectWrapper>
              <Select
                size={"small"}
                defaultValue={userType}
                onChange={(e) => {
                  setSubMallType(e.target.value)
                  if (e.target.value == "INDIVIDUAL_BUSINESS") {
                    setUserType("개인사업자")
                  } else {
                    setUserType("법인사업자")
                  }
                }}
                MenuProps={{
                  style: { zIndex: 10003 },
                }}
              >
                {userTypeMenuList.map((menu) => (
                  <MenuItem value={menu.value} key={menu.title}>
                    {menu.title}
                  </MenuItem>
                ))}
              </Select>
            </SelectWrapper>
          </InlineWrapper>
          <InlineWrapper>
            <LabelWrapper>사업자명</LabelWrapper>
            <SearchInput
              value={companyName}
              onChange={(e) => {
                const inputValue = e.target.value
                if (isNil(inputValue)) {
                  return
                }
                setCompanyName(inputValue.toString())
              }}
            />
          </InlineWrapper>
          <InlineWrapper>
            <LabelWrapper>사업자등록번호</LabelWrapper>
            <SearchInput
              value={businessNumber}
              placeholder={"숫자로만"}
              onChange={(e) => {
                const inputValue = Number(e.target.value)
                if (isNaN(inputValue)) {
                  return
                }
                setBusinessNumber(inputValue.toString())
              }}
            />
          </InlineWrapper>
          <InlineWrapper>
            <LabelWrapper>은행</LabelWrapper>
            <SelectWrapper>
              <Select
                size={"small"}
                defaultValue={bankCode}
                onChange={(e) => setBankCode(e.target.value)}
                MenuProps={{
                  style: { zIndex: 10003 },
                }}
              >
                {bankList.map((menu) => (
                  <MenuItem value={menu.code} key={menu.name}>
                    {menu.name}
                  </MenuItem>
                ))}
              </Select>
            </SelectWrapper>
          </InlineWrapper>
          <InlineWrapper>
            <LabelWrapper>계좌번호</LabelWrapper>
            <SearchInput
              value={accountNumber}
              placeholder={"숫자로만"}
              onChange={(e) => {
                const inputValue = Number(e.target.value)
                if (isNaN(inputValue)) {
                  return
                }
                setAccountNumber(inputValue.toString())
              }}
            />
          </InlineWrapper>
          <InlineWrapper>
            <LabelWrapper>예금주</LabelWrapper>
            <SearchInput
              value={accountHolder}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/(\s*)/g, "")
                if (isNil(inputValue)) {
                  return
                }
                setAccountHolder(inputValue.toString())
              }}
            />
          </InlineWrapper>
          <InlineWrapper>
            <LabelWrapper>사업자등록증</LabelWrapper>
            <ImageUploadButton imageUrl={businessLicense?.url} width={'40%'} height={'40%'} handleImage={handleBusinessLicenseImage} handleDeleteImage={() => handleBusinessLicenseImage(null)}/>
          </InlineWrapper>
          <InlineWrapper>
            <LabelWrapper>통장사본</LabelWrapper>
            <ImageUploadButton imageUrl={bankAccount?.url} width={'40%'} height={'40%'} handleImage={handleBankAccountImage} handleDeleteImage={() => handleBankAccountImage(null)}/>
          </InlineWrapper>
        </ModalContentBody>
        <ModalContentFooter>
          <ButtonWrapper>
            <Button buttonStyle="blue-fill" buttonSize="M" disabled={!userType} onClick={clickChangeTypeButton}>
              수정
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button buttonStyle="black-border" buttonSize="M" onClick={closeModal}>
              닫기
            </Button>
          </ButtonWrapper>
        </ModalContentFooter>
      </ModalContentWrapper>
    </Modal>
  )
}

export default UserTypeChangeModal

const ModalContentWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 15px;
`

const ModalContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  h3 {
    margin: 0 0 10px 0;
  }
`

const ModalContentBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

const ModalContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const ButtonWrapper = styled.div`
  width: 70px;
  :first-child {
    margin-right: 5px;
  }
`

const InlineWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`

const TextArea = styled.textarea`
  flex: 1;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  outline: none;
  padding: 10px;
  display: flex;
  algin-items: center;
`

const TitleWrapper = styled.div`
  padding: 10px;
  background: #eee;
  border-bottom: 1px solid #ccc;
  font-weight: 800;
`

const LabelWrapper = styled.div`
  margin-right: 10px;
  width: 130px;
`

const SelectWrapper = styled.div`
    flex: 1;
    border-radius: 4px;
    outline: none;

    display: flex;
    algin-items: center;
`