import { Stack, FormControl, InputLabel, Select, MenuItem, TextField, Button, Grid } from "@mui/material"
import { DesktopDatePicker } from "@mui/lab"
import { Dispatch, SetStateAction } from "react"
import { SettlementState } from "./useSettlementManager"

interface SettlementManagerSearchBarProps {
  state: SettlementState
  setState: Dispatch<SetStateAction<SettlementState>>
  handleExcelDownload: () => void
  handleRequestPayout: () => void
}

export const SettlementManagerSearchBar: React.FC<SettlementManagerSearchBarProps> = ({ state, setState, handleExcelDownload, handleRequestPayout }) => {
  return (
    <Stack spacing={1} direction={"column"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack direction={"row"}>
          <FormControl sx={{ width: "160px" }} size={"small"}>
            <InputLabel id="search_key">검색항목</InputLabel>
            <Select<"productId"|"userId"|"payoutId">
              value={state.searchFilterKey || ""}
              onChange={(params) => {
                setState((prevState)=>({
                  ...prevState,
                  searchFilterKey:params.target.value as "productId"|"userId"|"payoutId",
                  searchFilterValue:'',
                }))
              }}
            >
              <MenuItem value={"payoutId"}>정산 ID</MenuItem>
              <MenuItem value={"productId"}>상품번호</MenuItem>
              <MenuItem value={"userId"}>판매자 ID</MenuItem>
            </Select>
          </FormControl>
          <TextField
            sx={{ width: "220px" }}
            size={"small"}
            value={state.searchFilterValue || ""}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setState((prevState)=>({
                  ...prevState,
                  refresh:!prevState.refresh
                }))
              }
            }}
            onChange={(params) => {
              setState((prevState)=>({
                ...prevState,
                searchFilterValue:params.target.value
              }))
            }}
          />
          <FormControl sx={{ width: "160px" }} size={"small"}>
            <InputLabel id="date_key">검색기간</InputLabel>
            <Select<"paymentRequestDate" | "paymentApproveDate">
              value={state.dateFilterKey || ""}
              onChange={(params) => {
                setState((prevState)=>({
                  ...prevState,
                  dateFilterKey:params.target.value as "paymentRequestDate" | "paymentApproveDate",
                  refresh:!prevState.refresh
                }))
              }}
            >
              <MenuItem value={"paymentRequestDate"}>지급 요청일</MenuItem>
              <MenuItem value={"paymentApproveDate"}>지급 승인일</MenuItem>
            </Select>
          </FormControl>
          <DesktopDatePicker
            label="시작일"
            inputFormat="yyyy-MM-dd"
            value={state.startDateFilter ?? null}
            onChange={(params) => {
              setState((prevState)=>({
                ...prevState,
                startDateFilter:params,
                refresh:!prevState.refresh
              }))
            }}
            renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
          />
          <DesktopDatePicker
            label="종료일"
            inputFormat="yyyy-MM-dd"
            value={state.endDateFilter ?? null}
            onChange={(params) => {
              setState((prevState)=>({
                ...prevState,
                endDateFilter:params,
                refresh:!prevState.refresh
              }))
            }}
            renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
          />
          <Button variant={"outlined"} size={"small"} style={{ margin: "0 2px" }} onClick={handleExcelDownload}>엑셀 다운로드</Button>
          <Button variant={"outlined"} size={"small"} style={{ margin: "0 2px" }} onClick={handleRequestPayout}>정산 지급 요청하기</Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
