import { httpGet, httpGetToBlob, httpPost, httpPostMultipartFile, httpPut } from "../utils/httpAPI"
import {
  CreateSellOrderRequestBodyType,
  PreProductsExcelParams,
  SellOrderBatchUploadResultType,
  SellOrderExcelParams,
  SellOrderListSearchParams,
  SellOrderType,
} from "types/SellOrderType"
import { CountType } from "types/CountType"
import { PageType } from "types/PageType"

export const countSellOrders: (searchParams: any) => Promise<CountType[]> = (searchParams) =>
  httpGet("/sell-orders/counts", searchParams)

export const getSellOrders: (searchParams: SellOrderListSearchParams) => Promise<PageType<SellOrderType>> = (
  searchParams
) => httpGet("/sell-orders", searchParams)

export const getSellOrdersForQR: (sellOrderList: string[]) => Promise<SellOrderType[]> = (sellOrderList) =>
  httpGet("/sell-orders/qr-list", { sellOrderList: sellOrderList })

export const getSellOrderList: (searchParams: SellOrderListSearchParams) => Promise<SellOrderType[]> = (searchParams) =>
  httpGet("/sell-orders/list", searchParams)

export const getSellOrder: (sid: string) => Promise<SellOrderType> = (sid) => httpGet(`/sell-orders/${sid}`)

export const getSellOrderByProductId: (productId: number) => Promise<SellOrderType> = (productId) =>
  httpGet(`/products/${productId}/sell-order`)

export const createSellOrder = (createSellOrderRequestBody: CreateSellOrderRequestBodyType) =>
  httpPost<SellOrderType>("/sell-orders", createSellOrderRequestBody)

export const updateSellOrder = (
  sid: SellOrderType["id"],
  sellOrder: Partial<Omit<SellOrderType, "id"> & { price?: number }>
): Promise<SellOrderType> => httpPut(`/sell-orders/${sid}`, sellOrder)

export const updateSellOrderProduct = (
  sellOrderId: SellOrderType["id"],
  requestBody: {
    detailModelId?: number
    productTitle?: string
  }
) => httpPut<SellOrderType>(`/sell-orders/${sellOrderId}`, requestBody)

export const confirmReceiving: (confirmReceiving: string[]) => Promise<string[] | SellOrderType[]> = (
  confirmReceiving
) => httpPost("/sell-orders/confirm-receiving", confirmReceiving)

export const cancelSellOrder: (sid: string) => Promise<SellOrderType> = (sid) =>
  httpPost(`/sell-orders/${sid}/cancel`, { reasonType: "변심" })

export const cancelSellOrderRollback: (sid: string) => Promise<SellOrderType> = (sid) =>
  httpPost(`/sell-orders/${sid}/cancel/rollback`, { reasonType: "변심" })

export const approveSellOrder: (sid: string) => Promise<SellOrderType> = (sid) =>
  httpPost(`/sell-orders/${sid}/approve`)

export const rejectSellOrder: (
  sid: string,
  requestBody: {
    rejectReasonType?: string
    rejectReasonDetail?: string
  }
) => Promise<SellOrderType> = (
  sid: string,
  requestBody: {
    rejectReasonType?: string
    rejectReasonDetail?: string
  }
) => httpPost(`/sell-orders/${sid}/reject`, requestBody)

export const cancelSellOrderShipping: (orderId: string) => Promise<void> = (orderId) =>
  httpPost(`/sell-orders/${orderId}/cancel-shipping`)

export const cancelSellOrderShippingDirectPickup: (orderId: string) => Promise<void> = (orderId) =>
  httpPost(`/sell-orders/${orderId}/cancel-shipping/direct-pick-up`)

export const deniedSellOrderShipping: (orderId: string) => Promise<void> = (orderId) =>
  httpPost(`/sell-orders/${orderId}/denied-shipping-by-seller`)

export const transformToConsigmentSellOrder: (orderId: string) => Promise<void> = (orderId) =>
  httpPost(`/sell-orders/${orderId}/transform-to-consignment-sell-order`)

// TODO : Promise<void> VP-6 요구사항에 맞게 변경해야함. 현재 API가 return이 null
// 우선 succeed, failure가 넘어온다고 가정하고 구현
export const uploadSellOrderBatchByExcel: (fileList: FileList) => Promise<SellOrderBatchUploadResultType> = (
  fileList
) => httpPostMultipartFile("/sell-orders/bulk-update", fileList)

export const downloadSellOrderExcel = (params: SellOrderExcelParams): Promise<Blob> =>
  httpGetToBlob("/sell-orders/excel", params)
export const downloadPreProductsExcel = (params: PreProductsExcelParams): Promise<Blob> =>
  httpGetToBlob("/sell-orders/pre-products/excel", params)
