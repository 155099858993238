import { ProductConditionCode } from "./DiagnosisType"
import { ProductType } from "./ProductType"
import { ShopSwipeBannerType } from "./ShopCurationType"

export declare interface ShopContentType {
  id: number | null
  createdAt: string | null
  onDisplay: boolean | null
  priority: number | null
  startedAt: string | null
  endedAt: string | null
  title: string | null
  titleSecondary: string | null
  products: ShopCurationProductType[] | null
  minQuantity: number | null
  maxQuantity: number | null
  excludeSoldOut: boolean
  productCondition: ProductConditionCode[]
  saleType: CurationProductSaleType[]
  swipeBanners: ShopSwipeBannerType[] | null
  curationType: "최근가격상승" | "최근가격하락" | "최신입고순" | "최근가격변동" | "수동"
  description?: string | null
}

export declare type ShopCurationPostRequestBodyType = Omit<ShopContentType, "id" | "createdAt">

export declare interface ShopCurationProductType {
  product: ProductType | null
  priority: number | null
}

export type CurationProductSaleType = "직접판매" | "위탁판매" | "진단전판매"

export const enum SwipeBannerTypeCode {
  STRIP = "STRIP",
  IMAGE = "IMAGE",
}

export const SwipeBannerTypeCodeList: Record<SwipeBannerTypeCode, string> = {
  [SwipeBannerTypeCode.STRIP]: "띠배너",
  [SwipeBannerTypeCode.IMAGE]: "이미지배너",
};


