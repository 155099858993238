import styled from "@emotion/styled"
import Modal from "components/Modal"
import Button from "components/Button"
import SearchInput from "components/Search/SearchBar/SearchInput"
import React, { useState } from "react"
import {
  CouponTargetTypeCode,
  CouponTargetTypeTitleMap,
  PreIssueCouponsParams,
  PreIssueType, PreIssueTypeTitleMap,
} from "../../../../types/CouponType"
import { toast } from "react-toastify"
import { createPreIssueCoupons } from "../../../../apis/couponAPI"
import { FormControl, MenuItem, Select } from "@mui/material"

type Props = {
  open: boolean
  closeModal: () => void
  couponId: number
}

const PreIssueCouponPopupModal = ({ open, closeModal, couponId }: Props) => {
  const [preIssueType, setPreIssueType] = useState<PreIssueType>(PreIssueType.INDIVISUAL)
  const [issueCount, setIssueCount] = useState(0)

  const onPreIssueCoupon = () => {
    if ( issueCount < 1) {
      toast.success("발행 건수를 입력하세요.")
      return
    }
    const params = { couponId:couponId, preIssueType: preIssueType, issueCount:issueCount} as PreIssueCouponsParams
    createPreIssueCoupons(params)
      .then(() => {
        toast.success(`총 ${issueCount}개의 선발행 쿠폰을 발행하였습니다.`)
        closeModal()
      })
      .catch((error) => {
        toast.error(`선발행 쿠폰 발행 실패 (${error.message || error})`)
      })
  }

  return (
    <Modal isOpen={open}>
      <ModalContentWrapper>
        <ModalContentHeader>
          <h3>선발행 쿠폰 발행</h3>
        </ModalContentHeader>
        <ModalContentBody>
          <Title>구분</Title>
          <FormControl fullWidth={true}>
            <Select
              size={"small"}
              value={preIssueType}
              required
              onChange={(e) => {
                setPreIssueType( e.target.value as PreIssueType)
              }}
              MenuProps={{
                style: { zIndex: 10001 },
              }}
            >
              {Object.entries(PreIssueTypeTitleMap).map(([code, title]) => (
                <MenuItem value={code} key={code}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Title>발행수</Title>
            <SearchInput
              value={issueCount}
              onChange={(e) => {
                const inputValue = Number(e.target.value)
                if (isNaN(inputValue)) {
                  return
                }
                setIssueCount(inputValue)
              }
              } />
        </ModalContentBody>
        <ModalContentFooter>
          <ButtonWrapper>
            <Button
              buttonStyle="blue-fill"
              buttonSize="M"
              disabled={! (issueCount || issueCount == 0) }
              onClick={onPreIssueCoupon}
            >
              발행
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button buttonStyle="black-border" buttonSize="M" onClick={closeModal}>
              닫기
            </Button>
          </ButtonWrapper>
        </ModalContentFooter>
      </ModalContentWrapper>
    </Modal>
  )
}

export default PreIssueCouponPopupModal

const ModalContentWrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  padding: 15px;
`

const ModalContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  h3 {
    margin: 0 0 10px 0;
  }
`

const ModalContentBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

const ModalContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const ButtonWrapper = styled.div`
  width: 70px;
  :first-child {
    margin-right: 5px;
  }
`
const Title = styled.div`
    font-size: 16px;
    font-weight: bold;
    flex: 10 10 10px; /* 고정 너비 */
    margin: 10px 0;
`;
