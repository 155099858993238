import styled from "@emotion/styled"
import { Radio, Switch } from "@mui/material"
import { BoxHorizontal, BoxVertical, Typography } from "@viver-inc/viver-ui"
import { upload } from "apis/resourceAPI"
import React, { FunctionComponent, useMemo, useState } from "react"
import { EventFormStateTemplateBodyItemImageType } from "types/EventFormStateType"
import { isNotNil } from "utils/validationUtils"
import { initialEventForm } from ".."
import Button from "../../../../components/Button"
import ImageUploadButton from "../../../../components/ImageUploadButton"
import { toast } from "react-toastify"

export const TemplateEditorImage: FunctionComponent<{
  templateBodyImage: EventFormStateTemplateBodyItemImageType
  setTemplateBodyImage: (templateBodyImage: EventFormStateTemplateBodyItemImageType) => void
  onCheckImageVideoCount: (type:string) => boolean
}> = ({ templateBodyImage, setTemplateBodyImage, onCheckImageVideoCount }) => {

  const [draggedIndex, setDraggedIndex] = useState<number>(0);

  const handleDragStart = (index:number) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (e:any) => {
    e.preventDefault()
  };

  const handleDrop = (index: number) => {
    if (draggedIndex === null) return;

    const newImages = [...templateBodyImage.images]
    const [draggedItem] = newImages.splice(draggedIndex, 1)
    newImages.splice(index, 0, draggedItem)

    const updatedImages = newImages.map((image, idx) => ({
      ...image,
      priority: idx + 1,
    }))

    setTemplateBodyImage({
      ...templateBodyImage,
      images: updatedImages,
    })

    setDraggedIndex(0); // 드래그 상태 초기화

  }


  return (
    <BoxVertical borderWidth={1} borderColor={"#ddd"} borderRadius={1} paddingHorizontal={10} paddingVertical={10}  bottom={10}>
      <Typography variant="sub18" weight="sb">
        이미지 최대 10개, 동영상 최대 3개까지 등록 가능
      </Typography>
      <BoxVertical flex={1} paddingHorizontal={10} paddingVertical={10}>
          {templateBodyImage.images
            // .sort((a, b) => a.priority - b.priority)
            .map((imageItem, index) => (
              // 이미지 영역
              <TemplateEditorImageItem
                key={index}
                index={index}
                imageItem={imageItem}
                imagesSize={templateBodyImage.images.length}
                setImageItem={(imageItem) => {
                  setTemplateBodyImage({
                    ...templateBodyImage,
                    images: templateBodyImage.images.map((image, _index) => {
                      if (index === _index) {
                        return imageItem
                      }
                      return image
                    }),
                  })
                }}
                onClickDeleteImageItem={() => {
                  setTemplateBodyImage({
                    ...templateBodyImage,
                    images: templateBodyImage.images.filter((_, _index) => index !== _index),
                  })
                }}
                onCheckImageVideoCount = {onCheckImageVideoCount}
                onDragStart={() => handleDragStart(index)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop(index)}
              />
            ))}
          {/*추가 버튼*/}
          <ButtonWrapper>
            <Button
              buttonSize="M"
              // disabled={templateBodyImage.images.length >= 10}
              buttonStyle="blue-fill"
              onClick={() => {
                if (onCheckImageVideoCount('ADD')) {
                  return
                }
                setTemplateBodyImage({
                  ...templateBodyImage,
                  images: [...templateBodyImage.images, initialEventForm.templateBodyImage.images[0]],
                })
              }}>
              추가
            </Button>
          </ButtonWrapper>
      </BoxVertical>
    </BoxVertical>
 )
}

const TemplateEditorImageItem: FunctionComponent<{
  index: number
  imagesSize: number
  imageItem: EventFormStateTemplateBodyItemImageType["images"][0]
  setImageItem: (imageItem: EventFormStateTemplateBodyItemImageType["images"][0]) => void
  onClickDeleteImageItem: () => void
  onCheckImageVideoCount: (type:string) => boolean
  onDragStart: () => void
  onDragOver: (e: React.DragEvent<HTMLDivElement>) => void
  onDrop: () => void
}> = ({ index,
       imagesSize,
       imageItem,
       setImageItem,
       onClickDeleteImageItem,
       onCheckImageVideoCount,
       onDragStart,
       onDragOver,
       onDrop}) => {

  const imageItemType = useMemo(() => {
    if (imageItem.imageType == 'IMAGE') {
      if (imageItem.apply) return "APPLY"
      if (isNotNil(imageItem.linkUrl)) return "DEEPLINK"
      return "NONE"
    }
    else {
      return null
    }
  }, [imageItem.apply, imageItem.linkUrl, imageItem.imageType])

  const imageType = useMemo(() => {
    return imageItem.imageType
  }, [imageItem.imageType])
  const handleImage = (image:any) => {
      upload(image, [{ key: "path", value: "resources/event" }]).then((result) => {
        setImageItem({ ...imageItem, imageUrl: result.url })
      })
  }

  const handleImageEnglish = (image:any) => {
    upload(image, [{ key: "path", value: "resources/event" }]).then((result) => {
      setImageItem({ ...imageItem, imageUrlEnglish: result.url })
    })
  }

  const handleImageJapanese = (image:any) => {
    upload(image, [{ key: "path", value: "resources/event" }]).then((result) => {
      setImageItem({ ...imageItem, imageUrlJapanese: result.url })
    })
  }

  const handleThumbnail = (image : any) => {
    upload(image, [{ key: "path", value: "resources/event" }]).then((result) => {
      setImageItem({ ...imageItem, thumbnailUrl: result.url })
    })
  }
  const handleVideo = (image : any) => {
    upload(image).then((result) => {
      setImageItem({ ...imageItem, videoUrl: result.url })
    })
  }
  const handleThumbnailEnglish = (image : any) => {
    upload(image, [{ key: "path", value: "resources/event" }]).then((result) => {
      setImageItem({ ...imageItem, thumbnailUrlEnglish: result.url })
    })
  }
  const handleVideoEnglish = (image : any) => {
    upload(image).then((result) => {
      setImageItem({ ...imageItem, videoUrlEnglish: result.url })
    })
  }

  const handleThumbnailJapanese = (image : any) => {
    upload(image, [{ key: "path", value: "resources/event" }]).then((result) => {
      setImageItem({ ...imageItem, thumbnailUrlJapanese: result.url })
    })
  }
  const handleVideoJapanese = (image : any) => {
    upload(image).then((result) => {
      setImageItem({ ...imageItem, videoUrlJapanese: result.url })
    })
  }

  return (
    // 이미지타입
      <ImageItemWrapper
        draggable
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <BoxHorizontal justifyContent={"space-between"} borderWidth={1} borderColor={"#ddd"} borderRadius={1} paddingHorizontal={10} paddingVertical={10} >
          <BoxHorizontal flex={1}>
            <BoxVertical>
              {/*이미지 영역*/}
              <BoxVertical   justifyContent="flex-start">
                <BoxHorizontal alignItems="center">
                  {index+1}
                  <RadioLabelWrapper>
                    <Radio
                      checked={imageType === "IMAGE"}
                      onChange={(event) => {
                          if (onCheckImageVideoCount('IMAGE')) return
                          setImageItem({ ...imageItem, imageType: event.target.checked ? "IMAGE" : "VIDEO" })
                      }}
                    />
                    <Typography variant="desc14" weight="r">
                      이미지
                    </Typography>
                  </RadioLabelWrapper>
                  <RadioLabelWrapper>
                    <Radio
                      checked={imageType === "VIDEO"}
                      onChange={(event) => {
                        if (onCheckImageVideoCount('VIDEO')) return
                        setImageItem({ ...imageItem, imageType: event.target.checked ? "VIDEO" : "IMAGE" })
                      }}
                    />
                    <Typography variant="desc14" weight="r">
                      동영상(mp4)
                    </Typography>
                  </RadioLabelWrapper>
                </BoxHorizontal>
              </BoxVertical>
              <BoxVertical>
                <BoxHorizontal justifyContent={"flex-start"} width={"100%"}>
                  {imageType == 'IMAGE' &&
                      <BoxVertical>
                        <BoxHorizontal>
                          <ImageWrapper>
                            <BoxVertical alignItems={"center"}>
                              <Typography variant="desc16" weight="m" marginBottom={10}>
                                한국어
                              </Typography>
                              <ImageUploadButton
                                height={'100px'}
                                width={'130px'}
                                buttonHeight={'20px'}
                                imageUrl={imageItem.imageUrl}
                                handleImage={handleImage}
                                handleDeleteImage={()=>{setImageItem({ ...imageItem, imageUrl: null })}}
                              />
                            </BoxVertical>
                            </ImageWrapper>
                          <ImageWrapper>
                            <BoxVertical alignItems={"center"}>
                              <Typography variant="desc16" weight="m" marginBottom={10}>
                                영어
                              </Typography>
                              <ImageUploadButton
                                height={'100px'}
                                width={'130px'}
                                buttonHeight={'20px'}
                                imageUrl={imageItem.imageUrlEnglish}
                                handleImage={handleImageEnglish}
                                handleDeleteImage={()=>{setImageItem({ ...imageItem, imageUrlEnglish: null })}}
                              />
                            </BoxVertical>
                          </ImageWrapper>
                          <ImageWrapper>
                            <BoxVertical alignItems={"center"}>
                              <Typography variant="desc16" weight="m" marginBottom={10}>
                                일본어
                              </Typography>
                              <ImageUploadButton
                                height={'100px'}
                                width={'130px'}
                                buttonHeight={'20px'}
                                imageUrl={imageItem.imageUrlJapanese}
                                handleImage={handleImageJapanese}
                                handleDeleteImage={()=>{setImageItem({ ...imageItem, imageUrlJapanese: null })}}
                              />
                            </BoxVertical>
                          </ImageWrapper>
                        </BoxHorizontal>
                      </BoxVertical>
                    }
                    { imageType == "VIDEO" &&
                      <BoxVertical>
                        <BoxHorizontal>
                          <VideoWrapper>
                            <BoxVertical alignItems={"center"}>
                              <Typography variant="desc16" weight="m" marginBottom={10}>
                                한국어
                              </Typography>
                              <BoxHorizontal>
                                <BoxVertical alignItems={"center"}>
                                  <Typography variant="desc16" weight="m" marginBottom={10}>
                                    썸네일
                                  </Typography>
                                  <ImageUploadButton height={"100px"} width={"130px"}
                                                     buttonHeight={'20px'}
                                                     handleImage={handleThumbnail}
                                                     handleDeleteImage={()=>{setImageItem({ ...imageItem, thumbnailUrl: null })}}
                                                     imageUrl={imageItem.thumbnailUrl}
                                  />
                                </BoxVertical>
                                <BoxVertical alignItems={"center"} paddingLeft={5}>
                                  <Typography variant="desc16" weight="m" marginBottom={10}>
                                    동영상
                                  </Typography>
                                  <ImageUploadButton height={"100px"} width={"130px"}
                                                     buttonHeight={'20px'}
                                                     handleImage={handleVideo}
                                                     handleDeleteImage={()=>{setImageItem({ ...imageItem, videoUrl: null })}}
                                                     videoUrl={imageItem.videoUrl}
                                  />
                                </BoxVertical>
                              </BoxHorizontal>
                            </BoxVertical>
                          </VideoWrapper>
                          <VideoWrapper>
                            <BoxVertical alignItems={"center"}>
                              <Typography variant="desc16" weight="m" marginBottom={10}>
                                영어
                              </Typography>
                              <BoxHorizontal>
                                <BoxVertical alignItems={"center"}>
                                  <Typography variant="desc16" weight="m" marginBottom={10}>
                                    썸네일
                                  </Typography>
                                  <ImageUploadButton height={"100px"} width={"130px"}
                                                     buttonHeight={'20px'}
                                                     handleImage={handleThumbnailEnglish}
                                                     handleDeleteImage={()=>{setImageItem({ ...imageItem, thumbnailUrlEnglish: null })}}
                                                     imageUrl={imageItem.thumbnailUrlEnglish}
                                  />
                                </BoxVertical>
                                <BoxVertical alignItems={"center"} paddingLeft={5}>
                                  <Typography variant="desc16" weight="m" marginBottom={10}>
                                    동영상
                                  </Typography>
                                  <ImageUploadButton height={"100px"} width={"130px"}
                                                     buttonHeight={'20px'}
                                                     handleImage={handleVideoEnglish}
                                                     handleDeleteImage={()=>{setImageItem({ ...imageItem, videoUrlEnglish: null })}}
                                                     videoUrl={imageItem.videoUrlEnglish}
                                  />
                                </BoxVertical>
                              </BoxHorizontal>
                            </BoxVertical>
                          </VideoWrapper>
                          <VideoWrapper>
                            <BoxVertical alignItems={"center"}>
                              <Typography variant="desc16" weight="m" marginBottom={10}>
                                일본어
                              </Typography>
                              <BoxHorizontal>
                                <BoxVertical alignItems={"center"}>
                                  <Typography variant="desc16" weight="m" marginBottom={10}>
                                    썸네일
                                  </Typography>
                                  <ImageUploadButton height={"100px"} width={"130px"}
                                                     buttonHeight={'20px'}
                                                     handleImage={handleThumbnailJapanese}
                                                     handleDeleteImage={()=>{setImageItem({ ...imageItem, thumbnailUrlJapanese: null })}}
                                                     imageUrl={imageItem.thumbnailUrlJapanese}
                                  />
                                </BoxVertical>
                                <BoxVertical alignItems={"center"} paddingLeft={5}>
                                  <Typography variant="desc16" weight="m" marginBottom={10}>
                                    동영상
                                  </Typography>
                                  <ImageUploadButton height={"100px"} width={"130px"}
                                                     buttonHeight={'20px'}
                                                     handleImage={handleVideoJapanese}
                                                     handleDeleteImage={()=>{setImageItem({ ...imageItem, videoUrlJapanese: null })}}
                                                     videoUrl={imageItem.videoUrlJapanese}
                                  />
                                </BoxVertical>
                              </BoxHorizontal>
                            </BoxVertical>
                          </VideoWrapper>
                        </BoxHorizontal>
                      </BoxVertical>
                    }
                </BoxHorizontal>
              </BoxVertical>
              {/*노출 없음 응모하기 딥링크*/}
              <BoxVertical flex={1} justifyContent="center">
                <BoxHorizontal alignItems="center" paddingLeft={20} paddingTop={10}>
                  {/*노출*/}
                  <BoxHorizontal alignItems="center">
                    <Switch
                      checked={imageItem.onDisplay}
                      onChange={(e, check) => {
                        setImageItem({ ...imageItem, onDisplay: check })
                      }}
                    />
                    <Typography variant="desc14" weight="r">
                      노출
                    </Typography>
                  </BoxHorizontal>
                  { imageType == 'IMAGE' &&
                    <>
                      {/*없음*/}
                      <BoxHorizontal alignItems="center">
                        <RadioLabelWrapper>
                          <Radio
                            checked={imageItemType === "NONE"}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setImageItem({ ...imageItem, linkUrl: undefined, apply: false })
                              }
                            }}
                          />
                          <Typography variant="desc14" weight="r">
                            없음
                          </Typography>
                        </RadioLabelWrapper>
                      </BoxHorizontal>
                      {/*응모하기*/}
                      <BoxHorizontal alignItems="center">
                        <RadioLabelWrapper>
                          <Radio
                            checked={imageItemType === "APPLY"}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setImageItem({ ...imageItem, linkUrl: undefined, apply: true })
                              }
                            }}
                          />
                          <Typography variant="desc14" weight="r">
                            응모하기
                          </Typography>
                        </RadioLabelWrapper>
                      </BoxHorizontal>
                      {/*딥링크*/}
                      <BoxHorizontal alignItems="center" flex={1}>
                        <RadioLabelWrapper>
                          <Radio
                            checked={imageItemType === "DEEPLINK"}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setImageItem({ ...imageItem, linkUrl: "", apply: false })
                              }
                            }}
                          />
                          <BoxHorizontal width={50}>
                            <Typography variant="desc14" weight="r">
                              딥링크
                            </Typography>
                          </BoxHorizontal>
                        </RadioLabelWrapper>
                        <Input
                          value={imageItem.linkUrl ?? ""}
                          placeholder={
                            imageItemType === "DEEPLINK"
                              ? "contents/82 | viver://contents/82 | https://www.viver.co.kr/contents/82"
                              : ""
                          }
                          onChange={(event) => {
                            setImageItem({ ...imageItem, linkUrl: event.target.value })
                          }}
                          style={{width:"290px"}}
                        />
                      </BoxHorizontal>
                    </>
                  }
                </BoxHorizontal>
              </BoxVertical>
            </BoxVertical>
          </BoxHorizontal>
          {/*삭제*/}
          <BoxHorizontal>
            {
              imagesSize !== 1 &&
              <>
                <ButtonWrapper>
                  <Button
                    buttonSize="M"
                    buttonStyle="red-fill"
                    onClick={onClickDeleteImageItem}>
                    삭제
                  </Button>
                </ButtonWrapper>
              </>
            }
          </BoxHorizontal>
        </BoxHorizontal>
      </ImageItemWrapper>

  )
}

const Input = styled.input`
  font-size: 16px;
  width: 100%;
  outline: none;
  height: 40px;
  padding: 10px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  margin-right: 10px;
`
const ButtonWrapper = styled.div`
  width: 50px; 
`

const ImageItemWrapper = styled.div`
    margin-bottom: 10px; 
    background-color: #eeeeee;
`
const ImageWrapper = styled.div`
    margin-top: 5px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 10px;
`

const VideoWrapper = styled.div`
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px; 
`

const RadioLabelWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`