import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import RowBlock from "components/RowBlock"
import { DateTimePicker } from "@mui/lab"
import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import { ShopCurationType, ShopSwipeBannerType, ShoSwipeBannerCreateBodyType } from "types/ShopCurationType"
import styled from "@emotion/styled"
import { toast } from "react-toastify"
import { isNil } from "utils/validationUtils"
import SwipeBannerDetailTable from "./SwipeBannerDetailTable"
import SwipeBannerDetailEditorPopup from "./SwipeBannerDetailEditorPopup"
import ShopBannerCurationAPI from "../../../../apis/shopBannerCurationAPI"
import CheckBox from "../../../../components/Checkbox"
import { SwipeBannerTypeCode, SwipeBannerTypeCodeList } from "../../../../types/ShopContentType"
import { CouponIssueTargetTypeTitleMap } from "../../../../types/CouponType"

type SwipeBannerStateType = ShoSwipeBannerCreateBodyType | ShopCurationType
const initState: SwipeBannerStateType = {
  title: null,
  titleSecondary: null,
  swipeBanners: [],
  onDisplay: true,
  priority: null,
  startedAt: DateTime.now().toISO(),
  endedAt: DateTime.now().toISO(),
  showDomesticUser: true,
  showForeignUser: false,
  bannerType: SwipeBannerTypeCode.STRIP
}

type Props = {
  id: number | null
  handleClose: () => void
  editorType: "EDIT" | "CREATE"
}

const SwipeBannerEditorPopup = ({ id, handleClose, editorType }: Props) => {
  const [state, setState] = useState<SwipeBannerStateType>(initState)
  const [showSwipeBannerDetailCreatePopup, setShowSwipeBannerDetailCreatePopup] = useState(false)
  const [showSwipeBannerDetailUpdatePopup, setShowSwipoeBannerDetailUpdatePopup] = useState(false)
  const [currentSwipeBanner, setCurrentSwipeBanner] = useState<ShopSwipeBannerType | null>()

  const handleOnChangeState = (partialState: Partial<SwipeBannerStateType>) => {
    setState((state) => (state ? { ...state, ...partialState } : state))
  }


  const handleOnClickSubmitButton = async () => {

    if (isNil(state.title)) {
      toast.error("제목을 입력하세요.")
      return
    }
    if (isNil(state.startedAt)) {
      toast.error("노출 시작일자를 입력하세요.")
      return
    }
    if (isNil(state.endedAt)) {
      toast.error("노출 종료일자를 입력하세요.")
      return
    }
    if (state.startedAt > state.endedAt) {
      toast.error("노출 기간을 확인하세요.")
      return
    }
    if (state.swipeBanners?.length!! < 1) {
      toast.error("노출 배너를 등록하세요.")
      return
    }

    if (editorType === "EDIT") {
      await ShopBannerCurationAPI.update(state as ShopCurationType)
    } else {
      await ShopBannerCurationAPI.create(state as ShoSwipeBannerCreateBodyType)
    }
    handleClose()
  }

  const handleOpenShowSwipoeBannerDetailUpdatePopup = (swipeBanner: ShopSwipeBannerType) => {
    setCurrentSwipeBanner(  swipeBanner)
    setShowSwipeBannerDetailCreatePopup(true)
  };

  const handleSwipeBannerDetailAdd = (swipeBanner: ShopSwipeBannerType) => {
    setState(prevState => {
      const newId = (prevState?.swipeBanners?.length || 0) + 1;

      const newSwipeBanner: ShopSwipeBannerType = {
        ...swipeBanner,
        id: newId * -1,
        //priority: (state.swipeBanners?.length  ?? 0 ) + 1
      };

      return {
        ...prevState,
        swipeBanners: prevState ? [...(prevState.swipeBanners || []), newSwipeBanner] : [newSwipeBanner]
      };
      // return {
      //   ...prevState,
      //   swipeBanners: prevState ? [...(prevState.swipeBanners || []), swipeBanner] : [swipeBanner]
      // };
    });
    setShowSwipeBannerDetailCreatePopup(false)
  };
  const handleSwipeBannerDetailChange = (updatedSwipeBanner: ShopSwipeBannerType) => {
    setState(prevState => {
      const updatedSwipeBanners = (prevState?.swipeBanners || []).map(swipeBanner => {
        if (swipeBanner.id === updatedSwipeBanner.id) {
          return updatedSwipeBanner;
        }
        return swipeBanner;
      });

      return {
        ...prevState,
        swipeBanners: updatedSwipeBanners
      };
    });
    setShowSwipeBannerDetailCreatePopup(false)
  };

  const handleSwipsBannerDetailApply = (swipeBanners: ShopSwipeBannerType[]) => {
    setState(prevState => ({
      ...prevState,
      swipeBanners: swipeBanners
    }));
    setShowSwipeBannerDetailCreatePopup(false)
  };

  const handleSwipsBannerDetailUp = (index: number) => {
    if ( state.swipeBanners != null && state.swipeBanners?.length > 0) {
      if (index >0) {
        setState(prevState => {
          if ( prevState && prevState.swipeBanners) {
            let updatedSwipeBanners = [
              ...prevState.swipeBanners.slice(0, index-1),
              prevState.swipeBanners[index],
              prevState.swipeBanners[index-1],
              ...prevState.swipeBanners.slice(index + 1)
            ];

            updatedSwipeBanners = updatedSwipeBanners.map(banner => ({
              ...banner,
              priority: null
            })) ;

            return {
              ...prevState,
              swipeBanners: updatedSwipeBanners
            };
          }
          else {
            return  prevState
          }
        });
      }
    }
  };

  const handleSwipsBannerDetailDown = (index: number) => {
    if ( state.swipeBanners != null && state.swipeBanners?.length > 0) {
      if (index < state.swipeBanners.length - 1) {
        setState(prevState => {
          if ( prevState && prevState.swipeBanners) {
            let updatedSwipeBanners = [
              ...prevState.swipeBanners.slice(0, index),
              prevState.swipeBanners[index + 1],
              prevState.swipeBanners[index],
              ...prevState.swipeBanners.slice(index + 2)
            ];

            updatedSwipeBanners = updatedSwipeBanners.map(banner => ({
              ...banner,
              priority: null
            })) ;

            return {
              ...prevState,
              swipeBanners: updatedSwipeBanners
            };
          }
          else {
            return  prevState
          }
        });
      }
    }
  };

  const fetchSwipeBannerDetail = (curationId: number) => {
    ShopBannerCurationAPI.getDetail(curationId).then((curation) => {
      setState(curation)
    })
  }

  useEffect(() => {
    if (!!id) {
      fetchSwipeBannerDetail(id)
    } else {
      setState(initState)
    }
  }, [id])

  return (
    <Dialog open={true} maxWidth={"xl"}  fullWidth    >
      <DialogTitle>스와이프 배너 {editorType === "EDIT" ? "수정" : "생성"}</DialogTitle>
      <DialogContent>
        <Stack spacing={3} style={{width:'100%', height:'900px'}} >
          <RowBlock title={"배너 타입"}>
            <Stack direction={'row'}  justifyContent={'flex-start'}>
              <RadioGroup row sx={{ justifyContent: "center" }}
                          onChange={(event, value) => {
                            // @ts-ignore
                            setState({...state, bannerType: value})
                          }}
              >
                {Object.entries(SwipeBannerTypeCodeList).map(([code, title]) => (
                  <FormControlLabel
                    key={code}
                    value={code}
                    label={title}
                    checked={state.bannerType === code}
                    control={<Radio />}
                  />
                ))}
              </RadioGroup>
            </Stack>
          </RowBlock>
          <RowBlock title={"설명"}>
            <TextField
              size={'small'}
              fullWidth
              value={state.description}
              onChange={(e) => setState({...state, description: e.target.value})}
              placeholder={'스와이프 배너의 설명을 입력 해주세요. (서비스에 노출되진 않습니다)'}/>
          </RowBlock>
          <RowBlock title={"제목"}>
            <TextField
              size={"small"}
              fullWidth
              value={state?.title}
              onChange={(e) => handleOnChangeState({ title: e.target.value })}
              placeholder={"스와이프 배너 제목"}
            />
          </RowBlock>
          <RowBlock title={"부제목"}>
            <TextField
              size={"small"}
              fullWidth
             value={state?.titleSecondary}
              onChange={(e) => handleOnChangeState({ titleSecondary: e.target.value })}
              placeholder={"스와이프 부제목"}
            />
          </RowBlock>
          <RowBlock title={"노출 여부"}>
            <Stack direction={"row"}>
              <Switch
                checked={state ? Boolean(state.onDisplay) : false}
                onChange={(e) => handleOnChangeState({ onDisplay: e.target.checked })}
              />
            </Stack>
          </RowBlock>
          <RowBlock title={"노출 기간"}>
            <Stack direction={"row"} spacing={2}>
              <DateTimePicker
                onChange={(e) => {
                  setState({...state, startedAt: e?.toISO() ?? DateTime.now().toISO()})
                }}
                value={DateTime.fromISO(state.startedAt ?? DateTime.now().toISO())}
                renderInput={(props) => (<TextField {...props} />)}
                label={'게시 시작'}
                inputFormat={"yyyy-MM-dd HH:mm"}/>
              <Typography>~</Typography>
              <DateTimePicker
                onChange={(e) => {
                  setState({...state, endedAt: e?.toISO() ?? DateTime.now().toISO()})
                }}
                value={DateTime.fromISO(state.endedAt ?? DateTime.now().toISO())}
                renderInput={(props) => (<TextField {...props} />)}
                label={'게시 종료'}
                inputFormat={"yyyy-MM-dd HH:mm"}/>
            </Stack>
          </RowBlock>
          <RowBlock title={"노출"}   >
            <Stack direction={'row'} alignItems={'center'}   >
              <CheckBox checked={state?.showDomesticUser}   id="showDomesticUser"  onChange={() => setState((pre) => ({...state, showDomesticUser: !state.showDomesticUser})) } />
              <CheckBoxLabel htmlFor="showDomesticUser" >
                내국인 회원 (국내거주 외국인 포함)
              </CheckBoxLabel>
              <CheckBox checked={state?.showForeignUser}   id="showForeignUser"  onChange={() => setState((pre) => ({...state, showForeignUser: !state.showForeignUser})) } />
              <CheckBoxLabel htmlFor="showForeignUser" >
                외국인 회원
              </CheckBoxLabel>
            </Stack>
          </RowBlock>
          <RowBlock title={"노출 배너"}>
            <SwipeBannerDetailTable
              swipeBanners={state?.swipeBanners || []}
              setSwipeBanners={handleSwipsBannerDetailApply}
              setUpSwipeBanners={handleSwipsBannerDetailUp}
              setDownSwipeBanners={handleSwipsBannerDetailDown}
              openShowSwipoeBannerDetailCreatePopup={() => {
                setCurrentSwipeBanner(null)
                setShowSwipeBannerDetailCreatePopup(true)
              }}
              openShowSwipoeBannerDetailUpdatePopup={handleOpenShowSwipoeBannerDetailUpdatePopup}
            />
          </RowBlock>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant={"contained"} color={"primary"} onClick={handleOnClickSubmitButton}>
          {editorType === "EDIT" ? "수정" : "등록"}
        </Button>
        <Button variant={"contained"} color={"error"} onClick={handleClose}>
          취소
        </Button>
      </DialogActions>

      {(showSwipeBannerDetailCreatePopup  ) && (
        <SwipeBannerDetailEditorPopup
          open={showSwipeBannerDetailCreatePopup  }
          handleClose={() => {setShowSwipeBannerDetailCreatePopup(false)}}
          swipeBanner = {isNil(currentSwipeBanner) ? null : currentSwipeBanner}
          addSwipeBanner={handleSwipeBannerDetailAdd}
          setSwipeBanner={handleSwipeBannerDetailChange}
          editorType={isNil(currentSwipeBanner) ? "CREATE" : "EDIT"}
        />
      )}
    </Dialog>
  )
}

export default SwipeBannerEditorPopup

const InlineWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`

const Label = styled.label`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  margin-left: 5px;
`
const CheckBoxLabel = styled.label`
  margin-right: 32px;
  margin-left: 10px;
  font-size: 15px;
`