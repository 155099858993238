import packageJson from '../../package.json'
export function getEnumTitleByCode<T extends Record<string, string>>(
  enumType: T,
  code: keyof T | T[keyof T]
): string {
  // Enum의 값을 전달하면 그에 해당하는 타이틀을 반환
  if (typeof code === "string") {
    const found = Object.entries(enumType).find(([, value]) => value === code);
    if (found) {
      return found[1];
    }
  }
  // Enum의 key를 전달하면 해당하는 타이틀을 반환
  const key = code as keyof T;
  return enumType[key] || "알 수 없는 코드";
}

export function getEnumValue<T extends Record<string, string>>(enumType: T, value: T[keyof T]): keyof T | undefined {
  return Object.entries(enumType).find(([_, enumValue]) => enumValue === value)?.[0] as keyof T | undefined;
}

export function getEnumList<T extends { [key: string]: string | number }>(
  enumType: T
): { code: keyof T; title: string }[] {
  return Object.entries(enumType)
    .filter(([key, value]) => isNaN(Number(key)))
    .map(([key, value]) => ({
      code: key as keyof T,
      title: value as string,
    }));
}