import { getBrands } from "apis/brandAPI"
import { commonResourceUpload } from "apis/commonResourceAPI"
import { getModelList } from "apis/modelAPI"
import {
  acceptModelRequest,
  cancelModelRequest,
  createModelRequest,
  getModelRequestDetail,
  rejectModelRequest,
  updateModelRequestDetail,
} from "apis/modelRequestAPI"
import { useCallback, useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { useMount } from "react-use"

import { ModelRequestDetailType } from "types/ModelRequestType"

const initialModelFormState = {
  brand: {
    name: "",
    sid: "",
  },
  model: {
    name: "",
    id: "",
  },
  name: "",
  refNo: "",
  fullRefNo: "",
  note: "",
  resources: [],

  rejectedAt: undefined,
  rejectedReason: undefined,
  rejectedBy: undefined,
}

export const useModelRequestDetailData = (requestId: null | number, refreshAndCloseModal: () => void) => {
  const [brandOptionList, setBrandOptionList] = useState<{ value: string; title: string }[]>([])
  const [modelOptionList, setModelOptionList] = useState<{ value: string; title: string }[]>([])
  const [originalRequestDetail, setOriginalRequestDetail] = useState<ModelRequestDetailType | null>(null)
  const [formState, setFormState] = useState<{
    brand: {
      name: string
      sid: string
    }
    model: {
      name: string
      id: string
    }
    name: string
    refNo: string
    fullRefNo: string

    note: string
    resources: ModelRequestDetailType["resources"]

    rejectedAt: string | undefined
    rejectedReason: string | undefined
    rejectedBy: string | undefined
  }>(initialModelFormState)

  const fetchBrandOptionList = useCallback(async () => {
    const brandList = await getBrands()

    setBrandOptionList(
      brandList.map((brand) => ({
        title: brand.name || "",
        value: brand.sid || "",
      })),
    )
  }, [])

  const fetchModelOptionList = useCallback(async (brand) => {
    const { content: modelList } = await getModelList({
      page: 0,
      size: 300,
      brand,
      name: "",
    })

    setModelOptionList(
      modelList.map((model) => ({
        title: model.name || "",
        value: model.id.toString() || "",
      })),
    )
  }, [])

  const getSelectedBrandOption = useCallback(
    async (brandSid: string) => {
      if (brandOptionList.length < 1) {
        await fetchBrandOptionList()
      }

      return brandOptionList.find((brand) => brand.value === brandSid)
    },
    [brandOptionList, fetchBrandOptionList],
  )

  const getSelectedModelOption = useCallback(
    async (modelId: string) => {
      if (modelOptionList.length < 1) {
        await fetchModelOptionList("")
      }

      return modelOptionList.find((model) => model.value === modelId)
    },
    [modelOptionList, fetchModelOptionList],
  )

  const onSelectBrandOption = useCallback(
    (sid: string) => {
      const brandOption = brandOptionList.find((brand) => brand.value === sid)

      setFormState((prev) => ({
        ...prev,
        brand: {
          sid: brandOption?.value || "",
          name: brandOption?.title || "",
        },
      }))
    },
    [brandOptionList],
  )

  const onSelectModelOption = useCallback(
    (id: string) => {
      const modelOption = modelOptionList.find((model) => model.value === id)

      setFormState((prev) => ({
        ...prev,
        model: {
          id: modelOption?.value || "",
          name: modelOption?.title || "",
        },
      }))
    },
    [modelOptionList],
  )

  const resetFormData = useCallback(() => {
    setOriginalRequestDetail(null)
    setFormState(initialModelFormState)
  }, [])

  useEffect(() => {
    if (!requestId) {
      resetFormData()
    } else {
      getModelRequestDetail(requestId)
        .then(async (response) => {
          const brandOption = await getSelectedBrandOption(response.brand.sid!)
          const modelOption = await getSelectedModelOption(response.model.id?.toString() || "")
          setOriginalRequestDetail(response)
          setFormState({
            brand: {
              sid: brandOption?.value || "",
              name: brandOption?.title || "",
            },
            model: {
              name: modelOption?.title || "",
              id: modelOption?.value || "",
            },
            name: response.name || "",
            note: response.note || "",
            refNo: response.refNo || "",
            fullRefNo: response.fullRefNo || "",
            resources: response.resources,

            rejectedAt: response.rejectedAt,
            rejectedReason: response.rejectedReason,
            rejectedBy: response.rejectedBy?.name,
          })
        })
        .catch(() => toast.error("상세 요청을 불러오는데 실패했습니다."))
    }
  }, [requestId, getSelectedBrandOption, getSelectedModelOption, resetFormData])

  const onChangeFormState = (key: string, value: string) => {
    setFormState((prev) => ({ ...prev, [key]: value }))
  }

  const onAddImage = useCallback(async (fileList: FileList) => {
    const result = await commonResourceUpload(fileList)

    setFormState((prev) => ({ ...prev, resources: prev.resources.concat(result) }))
  }, [])

  const onDeleteImage = useCallback((id: number) => {
    setFormState((prev) => ({ ...prev, resources: prev.resources.filter((resource) => resource.id !== id) }))
  }, [])

  const handleSaveModelRequest = useCallback(async () => {
    try {
      if (!requestId) {
        await createModelRequest({
          brand: { sid: formState.brand.sid },
          model: { id: formState.model.id },
          name: formState.name,
          refNo: formState.refNo,
          fullRefNo: formState.fullRefNo,
          note: formState.note,
          resources: formState.resources,
        })
        toast.success("모델 등록 요청이 완료되었습니다.")
        refreshAndCloseModal()
      } else {
        await updateModelRequestDetail(requestId, {
          brand: { sid: formState.brand.sid },
          model: { id: formState.model.id },
          name: formState.name,
          refNo: formState.refNo,
          fullRefNo: formState.fullRefNo,
          note: formState.note,
          resources: formState.resources,
        })
        toast.success("모델 등록 요청이 수정되었습니다.")
        refreshAndCloseModal()
      }
    } catch {
      toast.error("등록에 실패했습니다.")
    }
  }, [requestId, formState, refreshAndCloseModal])

  const handleRejectModelRequest = useCallback(async (rejectReason: string) => {
    if (!requestId || !rejectReason) return

    try {
      await rejectModelRequest(requestId, rejectReason)
      refreshAndCloseModal()
      toast.success("모델 등록을 거절했습니다.")
    } catch (e) {
      toast.error("모델 등록 거절에 실패했습니다.")
    }
  }, [requestId, refreshAndCloseModal])

  const handleAcceptModelRequest = useCallback(async () => {
    if (!requestId) return

    try {
      await acceptModelRequest(requestId)
      refreshAndCloseModal()
      toast.success("모델 등록을 수락했습니다.")
    } catch (e) {
      toast.error("모델 등록 수락에 실패했습니다.")
    }
  }, [requestId, refreshAndCloseModal])

  const handleCancelModelRequest = useCallback(
    async (modelId: string) => {
      if (!requestId) return

      try {
        await cancelModelRequest(requestId, { detailModel: { id: modelId } })
        refreshAndCloseModal()
        toast.success("모델 중복처리를 완료했습니다.")
      } catch (e) {
        toast.error("모델 중복처리에 실패했습니다.")
      }
    },
    [requestId, refreshAndCloseModal],
  )

  useMount(() => {
    fetchBrandOptionList()
    fetchModelOptionList("")
  })

  const buttonStatus = useMemo(() => {
    if (!originalRequestDetail?.status) {
      return {
        activeSave: true,
        activeCancel: false,
        activeAccept: false,
        activeReject: false,
      }
    }

    if (originalRequestDetail.status === "등록완료") {
      return {
        activeSave: true,
        activeCancel: false,
        activeAccept: true,
        activeReject: false,
      }
    }

    if (originalRequestDetail.status === "요청취소") {
      return {
        activeSave: true,
        activeCancel: true,
        activeAccept: false,
        activeReject: false,
      }
    }

    if (originalRequestDetail.status === "등록거절") {
      return {
        activeSave: true,
        activeCancel: false,
        activeAccept: false,
        activeReject: false,
      }
    }

    if (originalRequestDetail.status === "등록중") {
      return {
        activeSave: true,
        activeCancel: false,
        activeAccept: true,
        activeReject: false,
      }
    }

    if (originalRequestDetail.status === "등록요청") {
      return {
        activeSave: true,
        activeCancel: true,
        activeAccept: true,
        activeReject: true,
      }
    }

    return {
      activeSave: true,
      activeCancel: true,
      activeAccept: true,
      activeReject: true,
    }
  }, [originalRequestDetail?.status])

  return {
    originalRequestDetail,
    buttonStatus,
    formState,
    setFormState,
    brandOptionList,
    modelOptionList,
    onSelectBrandOption,
    onSelectModelOption,
    resetFormData,
    onChangeFormState,
    onAddImage,
    onDeleteImage,
    handleSaveModelRequest,
    handleRejectModelRequest,
    handleAcceptModelRequest,
    handleCancelModelRequest,
  }
}
