import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack, Switch,
    TextField, Typography,
} from "@mui/material"
import RowBlock from "../../../../components/RowBlock"
import ImageUploadButton from "../../../../components/ImageUploadButton"
import React, { useEffect, useState } from "react"
import { upload } from "../../../../apis/resourceAPI"
import { ThemeModeTypeCode, ThemeModeTypeCodeList } from "../../../../types/ShopHeroBannerType"
import { isNil } from "../../../../utils/validationUtils"
import { toast } from "react-toastify"
import { ShopSwipeBannerType } from "../../../../types/ShopCurationType"
import { DateTime } from "luxon"
import { DateTimePicker } from "@mui/lab"

const initState: ShopSwipeBannerType = {
    id: 0,
    title: null,
    bannerImageUrl: null,
    bannerLinkUrl: null,
    themeModeType: ThemeModeTypeCode.DARK,
    title1: null,
    title2: null,
    onDisplay: true,
    priority: null,
    startedAt: DateTime.now().toISO(),
    endedAt: DateTime.now().toISO()
}

type Props = {
    open: boolean
    swipeBanner: ShopSwipeBannerType | null
    addSwipeBanner: (swipeBanner: ShopSwipeBannerType) => void
    setSwipeBanner: (swipeBanner: ShopSwipeBannerType) => void
    handleClose: () => void
    editorType: "EDIT" | "CREATE"
}

// @ts-ignore
const SwipeBannerDetailEditorPopup = ({open, swipeBanner, addSwipeBanner, setSwipeBanner, handleClose, editorType} : Props) => {

    const [state, setState] = useState<ShopSwipeBannerType>(initState)

    useEffect(() => {
        if ( editorType=='CREATE') {
            setState(initState)
        }
        else {
            setState(swipeBanner!!)
        }
    }, [open,swipeBanner])


    // @ts-ignore
    const handleBannerImage = (image) => {
        upload(image).then((result) => {
            setState({
                ...state,
                bannerImageUrl: result.url,
            })
        })
    }


    const handleSubmit = () => {

        if (isNil(state.title)) {
            toast.error("제목을 입력하세요.")
            return
        }
        if (  isNil(state.bannerImageUrl)) {
            toast.error("배너 이미지를 선택하세요.")
            return
        }
        // if (isNil(state.bannerLinkUrl)) {
        //     toast.error("링크 URL를 입력하세요.")
        //     return
        // }
        if (isNil(state.startedAt)) {
            toast.error("노출 시작일자를 입력하세요.")
            return
        }
        if (isNil(state.endedAt)) {
            toast.error("노출 종료일자를 입력하세요.")
            return
        }
        if (state.startedAt > state.endedAt) {
            toast.error("노출 기간을 확인하세요.")
            return
        }
        if (editorType === 'EDIT')
            setSwipeBanner(state)
        else
            addSwipeBanner(state)
    }

    return (
        <Dialog open={open} maxWidth={"lg"}>
            <DialogTitle>배너 {editorType === "EDIT" ? "수정" : "생성"}</DialogTitle>
            <DialogContent>
                <Stack direction={'row'}>
                    <Stack spacing={3} style={{width:'100%', height:'800px'}}>
                        <RowBlock title={"제목"}><TextField size={'small'}   fullWidth value={state.title}
                                                          onChange={(e) => {
                                                              if (e.target.value.length <= 8) {
                                                                  setState({ ...state, title: e.target.value })
                                                              }
                                                          }} />
                        </RowBlock>

                        <RowBlock title={"배너 이미지"}>
                            <Stack direction={'column'} alignItems={'flex-start'} spacing={2} alignContent={'space-between'} >
                                <ImageUploadButton  height={'200px'} width={'400px'}  handleImage={handleBannerImage} imageUrl={state.bannerImageUrl}  handleDeleteImage={() => {setState({...state, bannerImageUrl: null})}}/>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"타입"}>
                            <Stack direction={'row'}  justifyContent={'flex-start'}>
                                <RadioGroup row sx={{ justifyContent: "center" }}
                                            onChange={(event, value) => {
                                                // @ts-ignore
                                                setState({...state, themeModeType: value})
                                            }}
                                >
                                    {ThemeModeTypeCodeList.map((item) => (
                                      <FormControlLabel
                                        key={item.value}
                                        value={item.value}
                                        label={item.title}
                                        checked={state.themeModeType === item.value}
                                        control={<Radio />}
                                      />
                                    ))}
                                </RadioGroup>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"텍스트"}   >
                            <Stack direction={"column"} spacing={1}>
                                <TextField size={'small'} fullWidth inputProps={{maxLength:50}} value={state.title1} onChange={(e) => setState({...state, title1: e.target.value})} placeholder={'첫째줄 소문구'}/>
                                <TextField size={'small'} fullWidth inputProps={{maxLength:50}} value={state.title2} onChange={(e) => setState({...state, title2: e.target.value})} placeholder={'둘째줄 대문구'}/>
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"링크 URL"}><TextField size={'small'} fullWidth value={state.bannerLinkUrl} onChange={(e) => setState({...state, bannerLinkUrl: e.target.value})} placeholder={'https://'}/></RowBlock>
                        <RowBlock title={"노출 여부"}>
                            <Stack direction={"row"}>
                                <Switch
                                  checked={state ? Boolean(state.onDisplay) : false}
                                  onChange={(e) => {setState({...state, onDisplay: e.target.checked}) }}
                                />
                            </Stack>
                        </RowBlock>
                        <RowBlock title={"노출 기간"}>
                            <Stack direction={"row"} spacing={2}>
                                <DateTimePicker
                                  onChange={(e) => {
                                      setState({...state, startedAt: e?.toISO() ?? DateTime.now().toISO()})
                                  }}
                                  value={DateTime.fromISO(state.startedAt ?? DateTime.now().toISO())}
                                  renderInput={(props) => (<TextField {...props} />)}
                                  label={'게시 시작'}
                                  inputFormat={"yyyy-MM-dd HH:mm"}/>
                                <Typography>~</Typography>
                                <DateTimePicker
                                  onChange={(e) => {
                                      setState({...state, endedAt: e?.toISO() ?? DateTime.now().toISO()})
                                  }}
                                  value={DateTime.fromISO(state.endedAt ?? DateTime.now().toISO())}
                                  renderInput={(props) => (<TextField {...props} />)}
                                  label={'게시 종료'}
                                  inputFormat={"yyyy-MM-dd HH:mm"}/>
                            </Stack>
                        </RowBlock>
                    </Stack>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button variant={'contained'} color={'primary'} onClick={handleSubmit}>{editorType === "EDIT" ? "수정" : "등록"}</Button>
                <Button variant={'contained'} color={'error'} onClick={handleClose}>취소</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SwipeBannerDetailEditorPopup