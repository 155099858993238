import { UserAuthType, UserGradeCode, UserStatusType, UserType } from "./UserType"
import { ProductStatusCode, SaleTypeCode } from "./ProductType"
import { ProductConditionCode } from "./DiagnosisType"
import { DetailModelType } from "./DetailModelType"
import { EventType } from "./EventType"
import { BuyOrderType } from "./BuyOrderType"

export enum CouponIssueTargetTypeCode {
  ALL = "ALL",
  DOMESTIC_FOREIGNER = "DOMESTIC_FOREIGNER",
  OVERSEA_FOREIGNER = "OVERSEA_FOREIGNER",
  DOMESTIC_KOREAN = "DOMESTIC_KOREAN",
}
export const CouponIssueTargetTypeTitleMap: Record<CouponIssueTargetTypeCode, string> = {
  [CouponIssueTargetTypeCode.ALL]: "전체",
  [CouponIssueTargetTypeCode.DOMESTIC_FOREIGNER]: "국내거주 외국인",
  [CouponIssueTargetTypeCode.OVERSEA_FOREIGNER]: "해외거주 외국인",
  [CouponIssueTargetTypeCode.DOMESTIC_KOREAN]: "국내거주 한국인",
};

export enum CouponApplySaleTypeCode {
  ALL = "ALL",
  DIRECT_SALE = "DIRECT_SALE",
  CONSIGNMENT_SALE = "CONSIGNMENT_SALE",
  PRE_DIAGNOSIS_SALE = "PRE_DIAGNOSIS_SALE",
  PURCHASE_GUARANTEE_SALE = "PURCHASE_GUARANTEE_SALE",
}

export const CouponApplySaleTypeTitleMap: Record<CouponApplySaleTypeCode, string> = {
  [CouponApplySaleTypeCode.ALL]: "전체",
  [CouponApplySaleTypeCode.DIRECT_SALE]: "직접판매",
  [CouponApplySaleTypeCode.CONSIGNMENT_SALE]: "위탁판매",
  [CouponApplySaleTypeCode.PRE_DIAGNOSIS_SALE]: "진단전판매",
  [CouponApplySaleTypeCode.PURCHASE_GUARANTEE_SALE]: "매입보증판매",
};

export enum CouponApplyTargetTypeCode {
  ALL = "ALL",
  USER = "USER",
  // CATEGORY = "CATEGORY",
  BRAND = "BRAND",
  MODEL = "MODEL",
  DETAIL_MODEL = "DETAIL_MODEL",
  PRODUCT = "PRODUCT",
}

export const CouponApplyTargetTypeTitleMap: Record<CouponApplyTargetTypeCode, string> = {
  [CouponApplyTargetTypeCode.ALL]: "전체",
  [CouponApplyTargetTypeCode.USER]: "유저",
  // [CouponApplyTargetTypeCode.CATEGORY]: "카테고리",
  [CouponApplyTargetTypeCode.BRAND]: "브랜드",
  [CouponApplyTargetTypeCode.MODEL]: "모델",
  [CouponApplyTargetTypeCode.DETAIL_MODEL]: "상세모델",
  [CouponApplyTargetTypeCode.PRODUCT]: "상품",
};

export enum CouponSaleTypeCode {
  BUYER_COUPON = "BUYER_COUPON",
  SELLER_COUPON = "SELLER_COUPON",
}

export const CouponSaleTypeTitleMap: Record<CouponSaleTypeCode, string> = {
  [CouponSaleTypeCode.BUYER_COUPON]: "구매자 쿠폰",
  [CouponSaleTypeCode.SELLER_COUPON]: "판매자 쿠폰",
};

export enum CouponTargetTypeCode {
  SHIPPING_FEE = "SHIPPING_FEE",
  DIAGNOSIS_FEE = "DIAGNOSIS_FEE",
  PAYMENT_PRICE = "PAYMENT_PRICE",
}
export const CouponTargetTypeTitleMap: Record<CouponTargetTypeCode, string> = {
  [CouponTargetTypeCode.SHIPPING_FEE]: "배송비",
  [CouponTargetTypeCode.DIAGNOSIS_FEE]: "진단비",
  [CouponTargetTypeCode.PAYMENT_PRICE]: "결제금액",
};

export enum CouponIssueTypeCode {
  DOWNLOAD = "DOWNLOAD",
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
}
export const CouponIssueTypeTitleMap: Record<CouponIssueTypeCode, string> = {
  [CouponIssueTypeCode.DOWNLOAD]: "다운로드 방식",
  [CouponIssueTypeCode.AUTOMATIC]: "자동 발급",
  [CouponIssueTypeCode.MANUAL]: "수동 발급",
};


export enum CouponCalculationTypeCode {
  RATE = "RATE",
  FIXED_PRICE = "FIXED_PRICE",
}
export const CouponCalculationTypeTitleMap: Record<CouponCalculationTypeCode, string> = {
  [CouponCalculationTypeCode.RATE]: "정률",
  [CouponCalculationTypeCode.FIXED_PRICE]: "정액",
};

export enum CouponUsageDateType {
  EXPIRE_DATE = "EXPIRE_DATE",
  PERIOD = "PERIOD",
}
export const CouponUsageDateTypeTitleMap: Record<CouponUsageDateType, string> = {
  [CouponUsageDateType.EXPIRE_DATE]: "만료일 지정",
  [CouponUsageDateType.PERIOD]: "사용기간 지정",
};

export enum PreIssueType {
  INDIVISUAL = "INDIVISUAL",
  ENTIRE = "ENTIRE",
}

export const PreIssueTypeTitleMap: Record<PreIssueType, string> = {
  [PreIssueType.INDIVISUAL]: "개별",
  [PreIssueType.ENTIRE]: "전체",
};

export type ModalStateType = {
  user: boolean
  category: boolean
  brand: boolean
  model: boolean
  detailModel: boolean
  product: boolean
  event: boolean
  preissuecoupon: boolean
}

export type CouponApplyTargetInfo =
  | UserInfo
  | BrandInfo
  | CategoryInfo
  | DetailModelInfo
  | ProductInfo
  | ModelInfo


export interface UserInfo {
  targetId: string
  userId: string
  userName: string
  nickname?: string
  phone?: string
  email: string
  status: UserStatusType
  authType: UserAuthType
}

export interface BrandInfo {
  targetId: string
  brandId: string
  brandName?: string
  koName?: string
  onDisplay: boolean
}

export interface CategoryInfo {
  targetId: string
  categoryName: string;
}

export interface DetailModelInfo {
  targetId: string
  detailModelId: number
  thumbnail?: string | null
  refNo?: string
  fullRefNo?: string | null
  title?: string
  titleSecondary?: string
}

export interface ProductInfo {
  targetId: string
  productId: number
  startSaleAt?: string
  status: ProductStatusCode
  onDisplay?: boolean
  thumbnail?: string
  saleType: SaleTypeCode
  productCondition?: ProductConditionCode | null
  title?: string
  titleSecondary?: string
  detailModel: DetailModelType
  price?: number
  globalSale?: boolean
}

export interface ModelInfo {
  targetId: string
  modelId: number
  thumbnail: string | null
  name?: string
}

export interface CouponType {
  id?: number | null,
  issueTargetType: CouponIssueTargetTypeCode,
  issueUserGrade: UserGradeCode,
  applySaleType: CouponApplySaleTypeCode,
  applyTargetType: CouponApplyTargetTypeCode,
  applyTargetIds: CouponApplyTarget[],
  applyTargetInfo?: CouponApplyTargetInfo[] | null;
  couponType: CouponSaleTypeCode,
  couponTargetType: CouponTargetTypeCode,
  issueType: CouponIssueTypeCode,
  calculationType: CouponCalculationTypeCode,
  couponName: string,
  maxIssueCount: number,
  userIssueCount: number,
  issueCount: number,
  issueStartDate?: string | null,
  issueEndDate: string,
  usageDateType: CouponUsageDateType,
  expireDate?: string | null,
  validPeriod?: number | null,
  discountRate?: number | null,
  discountAmount?: number | null,
  maxDiscountAmount: number,
  minApplyPrice: number,
  isMultiCoupon: boolean
  isUse: boolean,
  event?: EventType | null,

  couponPreIssueCount?: number,
  couponPreIssueUseCount?: number,
  couponIssueCount?: number,
  couponIssueUseCount?: number
}

export type CouponApplyTarget = {
  couponId?: number,
  targetId: String
}

export declare interface CouponListSearchParams {
  couponId? : string
  couponName?: string
  isUse? : string | null
  applyTargetType? : CouponApplyTargetTypeCode | null
  couponTargetType? : CouponTargetTypeCode | null
  page?: number
  size?: number
  sort?: string
}

export declare interface PreIssueCouponsParams {
  couponId : number
  preIssueType: PreIssueType
  issueCount: number
}


export declare interface CouponPreIssueListSearchParams {
  couponId? : string
  couponName?: string
  isUse? : string | null
  userId? : number | null
  preIssueSid? : string | null
  buyOrderId? : string | null
  productId? : number | null
  page?: number
  size?: number
  sort?: string
}

export declare interface CouponIssueListSearchParams {
  couponId? : string
  couponName?: string
  isUse? : string | null
  userId? : number | null
  preIssueSid? : string | null
  buyOrderId? : string | null
  productId? : number | null
  page?: number
  size?: number
  sort?: string
}

export declare interface CouponPreIssueType {
  sid : string
  preIssueType: PreIssueType
  isUse: boolean
}

export declare interface CouponIssueType {
  id : number
  expireDate? : string | null
  createdAt? : string | null
  isUse: boolean
}

export interface CouponPreIssueItem {
  sid?: string | null,
  coupon: CouponType,
  preIssueType: CouponPreIssueType
  isUse : boolean,
  createdAt: string,
  couponIssue: CouponIssueType
  buyOrder?: BuyOrderType
}

export interface CouponIssueCouponItem {
  id?: number | null,
  coupon: CouponType,
  user: UserType,
  couponPreIssueType: CouponPreIssueType
  buyOrder?: BuyOrderType
  isUse : boolean,
  couponAmount: number,
  expireDate: string,
  createdAt: string,
}
