import { DiscountReasonTypeCode } from "./BuyOrderType"
import { DateTime } from "luxon"
import { SaleTypeCode } from "./ProductType"

export const enum FeePolicyTypeType {
  구매_수수료 = "구매 수수료",
  구매_진단_수수료 = "구매 진단 수수료",
  구매_정밀_진단_수수료 = "구매 정밀 진단 수수료",
  구매_배송_수수료 = "구매 배송 수수료",
  반품_수수료 = "반품 수수료",
  구매_업그레이드_수수료 = "구매 업그레이드 수수료",
  판매_수수료 = "판매 수수료",
  판매_배송_수수료 = "판매 배송 수수료",
  판매_진단_수수료 = "판매 진단 수수료",
  판매_업그레이드_수수료 = "판매 업그레이드 수수료",
  판매_결제_수수료 = "판매 결제 수수료",
  판매_취소_페널티 = "판매 취소 페널티",
  보관판매_취소_페널티 = "보관판매 취소 페널티",
  판매_요청_취소_페널티 = "판매 요청 취소 페널티",
  판매_가품_페널티 = "판매 가품 페널티",
  판매_커스텀_페널티 = "판매 커스텀 페널티",
  판매_상태_불일치_페널티 = "판매 상태 불일치 페널티",
  판매_다른_상품_전달_페널티 = "판매 다른 상품 전달 페널티",
  외국인_구매_수수료 = "외국인 구매 수수료",
  직접_입력 = "직접 입력",
  구매_환불_수수료 = "구매_환불_수수료",
}

export const enum FeePolicyTargetType {
  상품 = "상품",
  유저 = "유저",
  상세모델 = "상세모델",
  모델 = "모델",
  브랜드 = "브랜드",
  카테고리 = "카테고리",
}

export const enum FeePolicyCalculationType {
  정률 = "정률",
  정가 = "정가",
}

export type FeePolicyDataType = {
  id: number | null
  name:
    | "거래 수수료"
    | "판매 진단 수수료"
    | "배송비"
    | "서비스 수수료"
    | "페널티 금액"
    | "일반 감정진단"
    | "반품 배송비"
    | "정밀 감정진단"
    | "주문 관리 수수료"
  type: FeePolicyTypeType | null
  saleType: SaleTypeCode | null
  targetType: FeePolicyTargetType | null
  targetId: string | null
  priority: number | null
  calculationType: FeePolicyCalculationType
  rate: number | null
  amount: number | null
  minAmount: number | null
  maxAmount: number | null
  isEvent: boolean | null
  onDisplay: boolean | null
  discountReasonMemo: string | null
  discountReasonType: DiscountReasonTypeCode | null
  discountRate: number | null
  discountMaxAmount: number | null
  discountMinAmount: number | null
  onStartCreatedAt?: boolean
  startDate: string | null
  endDate: string | null
  createdAt: string | null
  updatedAt: string | null
  feePolicyStatus: string
}

export type FeePolicySearchParams = {
  page?: number
  size?: number
  name?: string
  type?: string
  saleType?: string
  targetType?: string
  search?: string
}

export type FeePolicyDeleteParams = {
  ids?: number[]
}

export type FeePolicyRequestParams = {
  id: number | null
  name:
    | "거래 수수료"
    | "판매 진단 수수료"
    | "배송비"
    | "서비스 수수료"
    | "페널티 금액"
    | "일반 감정진단"
    | "반품 배송비"
    | "정밀 감정진단"
    | "주문 관리 수수료"
  type: FeePolicyTypeType | null
  saleType: SaleTypeCode | null
  targetType: FeePolicyTargetType | null
  targetId: string | null
  priority: number | null
  calculationType: FeePolicyCalculationType
  rate: number | null
  amount: number | null
  minAmount: number | null
  maxAmount: number | null
  isEvent: boolean | null
  onDisplay: boolean | null
  discountReasonType: DiscountReasonTypeCode | null
  discountReasonMemo: string | null
  discountRate: number | null
  discountMaxAmount: number | null
  discountMinAmount: number | null
  onStartCreatedAt?: boolean
  startDate: string | null
  endDate: string | null
}

export type BuyingFeePolicyType = {
  name: string
  feePolicyType: "구매 수수료" | "외국인 구매 수수료"
  calculationType: "정률" | "정가"
  rate: number
  discountReasonType: DiscountReasonTypeCode
  calculationAmount: number
  discountAmount: number
  amount: number
  label: string
}
