import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { Check, Search } from "@mui/icons-material"
import { getDetailModels } from "../../apis/detailModelAPI"
import { DetailModelType } from "types/DetailModelType"
import { PageType } from "types/PageType"

type SearchProductModelProps = {
  open: boolean
  dialogTitle?: string
  handleClose: Function
  zIndex?: number
}

const SearchDetailModelPopup: React.FC<SearchProductModelProps> = ({ open, handleClose, zIndex }) => {
  const [detailModels, setDetailModels] = useState<PageType<DetailModelType>>({ content: [] })
  const [searchKey, setSearchKey] = useState<string>("fullRefNo")
  const [searchValue, setSearchValue] = useState<string>("")

  const [page, setPage] = useState<number>(0)
  const [size, setSize] = useState<number>(500)

  const fetchDetailModels = (params?: any | null) => getDetailModels(params).then((page) => setDetailModels(page))

  useEffect(() => {
    if (open) {
      setDetailModels({ content: [] })
      setSearchKey("fullRefNo")
      setSearchValue("")
      setPage(0)
      setSize(500)
    }
  }, [open])

  const parseParams = useCallback(() => {
    if (!searchKey) return {}

    return { [searchKey]: searchValue }
  }, [searchKey, searchValue])

  const handleSearchButton = () => {
    fetchDetailModels({ ...parseParams(), page, size })
  }

  return (
    <Dialog open={open} maxWidth={"md"} fullWidth style={{ zIndex }}>
      <DialogTitle>{"상세 모델 검색"}</DialogTitle>
      <DialogContent>
        <Grid container marginTop={1} spacing={1} direction={"row"} justifyContent={"end"}>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>검색항목</InputLabel>
              <Select
                size={"small"}
                required
                label="검색항목"
                defaultValue={"fullRefNo"}
                onChange={(e) => {
                  setSearchKey(e.target.value)
                }}
                MenuProps={{
                  style: { zIndex },
                }}
              >
                <MenuItem value={"fullRefNo"}>Full Ref No.</MenuItem>
                <MenuItem value={"title"}>이름</MenuItem>
                <MenuItem value={"id"}>ID</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              size={"small"}
              required
              value={searchValue}
              defaultValue={""}
              onChange={(e) => {
                setSearchValue(e.target.value)
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") handleSearchButton()
              }}
              autoFocus
              fullWidth
            />
          </Grid>
          <Grid item xs={0.8}>
            <IconButton>
              <Search onClick={handleSearchButton} />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography width={"100%"} textAlign={"right"} variant={"caption"} align={"right"}>
              검색 결과 {detailModels.totalElements || 0} 개
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ImageList sx={{ width: "100%", height: "500px" }} cols={3} variant="quilted">
              {detailModels?.content?.map((detailModel) => {
                return (
                  <ImageListItem key={detailModel.id}>
                    {detailModel.thumbnail ? (
                      <img
                        style={{
                          height: "400px",
                        }}
                        src={detailModel.thumbnail as string}
                        loading={"lazy"}
                        alt={`detail-model-${detailModel.id}`}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "400px",
                        }}
                      >
                        등록된 이미지 없음
                      </div>
                    )}
                    <ImageListItemBar
                      title={detailModel.title}
                      position="bottom"
                      subtitle={
                        <span>
                          <Typography sx={{ fontSize: "14px" }}>{detailModel?.titleSecondary || "-"}</Typography>
                          <Typography sx={{ fontSize: "14px" }}>{detailModel?.fullRefNo || "-"}</Typography>
                        </span>
                      }
                      actionIcon={
                        <IconButton onClick={() => {
                          if (!window.confirm("적용 하시겠습니까?")) return
                          handleClose(detailModel)
                        }}>
                          <Check />
                        </IconButton>
                      }
                      actionPosition={"left"}
                    />
                  </ImageListItem>
                )
              })}
            </ImageList>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} onClick={() => handleClose()}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SearchDetailModelPopup
