import PopupPageFooter from "components/Popup/PopupPage/PopupPageFooter"
import Button from "components/Button"
import styled from "@emotion/styled"
import PopupPageTitle from "components/Popup/PopupPage/PopupPageTitle"
import SearchInput from "components/Search/SearchBar/SearchInput"
import { toDateStr } from "utils/datetimeUtil"
import { DateTime } from "luxon"
import { Dialog, FormControl, MenuItem, Select, Switch } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import {
  BrandInfo,
  CouponApplySaleTypeCode,
  CouponApplySaleTypeTitleMap,
  CouponApplyTarget,
  CouponApplyTargetInfo,
  CouponApplyTargetTypeCode,
  CouponApplyTargetTypeTitleMap,
  CouponCalculationTypeCode,
  CouponCalculationTypeTitleMap,
  CouponIssueTargetTypeCode,
  CouponIssueTargetTypeTitleMap,
  CouponIssueTypeCode,
  CouponIssueTypeTitleMap,
  CouponSaleTypeCode,
  CouponSaleTypeTitleMap,
  CouponTargetTypeCode,
  CouponTargetTypeTitleMap,
  CouponType,
  CouponUsageDateType,
  CouponUsageDateTypeTitleMap,
  DetailModelInfo,
  ModalStateType,
  ModelInfo,
  ProductInfo,
  UserInfo,
} from "../../../../types/CouponType"
import { UserGradeCode, UserGradeCodeTitleMap, UserType } from "../../../../types/UserType"
import UserSelectPopup from "../../../../components/Popup/UserSelectPopup"
import ProductSelectPopup from "../../../../components/Popup/ProductSelectPopup"
import { ProductType } from "../../../../types/ProductType"
import DetailModelSelectPopup from "../../../../components/Popup/DetailModelSelectPopup"
import { BrandType } from "../../../../types/BrandType"
import { getCoupon, setCoupon } from "../../../../apis/couponAPI"
import ApplyTargetInfoTable from "./ApplyTargetInfoTable"
import { toast } from "react-toastify"
import BrandSelectPopup from "../../../../components/Popup/BrandSelectPopup"
import ModelSelectPopup from "../../../ShopManager/ShopContentTab/BrandModelCurationTab/ModelSelectPopup"
import { ModelType } from "../../../../types/ModelType"
import SearchEventsPopup from "../../../../components/Popup/SearchEventPopup"
import { EventType } from "../../../../types/EventType"
import PreIssueCouponPopupModal from "../PreIssueCouponPopup"

const initState: CouponType = {
  id: null,
  issueTargetType: CouponIssueTargetTypeCode.ALL,
  issueUserGrade: UserGradeCode.ALL,
  applySaleType: CouponApplySaleTypeCode.ALL,
  applyTargetType: CouponApplyTargetTypeCode.ALL,
  applyTargetIds: [],
  applyTargetInfo: [],
  couponType: CouponSaleTypeCode.BUYER_COUPON,
  couponTargetType: CouponTargetTypeCode.SHIPPING_FEE,
  issueType: CouponIssueTypeCode.DOWNLOAD,
  calculationType: CouponCalculationTypeCode.RATE,
  couponName: "",
  maxIssueCount: 0,
  userIssueCount: 0,
  issueCount: 0,
  issueStartDate: DateTime.now().set({ hour: 0, minute: 0, second: 0 }).toFormat("yyyy-MM-dd'T'HH:mmZZ"),
  issueEndDate: DateTime.now().plus({'days': 30}).set({ hour: 23, minute: 59, second: 59 }).toFormat("yyyy-MM-dd'T'HH:mmZZ"),
  usageDateType: CouponUsageDateType.EXPIRE_DATE,
  expireDate: DateTime.now().endOf('day').toISO({ suppressSeconds: true, suppressMilliseconds: true }),
  validPeriod: 0,
  discountRate: 0,
  discountAmount: 0,
  maxDiscountAmount: 0,
  minApplyPrice: 0,
  isMultiCoupon: false,
  isUse: true,
  event: null
}

type Props = {
  selectedCoupon?: CouponType
  isOpen: boolean
  closePopup: () => void
}
type CouponCodeEditorType = "CREATE" | "EDIT"

const EditCouponPopup = ({ selectedCoupon, isOpen, closePopup }: Props) => {
  const [state, setState] = useState(initState)
  const [modalState, setModalState] = useState<ModalStateType>({
    user: false,
    category: false,
    brand: false,
    model: false,
    detailModel: false,
    product: false,
    event: false,
    preissuecoupon: false
  })

  useEffect(() => {
    if (isOpen) {
      if (selectedCoupon?.id != null) {
        getCoupon(selectedCoupon.id!!)
          .then((coupon) => {
            setState(coupon)
          })
      }
      else {
        setState(initState)
      }
    }
  }, [isOpen])

  const editorType = useMemo((): CouponCodeEditorType => {
    return !selectedCoupon ? "CREATE" : "EDIT"
  }, [selectedCoupon])

  const onOpenShowTargetSelectPopup = () => {
    const targetType = state.applyTargetType
    let stateUpdate = {}
    let modalStateUpdate = {}
    switch (targetType) {
      case CouponApplyTargetTypeCode.PRODUCT:
        stateUpdate = { targetType: targetType, targetId: null, priority: 100 }
        modalStateUpdate = { product: true }
        break
      case CouponApplyTargetTypeCode.USER:
        stateUpdate = { targetType: targetType, targetId: null, priority: 200 }
        modalStateUpdate = { user: true }
        break
      case CouponApplyTargetTypeCode.MODEL:
        stateUpdate = { targetType: targetType, targetId: null, priority: 300 }
        modalStateUpdate = { model: true }
        break
      case CouponApplyTargetTypeCode.DETAIL_MODEL:
        stateUpdate = { targetType: targetType, targetId: null, priority: 400 }
        modalStateUpdate = { detailModel: true }
        break
      case CouponApplyTargetTypeCode.BRAND:
        stateUpdate = { targetType: targetType, targetId: null, priority: 500 }
        modalStateUpdate = { brand: true }
        break
      // case CouponApplyTargetTypeCode.CATEGORY:
      //   stateUpdate = { targetType: targetType, targetId: null, priority: 600 }
      //   modalStateUpdate = { brand: true }
      //   break
    }

    setModalState((prevState) => ({
      ...prevState,
      ...modalStateUpdate,
    }))
  }

  const handleOnChangeState = (targets: CouponApplyTargetInfo[]) => {
    setState((prevState) => ({
      ...prevState,
      applyTargetInfo: targets,
    }))
  }

  const onPreIssueCoupon = () => {
    setModalState((prevState) => ({
      ...prevState,
      preissuecoupon: true
    }))
  }
  const onSubmit = () => {
    if ( !state.couponName) {
      toast.error("쿠폰명을 입력해주세요")
      return
    }
    if ( !state.issueStartDate) {
      toast.error("발급 시작일자를 입력해주세요")
      return
    }
    if ( !state.issueEndDate) {
      toast.error("발급 종료일자를 입력해주세요")
      return
    }
    // if ( !state.expireDate) {
    //   toast.error("만료일자를 입력해주세요")
    //   return
    // }
    if (  state.issueStartDate > state.issueEndDate) {
      toast.error("발급기간을 확인해주세요")
      return
    }

    const updatedApplyTargetIds: CouponApplyTarget[] = (state.applyTargetInfo || []).map(item => ({
      couponId: state.id!,
      targetId: item.targetId
    }))

    const saveState: CouponType = {
      ...state,
      applyTargetIds: updatedApplyTargetIds
    }

    setCoupon(saveState)
      .then(() => {
        toast.success(`쿠폰 ${editorType === "CREATE" ? "생성" : "수정"}이 완료되었습니다.`)
        closePopup()
      })
      .catch((error) => {
        toast.error(`저장 실패 (${error.message || error})`)
      })
  }

  return (
    <Dialog open={isOpen} maxWidth={"lg"} fullWidth>
      <ContentWrapper>
        <PreIssueCouponPopupModal
          couponId={state.id!!}
          open={modalState.preissuecoupon}
          closeModal={(result?: EventType) => {
            setModalState((prevState) => ({
              ...prevState,
              preissuecoupon: false
            }))
          }}
        />
        <SearchEventsPopup
          open={modalState.event}
          handleClose={(result?: EventType) => {
            if (result) {
              setState({...state, event: { id: result.id, title: result.title, bannerImageUrl: result.bannerImageUrl} as EventType })
              console.log(state)
            }
            setModalState((prevState) => ({
              ...prevState,
              event: false
            }))
          }}
        />
        <UserSelectPopup
          open={modalState.user}
          filterStatus={["활성"]}
          isMultiSelectable={true}
          setUsers={(results?: UserType[]) => {
            if (results) {
              const newTargets: UserInfo[]  = results.map((user) => ({
                targetId: user.id.toString(),
                userId: user.id.toString(),
                userName: user.name!!,
                nickname: user.nickname,
                phone: user.phone,
                email: user.email,
                status: user.status,
                authType: user.authType
              }))

              const existingTargets = state.applyTargetInfo || []
              const duplicates = newTargets.filter((newTarget) =>
                existingTargets.some((existing) => existing.targetId === newTarget.targetId)
              )
              if (duplicates.length > 0) {
                alert('이미 추가된 사용자가 있습니다: ' + duplicates.map((d) => d.userName).join(', '))
              }

              const uniqueTargets = newTargets.filter(
                (newTarget) => !existingTargets.some((existing) => existing.targetId === newTarget.targetId)
              )

              setState((prevState) => ({
                ...prevState,
                applyTargetInfo: [...(prevState.applyTargetInfo || []), ...uniqueTargets],
              }))
            }
          }}
          close={() => {
            setModalState((prevState) => ({
              ...prevState,
              user: false,
            }))
          }}
        />
        <ProductSelectPopup
          open={modalState.product}
          isMultiSelectable={true}
          isSearchable={true}
          setProducts={(results?: ProductType[]) => {
            if (results) {
              const newTargets: ProductInfo[]  = results.map((product) => ({
                targetId: product.id.toString()!!,
                productId: product.id!!,
                startSaleAt: product.startSaleAt,
                status: product.status,
                onDisplay: product.onDisplay,
                thumbnail: product.thumbnail,
                saleType: product.saleType,
                productCondition: product.productCondition,
                title: product.title,
                titleSecondary: product.titleSecondary,
                detailModel: product.detailModel,
                price: product.price,
                globalSale: product.globalSale
              }))

              const existingTargets = state.applyTargetInfo || []
              const duplicates = newTargets.filter((newTarget) =>
                existingTargets.some((existing) => existing.targetId === newTarget.targetId)
              )
              if (duplicates.length > 0) {
                alert('이미 추가된 상품이 있습니다: ' + duplicates.map((d) => d.productId).join(', '))
              }

              const uniqueTargets = newTargets.filter(
                (newTarget) => !existingTargets.some((existing) => existing.targetId === newTarget.targetId)
              )

              setState((prevState) => ({
                ...prevState,
                applyTargetInfo: [...(prevState.applyTargetInfo || []), ...uniqueTargets],
              }))
            }
          }}
          close={() => {
            setModalState((prevState) => ({
              ...prevState,
              product: false,
            }))
          }}
        />

        <DetailModelSelectPopup
          open={modalState.detailModel}
          handleClose={(results) => {
            if (results) {
              const newTargets: DetailModelInfo[]  = results.map((detailModel) => ({
                targetId: detailModel.id!!.toString(),
                detailModelId: detailModel.id!!,
                thumbnail: detailModel.thumbnail,
                refNo: detailModel.refNo,
                fullRefNo: detailModel.fullRefNo,
                title: detailModel.title,
                titleSecondary: detailModel.titleSecondary
              }))

              const existingTargets = state.applyTargetInfo || []
              const duplicates = newTargets.filter((newTarget) =>
                existingTargets.some((existing) => existing.targetId === newTarget.targetId)
              )
              if (duplicates.length > 0) {
                alert('이미 추가된 상세모델이 있습니다: ' + duplicates.map((d) => d.fullRefNo).join(', '))
              }

              const uniqueTargets = newTargets.filter(
                (newTarget) => !existingTargets.some((existing) => existing.targetId === newTarget.targetId)
              )

              setState((prevState) => ({
                ...prevState,
                applyTargetInfo: [...(prevState.applyTargetInfo || []), ...uniqueTargets],
              }))
              setModalState((prevState) => ({
                ...prevState,
                detailModel: false,
              }))
            }
          }}
        />

        <BrandSelectPopup
          open={modalState.brand}
          isMultiSelectable={true}
          setBrands={(results?: BrandType[]) => {
            if (results) {
              const newTargets: BrandInfo[]  = results.map((brand) => ({
                targetId: brand.sid!!,
                brandId: brand.sid!!,
                brandName: brand.name,
                koName: brand.koName,
                onDisplay: brand.onDisplay
              }))

              const existingTargets = state.applyTargetInfo || []
              const duplicates = newTargets.filter((newTarget) =>
                existingTargets.some((existing) => existing.targetId === newTarget.targetId)
              )
              if (duplicates.length > 0) {
                alert('이미 추가된 브랜드가 있습니다: ' + duplicates.map((d) => d.brandName).join(', '))
              }

              const uniqueTargets = newTargets.filter(
                (newTarget) => !existingTargets.some((existing) => existing.targetId === newTarget.targetId)
              )

              setState((prevState) => ({
                ...prevState,
                applyTargetInfo: [...(prevState.applyTargetInfo || []), ...uniqueTargets],
              }))
            }
          }}
          close={() => {
            setModalState((prevState) => ({
              ...prevState,
              brand: false,
            }))
          }}
        />

        <ModelSelectPopup
          open={modalState.model}
          handleClose={(results?: ModelType[]) => {
            if (results) {
              const newTargets: ModelInfo[]  = results.map((model) => ({
                targetId: model.id!!.toString(),
                modelId: model.id!!,
                thumbnail: model.thumbnail,
                name: model.name
              }))

              const existingTargets = state.applyTargetInfo || []
              const duplicates = newTargets.filter((newTarget) =>
                existingTargets.some((existing) => existing.targetId === newTarget.targetId)
              )
              if (duplicates.length > 0) {
                alert('이미 추가된 모델이 있습니다: ' + duplicates.map((d) => d.modelId).join(', '))
              }

              const uniqueTargets = newTargets.filter(
                (newTarget) => !existingTargets.some((existing) => existing.targetId === newTarget.targetId)
              )

              setState((prevState) => ({
                ...prevState,
                applyTargetInfo: [...(prevState.applyTargetInfo || []), ...uniqueTargets],
              }))
            }
            setModalState((prevState) => ({
              ...prevState,
              model: false,
            }))
          }}
        />

        <PopupPageTitle>쿠폰 {editorType === "CREATE" ? "생성" : "수정"}</PopupPageTitle>
        <PopupContainer>
          <DataRowWrapper columnCount={1}>
            <DataWrapper>
              <Title>쿠폰명</Title>
              <SearchInput
                placeholder={"쿠폰명을 입력하세요."}
                value={state.couponName}
                onChange={(e) => setState({...state, couponName: e.target.value})}
              />
            </DataWrapper>
          </DataRowWrapper>
          <DataRowWrapper columnCount={3}>
            <DataWrapper>
              <Title>발급대상</Title>
              <FormControl fullWidth={true}>
                <Select
                  size={"small"}
                  value={state.issueTargetType}
                  required
                  onChange={(e) => {
                    setState({...state, issueTargetType: e.target.value as CouponIssueTargetTypeCode})
                  }}
                  MenuProps={{
                    style: { zIndex: 10001 },
                  }}
                >
                  {Object.entries(CouponIssueTargetTypeTitleMap).map(([code, title]) => (
                    <MenuItem value={code} key={code}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DataWrapper>
            <DataWrapper>
              <Title>발급판매방식</Title>
              <FormControl fullWidth={true}>
                <Select
                  size={"small"}
                  value={state.applySaleType}
                  required
                  onChange={(e) => {
                    setState({...state, applySaleType: e.target.value as CouponApplySaleTypeCode})
                  }}
                  MenuProps={{
                    style: { zIndex: 10001 },
                  }}
                >
                  {Object.entries(CouponApplySaleTypeTitleMap).map(([code, title]) => (
                    <MenuItem value={code} key={code}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DataWrapper>
            <DataWrapper>
              <Title>발급회원등급</Title>
              <FormControl fullWidth={true}>
                <Select
                  size={"small"}
                  value={state.issueUserGrade}
                  required
                  onChange={(e) => {
                    setState({...state, issueUserGrade: e.target.value as UserGradeCode})
                  }}
                  MenuProps={{
                    style: { zIndex: 10001 },
                  }}
                >
                  {Object.entries(UserGradeCodeTitleMap).map(([code, title]) => (
                    <MenuItem value={code} key={code}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DataWrapper>
          </DataRowWrapper>
          <DataRowWrapper columnCount={3}>
            <DataWrapper>
              <Title>쿠폰타입</Title>
              <FormControl fullWidth={true}>
                <Select
                  size={"small"}
                  value={state.couponType}
                  required
                  onChange={(e) => {
                    setState({...state, couponType: e.target.value as CouponSaleTypeCode})
                  }}
                  MenuProps={{
                    style: { zIndex: 10001 },
                  }}
                >
                  {Object.entries(CouponSaleTypeTitleMap).map(([code, title]) => (
                    <MenuItem value={code} key={code}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DataWrapper>
            <DataWrapper>
              <Title>쿠폰대상</Title>
              <FormControl fullWidth={true}>
                <Select
                  size={"small"}
                  value={state.couponTargetType}
                  required
                  onChange={(e) => {
                    setState({...state, couponTargetType: e.target.value as CouponTargetTypeCode})
                  }}
                  MenuProps={{
                    style: { zIndex: 10001 },
                  }}
                >
                  {Object.entries(CouponTargetTypeTitleMap).map(([code, title]) => (
                    <MenuItem value={code} key={code}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DataWrapper>
            <DataWrapper>
              <Title>발급타입</Title>
              <FormControl fullWidth={true}>
                <Select
                  size={"small"}
                  value={state.issueType}
                  required
                  onChange={(e) => {
                    setState({...state, issueType: e.target.value as CouponIssueTypeCode})
                  }}
                  MenuProps={{
                    style: { zIndex: 10001 },
                  }}
                >
                  {Object.entries(CouponIssueTypeTitleMap).map(([code, title]) => (
                    <MenuItem value={code} key={code}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DataWrapper>
          </DataRowWrapper>
          <DataRowWrapper columnCount={3}>
            <DataWrapper>
              <Title>인당쿠폰발급건수</Title>
              <FormControl fullWidth={true}>
                <SearchInput
                  value={state.userIssueCount}
                  onChange={(e) => {
                    const inputValue = Number(e.target.value)
                    if (isNaN(inputValue)) {
                      return
                    }
                    setState({...state, userIssueCount: inputValue})
                  }}
                />
              </FormControl>
            </DataWrapper>
            <DataWrapper>
              <Title>최대발급건수</Title>
              <FormControl fullWidth={true}>
                <SearchInput
                  value={state.maxIssueCount}
                  onChange={(e) => {
                    const inputValue = Number(e.target.value)
                    if (isNaN(inputValue)) {
                      return
                    }
                    setState({...state, maxIssueCount: inputValue})
                  }}
                />
              </FormControl>
            </DataWrapper>
            <DataWrapper>
              <Title>실제발급건수</Title>
              <FormControl fullWidth={true}>
                <SearchInput
                  value={state.issueCount}
                  disabled={true}
                  onChange={(e) => {
                    const inputValue = Number(e.target.value)
                    if (isNaN(inputValue)) {
                      return
                    }
                    setState({...state, maxIssueCount: inputValue})
                  }}
                />
              </FormControl>
            </DataWrapper>
          </DataRowWrapper>
          <DataRowWrapper columnCount={3}>
            <DataWrapper>
              <Title>계산타입</Title>
              <FormControl fullWidth={true}>
                <Select
                  size={"small"}
                  value={state.calculationType}
                  required
                  onChange={(e) => {
                    setState({
                      ...state,
                      discountAmount: e.target.value === CouponCalculationTypeCode.RATE ? null : state.discountAmount,
                      discountRate: e.target.value !== CouponCalculationTypeCode.RATE ? null : state.discountRate,
                      calculationType: e.target.value as CouponCalculationTypeCode,
                    });
                  }}
                  MenuProps={{
                    style: { zIndex: 10001 },
                  }}
                >
                  {Object.entries(CouponCalculationTypeTitleMap).map(([code, title]) => (
                    <MenuItem value={code} key={code}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DataWrapper>
            <DataWrapper>
              <Title>할인율</Title>
              <FormControl fullWidth={true}>
                <SearchInput
                  value={state.discountRate ?? ""}
                  disabled={state.calculationType == CouponCalculationTypeCode.FIXED_PRICE }
                  onChange={(e) => {
                    const inputValue = Number(e.target.value)
                    if (isNaN(inputValue)) {
                      return
                    }
                    setState({...state, discountRate: inputValue})
                  }}
                />
              </FormControl>
            </DataWrapper>
            <DataWrapper>
              <Title>할인금액</Title>
              <FormControl fullWidth={true}>
                <SearchInput
                  value={state.discountAmount ?? ""}
                  disabled={state.calculationType == CouponCalculationTypeCode.RATE }
                  onChange={(e) => {
                    const inputValue = Number(e.target.value)
                    if (isNaN(inputValue)) {
                      return
                    }
                    setState({...state, discountAmount: inputValue})
                  }}
                />
              </FormControl>
            </DataWrapper>
          </DataRowWrapper>
          <DataRowWrapper columnCount={3}>
            <DataWrapper>
              <Title>최소적용금액</Title>
              <FormControl fullWidth={true}>
                <SearchInput
                  value={state.minApplyPrice}
                  onChange={(e) => {
                    const inputValue = Number(e.target.value)
                    if (isNaN(inputValue)) {
                      return
                    }
                    setState({...state, minApplyPrice: inputValue})
                  }}
                />
              </FormControl>
            </DataWrapper>
            <DataWrapper>
              <Title>최대할인금액</Title>
              <FormControl fullWidth={true}>
                <SearchInput
                  value={state.maxDiscountAmount}
                  onChange={(e) => {
                    const inputValue = Number(e.target.value)
                    if (isNaN(inputValue)) {
                      return
                    }
                    setState({...state, maxDiscountAmount: inputValue})
                  }}
                />
              </FormControl>
            </DataWrapper>
          </DataRowWrapper>
          <DataRowWrapper columnCount={3}>
            <DataWrapper>
              <Title>사용기간타입</Title>
              <FormControl fullWidth={true}>
                <Select
                  size={"small"}
                  value={state.usageDateType}
                  required
                  onChange={(e) => {
                    setState({...state, usageDateType: e.target.value as CouponUsageDateType})

                    setState({
                      ...state,
                      expireDate: e.target.value === CouponUsageDateType.PERIOD ? null : DateTime.now().endOf('day').toISO({ suppressSeconds: true, suppressMilliseconds: true }),
                      validPeriod: e.target.value !== CouponUsageDateType.PERIOD ? null : state.validPeriod,
                      usageDateType: e.target.value as CouponUsageDateType,
                    });
                  }}
                  MenuProps={{
                    style: { zIndex: 10001 },
                  }}
                >
                  {Object.entries(CouponUsageDateTypeTitleMap).map(([code, title]) => (
                    <MenuItem value={code} key={code}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DataWrapper>
            <DataWrapper>
              <Title>만료일자</Title>
              <FormControl>
                <DatePicker
                  disabled={state.usageDateType == CouponUsageDateType.PERIOD }
                  type="datetime-local"
                  value={
                    state.expireDate
                      ? DateTime.fromISO(state.expireDate).toLocal().toISO().split(".")[0]
                      : ""
                  }
                  min={toDateStr(DateTime.now().toISO(), true)}
                  onChange={(e) => {
                    setState({...state, expireDate: DateTime.fromISO(e.target.value).toISO()})
                  }}
                />
              </FormControl>
            </DataWrapper>
            <DataWrapper>
              <Title>유효기간</Title>
              <FormControl fullWidth={true}>
                <SearchInput
                  value={state.validPeriod ?? ""}
                  disabled={state.usageDateType == CouponUsageDateType.EXPIRE_DATE }
                  onChange={(e) => {
                    const inputValue = Number(e.target.value)
                    if (isNaN(inputValue)) {
                      return
                    }
                    setState({...state, validPeriod: inputValue})
                  }}
                />
              </FormControl>
            </DataWrapper>
          </DataRowWrapper>
          <DataRowWrapper columnCount={1}>
            <DataWrapper>
              <Title>발급기간</Title>
              <FormControl>
                <DatePicker
                  type="datetime-local"
                  value={
                    state.issueStartDate
                      ? DateTime.fromISO(state.issueStartDate).toLocal().toISO().split(".")[0]
                      : ""
                  }
                  min={toDateStr(DateTime.now().toISO(), true)}
                  onChange={(e) => {
                    setState({...state, issueStartDate: DateTime.fromISO(e.target.value).toISO()})
                  }}
                />
              </FormControl>
              ~
              <FormControl>
                <DatePicker
                  type="datetime-local"
                  value={
                    state.issueEndDate
                      ? DateTime.fromISO(state.issueEndDate).toLocal().toISO().split(".")[0]
                      : ""
                  }
                  min={toDateStr(DateTime.now().toISO(), true)}
                  onChange={(e) => {
                    setState({...state, issueEndDate: DateTime.fromISO(e.target.value).toISO()})
                  }}
                />
              </FormControl>
            </DataWrapper>
          </DataRowWrapper>
          <DataRowWrapper columnCount={3}>
            <DataWrapper>
              <Title>중복적용여부</Title>
              <FormControl fullWidth style={{ display: "flex", alignItems: "start", justifyContent: "center" }}>
                <Switch
                  checked={state.isMultiCoupon ?? false}
                  onChange={(_event, checked) => {
                    setState({...state, isMultiCoupon: checked})
                  }}
                />
              </FormControl>
            </DataWrapper>
            <DataWrapper>
              <Title>사용여부</Title>
              <FormControl fullWidth style={{ display: "flex", alignItems: "start", justifyContent: "center" }}>
                <Switch
                  checked={state.isUse ?? false}
                  onChange={(_event, checked) => {
                    setState({...state, isUse: checked})
                  }}
                />
              </FormControl>
            </DataWrapper>
          </DataRowWrapper>
          <DataRowWrapper columnCount={1}>
            <DataWrapper>
              <Title>이벤트</Title>
              <ButtonWrapper style={{   display: "flex", gap:"8px", alignItems:"flex-start" }}>
                { state.event?.id != null &&
                  <>
                    <img src={state.event.bannerImageUrl as string} style={{ width: "300px", height: "80px" }} loading={"lazy"} />
                    (
                    {state.event?.id}) {state.event?.title}
                    <Button
                      buttonSize={"S"}
                      onClick={() => {
                        setState({ ...state, event: null })
                      }}>삭제</Button>
                  </>
                }
                <Button
                  buttonSize={"S"}
                  onClick={() => {
                    setModalState((prevState) => ({
                      ...prevState,
                      event: true
                    }))
                  }}>선택</Button>
              </ButtonWrapper>
            </DataWrapper>
          </DataRowWrapper>
          <DataRowWrapper columnCount={1}>
            <DataWrapper>
              <Title>적용대상</Title>
              <FormControl fullWidth={true}>
                <Select
                  size={"small"}
                  value={state.applyTargetType}
                  required
                  onChange={(e ) => {
                    setState({...state, applyTargetType: e.target.value as CouponApplyTargetTypeCode, applyTargetInfo: []})
                  }}
                  MenuProps={{
                    style: { zIndex: 10001 },
                  }}
                >
                  {Object.entries(CouponApplyTargetTypeTitleMap).map(([code, title]) => (
                    <MenuItem value={code} key={code}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DataWrapper>
            <DataWrapper>
            </DataWrapper>
            <DataWrapper>
            </DataWrapper>
          </DataRowWrapper>
          {state.applyTargetType != CouponApplyTargetTypeCode.ALL &&
            <DataRowWrapper columnCount={1}>
              <DataWrapper>
                <FormControl fullWidth={true}>
                  <ApplyTargetInfoTable
                    applyTargetType={state?.applyTargetType}
                    applyTargetInfo={state?.applyTargetInfo || []}
                    // handleOnChangeState =  { (targets) => {
                    //    // setState((state) => (state ? { ...state, ...partialState } : state))
                    //   setState((prevState) => ({
                    //     ...prevState,
                    //     applyTargetInfo: targets,
                    //   }))
                    // }
                    // }
                    handleOnChangeState={(targets:CouponApplyTargetInfo[]) => handleOnChangeState(targets) }
                    openShowTargetSelectPopup={() => onOpenShowTargetSelectPopup() }
                  />
                </FormControl>
              </DataWrapper>
            </DataRowWrapper>
          }
        </PopupContainer>
        <PopupPageFooter>
          <ButtonListWrapper>
            { editorType === "EDIT" &&
            <ButtonWrapper style={{ width: "130px" }}>
              <Button onClick={onPreIssueCoupon}>선발행 쿠폰 발행</Button>
            </ButtonWrapper>
            }
            <ButtonWrapper style={{ width: "80px" }}>
              <Button onClick={onSubmit}>{editorType === "CREATE" ? "생성" : "수정"}</Button>
            </ButtonWrapper>
            <ButtonWrapper style={{ width: "80px" }}>
              <Button buttonStyle="blue-border" onClick={closePopup}>
                닫기
              </Button>
            </ButtonWrapper>
          </ButtonListWrapper>
        </PopupPageFooter>
      </ContentWrapper>


    </Dialog>
  )
}

export default EditCouponPopup

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    padding: 16px;
`

const ButtonWrapper = styled.div`
    :not(:last-child) {
        margin-right: 10px;
    }
`

const ButtonListWrapper = styled.div`
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
`

const DatePicker = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #eee;
    outline: none;
    border-radius: 4px;
    padding: 10px;
`

const DataRowWrapper = styled.div<{ columnCount: number }>`
    display: grid;
    grid-template-columns: repeat(${({ columnCount }) => columnCount}, 1fr);
    gap: 16px;
    width: 100%;
    padding: 4px;
    box-sizing: border-box;
`;

export const DataWrapper = styled.div`
    display: flex;
    align-items: center; /* 세로 정렬 */
    gap: 16px; /* Title과 Input 간격 */
`;

export const Title = styled.div`
    font-size: 16px;
    font-weight: bold;
    flex: 0 0 115px; /* 고정 너비 */
`;

export const Input = styled.input`
    flex: 1; /* 남은 공간을 차지 */
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
`;


const PopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    padding: 16px;
    overflow: visible; /* 메뉴가 가려지지 않도록 설정 */
    position: static;  /* 필요에 따라 relative 대신 static으로 변경 */
`