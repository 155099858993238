import ListTable from "components/Table/List"
import TableBody from "components/Table/List/TableBody"
import ListTableFooter from "components/Table/List/TableFooter"

import { Pagination } from "@mui/material"
import { PaginationParamsType } from "types/PageType"
import { PointType } from "types/PointType"
import PointListTableHeader from "./TableHeader"
import PointListTableRow from "./TableBody/TableRow"

type Props = {
  pointList: PointType[]
  paginationParams: PaginationParamsType
  changePage: (page: number) => void
  cancelPoint: (poindId: number) => void
}

const PointListTable = ({ pointList, paginationParams, changePage, cancelPoint }: Props) => {
  return (
    <ListTable>
      <PointListTableHeader />
      <TableBody>
        {pointList.map((point) => (
          <PointListTableRow key={point.id} point={point} cancelPoint={cancelPoint} />
        ))}
      </TableBody>
      <ListTableFooter>
        <Pagination
          count={paginationParams.totalPages}
          onChange={(e, page) => changePage(page - 1)}
          page={paginationParams.number ? paginationParams.number + 1 : 1}
        />
      </ListTableFooter>
    </ListTable>
  )
}

export default PointListTable
