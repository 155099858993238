import { httpGet, httpGetToBlob, httpPost } from "../utils/httpAPI"
import { OfficialModelResultType } from "../types/OfficialModelResultType"

export const getOfficialModelList: (searchParams: any) => Promise<OfficialModelResultType> = (searchParams) =>
  httpGet("/official-model/list", searchParams)
export const downloadOfficialModelList: (searchParams: any) => Promise<Blob> = (searchParams) =>
  httpGetToBlob("/official-model/download", searchParams)
export const updateOfficialModelList: (req: any) => Promise<any> = (req) =>
  httpPost("/official-model/update", req)
export const applyOfficialModelPriceList: (req: any) => Promise<any> = (req) =>
  httpPost("/official-model/apply", req)
