import { DataGrid, GridColumnHeaderParams } from "@mui/x-data-grid"
import React from "react"
import { numberWithCommas } from "../../../utils/NumberUtils"

//@ts-ignore
const OfficialPriceTable = ({ rows, onPageChange, onPageSizeChange, onSelectionModelChange, selectedIds, page, size, totalCount }) => {
  const columns = [
    {
      field: "metricsDate",
      editable: false,
      sortable: false,
      width: 100,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1, textAlign: "center" }}>
          공식출시가 <br />수집월
        </p>
      ),
      renderCell: (params: any) => {
        return <div style={{ lineHeight: 1, whiteSpace: "normal", wordWrap: "break-word", overflow: "hidden", wordBreak: "break-all", maxWidth: "100%" }}>{params.row.metricsMonth}</div>
      },
    },
    {
      field: "brandSid",
      headerName: "브랜드",
      editable: false,
      sortable: false,
      type: "string",
      width: 110,
      renderCell: (params: any) => {
        return <div style={{ lineHeight: 1, whiteSpace: "normal", wordWrap: "break-word", overflow: "hidden", wordBreak: "break-all", maxWidth: "100%" }}>{params.row.brandSid}</div>
      },
    },
    {
      field: "fullRefNo",
      headerName: "Full Ref No.",
      editable: false,
      sortable: false,
      type: "string",
      width: 200,
      renderCell: (params: any) => {
        return <div style={{ lineHeight: 1, whiteSpace: "normal", wordWrap: "break-word", overflow: "hidden", wordBreak: "break-all", maxWidth: "100%" }}>{params.row.fullRefNo}</div>
      },
    },
    {
      field: "officialPrice",
      headerName: "공식출시가",
      editable: false,
      sortable: false,
      align: "right" as const,
      minWidth: 150,
      renderCell: (params: any) => {
        return <div style={{ lineHeight: 1, whiteSpace: "normal", wordWrap: "break-word", overflow: "hidden", wordBreak: "break-all", maxWidth: "100%" }}>{numberWithCommas(params.row.officialPrice)}원</div>
      },
    },
    {
      field: "modelName",
      headerName: "모델명",
      editable: false,
      sortable: false,
      type: "string",
      flex: 10,
      renderCell: (params: any) => {
        return <div style={{ lineHeight: 1, whiteSpace: "normal", wordWrap: "break-word", overflow: "hidden", wordBreak: "break-all", maxWidth: "100%" }}>
          <a target="_blank" href={params.row.modelDetailUrl} rel="noreferrer">{params.row.modelName} </a>
        </div>
      },

    },
    {
      field: "detailModelExists",
      editable: false,
      sortable: false,
      width: 80,
      align: "center" as const,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{ lineHeight: 1, textAlign: "center", width: "100%" }}>
          모델생성<br />YN
        </div>
      ),
      renderCell: (params: any) => {
        return <div style={{ lineHeight: 1, whiteSpace: "normal", wordWrap: "break-word", overflow: "hidden", wordBreak: "break-all", maxWidth: "100%" }}>{params.row.detailModelId ? "Y" : "N"}</div>
      },
    },
  ]

  return (
    <div style={{ height: 1200, width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        getRowId={(row) => row.id}
        pagination
        page={page}
        pageSize={size}
        rowCount={totalCount}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        paginationMode={"server"}
        selectionModel={selectedIds}
        checkboxSelection
        onSelectionModelChange={onSelectionModelChange}
        disableExtendRowFullWidth={true}
        disableColumnMenu={true}
        getRowHeight={(params) => undefined}
      />

    </div>
  )
}

export default OfficialPriceTable