import { httpGet, httpPost, httpPut } from "../utils/httpAPI"

import { PageType } from "types/PageType"

import { ModelRequestDetailType, ModelRequestListItemType, ModelRequestPostBody } from "types/ModelRequestType"
import { BrandType } from "types/BrandType"
import { ModelType } from "types/ModelType"
import { UserType } from "types/UserType"
import { SellOrderType } from "../types/SellOrderType"

export const createModelRequest = (requestBody: ModelRequestPostBody): Promise<ModelRequestDetailType> =>
  httpPost("/detail-model-requests", requestBody)

export const rejectModelRequest: (id: number, rejectReason: string) => Promise<SellOrderType> = (id: number, rejectedReason: string) => httpPost(`/detail-model-requests/${id}/reject`, { rejectedReason: rejectedReason })

export const cancelModelRequest: (id: number, body: { detailModel: { id: string } }) => Promise<string> = (id, body) =>
  httpPost(`/detail-model-requests/${id}/cancel`, body)

export const acceptModelRequest: (id: number) => Promise<string> = (id) =>
  httpPost(`/detail-model-requests/${id}/accept`)

export const updateModelRequestDetail: (
  id: number,
  requestBody: ModelRequestPostBody,
) => Promise<ModelRequestDetailType> = (id, requestBody) => httpPut(`/detail-model-requests/${id}`, requestBody)

export const getModelRequestDetail: (id: number) => Promise<ModelRequestDetailType> = (id) =>
  httpGet(`/detail-model-requests/${id}`)

export const getModelRequestList: (params: {
  page?: number
  size: number
  brandSid?: string
  fullRefNo?: string
  name?: string
  status?: string
  sort?: string
}) => Promise<PageType<ModelRequestListItemType>> = async (params) => {
  const result = await httpGet<
    Promise<
      PageType<{
        id: number
        status: "등록완료" | "등록거절" | "등록요청" | "등록중" | "요청취소"
        brand: BrandType
        model: ModelType
        name: string
        title: string
        fullRefNo: string
        requestedAt: string
        requestedAdmin?: UserType
        requestedUser?: UserType
      }>
    >
  >("/detail-model-requests", params, { emptyStringAcceptable: true })
  return {
    ...result,
    content: result.content.map((response) => {
      return { ...response, requestedFrom: response.requestedUser || response.requestedAdmin }
    }),
  }
}
