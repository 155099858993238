import { httpGet, httpGetToBlob, httpPost } from "../utils/httpAPI"
import { PageType } from "../types/PageType"
import { DateTime } from "luxon"
import { SettlementStatusCode } from "../types/SettlementType"

export type SettlementListSearchParams = {
  payoutId?:string;
  orderId?:string;
  userId?:string;
  confirmStatus?:'대기'|'승인'
  page?:number;
  size?:number;
  sort?:'confirmedAt,desc',
  productId?:string,
  dateCriteria?: string
  startDate?: string
  endDate?: string,
  status?: SettlementStatusCode[]
}

export const getSettlementList = (params:SettlementListSearchParams)=>{
  return httpGet<PageType<{
    orderId:string;
    userId:number;
    confirmStatus:'대기'|'승인';
    settlementStatus:SettlementStatusCode;
    productPrice:number;
    amount:number;
    feeAmount:number;
    payoutDate:string; // 지급 요청일
    confirmedAt:string; //지급 승인일
    product:{
      id:number;
      title:string;
      titleSecondary:string
    };
    dateCriteria?: string;
    startDate?: string
    endDate?: string
  }>>('/settlements', params)
}

export const approveSettlementPayment = (sellOrderId:string)=>{

  return httpPost<{
    orderId:string;
    userId:number;
    confirmStatus:'승인';
    settlementStatus:'지급 대기';
    productPrice:number;
    amount:number;
    feeAmount:number;
    confirmedAt:string
  }>(`/settlements/${sellOrderId}/confirm`)
}

export const downloadSettlementExcel = (params?: { [key: string]: any }): Promise<Blob> =>
  httpGetToBlob("/settlements/excel", params)

export const requestPayout = (payoutDate: string, sellerId: number | null) => {
  return httpPost(`/settlements/payout`, {payoutDate: payoutDate, sellerId: sellerId})
}