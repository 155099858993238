import React, { useCallback, useEffect, useState } from "react"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material"
import {
  FeePolicyCalculationType,
  FeePolicyDataType,
  FeePolicyTargetType,
  FeePolicyTypeType,
} from "../../../types/FeePolicyType"
import { Spacer } from "../../../components/Spacer"
import { DiscountReasonTypeCode } from "../../../types/BuyOrderType"
import { DesktopDatePicker } from "@mui/lab"
import { insertFeePolicy, updateFeePolicy } from "../../../apis/feePolicyAPI"
import { toDateStr, toTimeStr } from "../../../utils/datetimeUtil"
import { toast } from "react-toastify"
import { isNil } from "../../../utils/validationUtils"
import ProductSelectPopup from "../../../components/Popup/ProductSelectPopup"
import { ProductStatusCode, ProductType } from "../../../types/ProductType"
import UserSelectPopup from "../../../components/Popup/UserSelectPopup"
import { UserStatusType, UserType } from "../../../types/UserType"
import _ from "lodash"
import DetailModelSelectPopup from "../../../components/Popup/DetailModelSelectPopup"
import { BrandType } from "../../../types/BrandType"
import BrandSelectPopup from "../../../components/Popup/BrandSelectPopup"
import { Help } from "@mui/icons-material"
import { DateTime } from "luxon"
import ModelSelectPopup from "../../ShopManager/ShopContentTab/BrandModelCurationTab/ModelSelectPopup"

type EditFeePolicyDataType = Omit<FeePolicyDataType, "updatedAt" | "createdAt" | "feePolicyStatus">

const initState: EditFeePolicyDataType = {
  id: null,
  name: "주문 관리 수수료",
  type: FeePolicyTypeType.판매_결제_수수료,
  saleType: null,
  targetType: null,
  targetId: null,
  priority: null,
  calculationType: FeePolicyCalculationType.정률,
  rate: null,
  amount: null,
  minAmount: null,
  maxAmount: null,
  isEvent: null,
  onDisplay: null,
  discountReasonMemo: null,
  discountReasonType: null,
  discountRate: null,
  discountMaxAmount: null,
  discountMinAmount: null,
  onStartCreatedAt: false,
  startDate: null,
  endDate: null,
}

type EditFeePolicyModalProps = {
  open: boolean
  feePolicy?: FeePolicyDataType
  handleClose: () => void
}

type ModalStateType = {
  product: boolean
  user: boolean
  detailModel: boolean
  brand: boolean
  category: boolean
  model: boolean
}

type FeePolicyModalMode = "NEW" | "EDIT"

export const FeePolicyModal: React.FC<EditFeePolicyModalProps> = (props) => {
  const [state, setState] = useState<EditFeePolicyDataType>(initState)
  const [mode, setMode] = useState<FeePolicyModalMode>("NEW")
  const [modalState, setModalState] = useState<ModalStateType>({
    brand: false,
    user: false,
    category: false,
    detailModel: false,
    model: false,
    product: false,
  })

  const validateRate = useCallback((event: any | null) => {
    const newValue = event.target.value

    const isTwoDecimalPlaces = (value: string) => {
      const parts = value.split(".")
      return parts.length <= 2 && (parts[1]?.length ?? 0) <= 1 && parts[1] != "0"
    }

    return newValue === "" || (!isNaN(newValue) && newValue <= 100 && newValue >= 0 && isTwoDecimalPlaces(newValue))
  }, [])

  useEffect(() => {
    if (props.feePolicy) {
      setState(props.feePolicy)
      setMode("EDIT")
    } else {
      setState(initState)
      setMode("NEW")
    }
  }, [props.open])

  const validateConfirm = () => {
    if (isNil(state.targetType)) {
      toast.error("적용 구분을 선택해주세요.")
      return false
    }

    if (isNil(state.targetId) || state.targetId === "") {
      toast.error("적용 값을 선택해주세요.")
      return false
    }
    if (state.calculationType === FeePolicyCalculationType.정률) {
      // @ts-ignore
      if (isNil(state.rate) || state.rate === "") {
        toast.error("수수료율을 입력해주세요.")
        return false
      }
    }

    if (state.calculationType === FeePolicyCalculationType.정가) {
      // @ts-ignore
      if (isNil(state.amount) || state.amount === "") {
        toast.error("수수료 금액을 입력해주세요.")
        return false
      }
    }

    // @ts-ignore
    if (isNil(state.maxAmount) || state.maxAmount === "") {
      toast.error("최대수수료값을 입력해주세요.")
      return false
    }

    if (isNil(state.startDate) || state.startDate === "-") {
      toast.error("시작일을 선택해주세요.")
      return false
    }
    return true
  }
  const addFeePolicy = () => {
    if (validateConfirm()) {
      insertFeePolicy(state)
        .then(() => {
          props.handleClose()
        })
        .catch((e) => {
          setState((prevState) => ({
            ...prevState,
            rate: prevState.rate === null ? null : prevState.rate * 100,
            discountRate: prevState.discountRate === null ? null : prevState.discountRate * 100,
          }))
          toast.error(e.message)
        })
    }
  }

  const setFeePolicy = () => {
    if (validateConfirm()) {
      updateFeePolicy(state)
        .then(() => {
          props.handleClose()
        })
        .catch((e) => {
          setState((prevState) => ({
            ...prevState,
            rate: prevState.rate === null ? null : prevState.rate * 100,
            discountRate: prevState.discountRate === null ? null : prevState.discountRate * 100,
          }))
          toast.error(e.message)
        })
    }
  }

  function isEditablePeriod(startDate: string | null | undefined): boolean {
    if (startDate == null) return false
    const now = new Date()
    const start = new Date(startDate)

    return start <= now
  }

  function addOneYear(date: Date): Date {
    const newDate = new Date(date.getTime())
    newDate.setFullYear(newDate.getFullYear() + 1)
    return newDate
  }

  return (
    <Dialog open={props.open} maxWidth={"xl"} fullWidth>
      <UserSelectPopup
        open={modalState.user}
        filterStatus={["활성"]}
        isMultiSelectable={true}
        setUsers={(results?: UserType[]) => {
          if (results) {
            setState((prevState) => ({
              ...prevState,
              targetId: results.map((value) => value.id).join(","),
            }))
          }
        }}
        close={() => {
          setModalState((prevState) => ({
            ...prevState,
            user: false,
          }))
        }}
      />

      <ProductSelectPopup
        open={modalState.product}
        isMultiSelectable={true}
        isSearchable={true}
        setProducts={(results?: ProductType[]) => {
          if (results) {
            setState((prevState) => ({
              ...prevState,
              targetId: results.map((value) => value.id).join(","),
            }))
          }
        }}
        close={() => {
          setModalState((prevState) => ({
            ...prevState,
            product: false,
          }))
        }}
      />

      <DetailModelSelectPopup
        open={modalState.detailModel}
        handleClose={(results) => {
          if (results) {
            setState((prevState) => ({
              ...prevState,
              targetId: results.map((value) => value.id).join(","),
            }))
            setModalState((prevState) => ({
              ...prevState,
              detailModel: false,
            }))
          }
        }}
      />

      <ModelSelectPopup
        open={modalState.model}
        handleClose={(results) => {
          if (results) {
            setState((prevState) => ({
              ...prevState,
              targetId: results.map((value) => value.id).join(","),
            }))
            setModalState((prevState) => ({
              ...prevState,
              model: false,
            }))
          }
        }}
      />

      <BrandSelectPopup
        open={modalState.brand}
        isMultiSelectable={true}
        setBrands={(results?: BrandType[]) => {
          if (results) {
            setState((prevState) => ({
              ...prevState,
              targetId: results.map((value) => value.sid).join(","),
            }))
          }
        }}
        close={() => {
          setModalState((prevState) => ({
            ...prevState,
            brand: false,
          }))
        }}
      />

      <DialogTitle>수수료 {mode === "NEW" ? "추가" : "수정"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <DialogContentText fontSize={14}>
                {"* 수수료 적용 우선순위 : 상품 > 유저 > 상세모델 > 모델 > 브랜드 > 카테고리"}
              </DialogContentText>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>수수료명</InputLabel>
              <Select
                required
                disabled={mode === "EDIT"}
                value={state.name}
                defaultValue={"주문 관리 수수료"}
                onChange={(params) => {
                  const searchName = params.target.value as
                    | "거래 수수료"
                    | "판매 진단 수수료"
                    | "배송비"
                    | "서비스 수수료"
                    | "페널티 금액"
                    | "일반 감정진단"
                    | "반품 배송비"
                    | "정밀 감정진단"
                    | "주문 관리 수수료"
                  let searchType: FeePolicyTypeType | null

                  switch (searchName) {
                    case "주문 관리 수수료":
                      searchType = FeePolicyTypeType.판매_결제_수수료
                      break
                    case "배송비":
                      searchType = FeePolicyTypeType.판매_배송_수수료
                      break
                    default:
                      searchType = null
                  }

                  setState((prevState) => ({ ...prevState, name: searchName, type: searchType }))
                }}
              >
                <MenuItem value={"주문 관리 수수료"}>{"주문 관리 수수료"}</MenuItem>
                {/*<MenuItem value={"배송비"}>{"배송비"}</MenuItem>*/}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>수수료 타입</InputLabel>
              <Select
                required
                disabled={mode === "EDIT"}
                value={state.type}
                onChange={(params) => {
                  setState((prevState) => ({ ...prevState, type: params.target.value as any }))
                }}
              >
                {state.name === "주문 관리 수수료" && (
                  <MenuItem value={"판매 결제 수수료"}>{"판매 결제 수수료"}</MenuItem>
                )}
                {state.name === "배송비" && <MenuItem value={"판매 배송 수수료"}>{"판매 배송 수수료"}</MenuItem>}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>판매 타입</InputLabel>
              <Select
                disabled={mode === "EDIT"}
                value={state.saleType ?? "전체"}
                defaultValue={"전체"}
                onChange={(params) => {
                  setState((prevState) => ({ ...prevState, saleType: params.target.value as any }))
                }}
              >
                <MenuItem value={"전체"}>{"전체"}</MenuItem>
                <MenuItem value={"직접판매"}>{"직접판매"}</MenuItem>
                <MenuItem value={"위탁판매"}>{"위탁판매"}</MenuItem>
                <MenuItem value={"진단전판매"}>{"진단전판매"}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>적용 구분</InputLabel>
              <Select
                disabled={mode === "EDIT"}
                required
                value={state.targetType ?? "전체"}
                onChange={(params) => {
                  const targetType = params.target.value as FeePolicyTargetType

                  let stateUpdate = {}
                  let modalStateUpdate = {}
                  switch (targetType) {
                    case FeePolicyTargetType.상품:
                      stateUpdate = { targetType: targetType, targetId: null, priority: 100 }
                      modalStateUpdate = { product: true }
                      break
                    case FeePolicyTargetType.유저:
                      stateUpdate = { targetType: targetType, targetId: null, priority: 200 }
                      modalStateUpdate = { user: true }
                      break
                    case FeePolicyTargetType.상세모델:
                      stateUpdate = { targetType: targetType, targetId: null, priority: 300 }
                      modalStateUpdate = { detailModel: true }
                      break
                    case FeePolicyTargetType.브랜드:
                      stateUpdate = { targetType: targetType, targetId: null, priority: 500 }
                      modalStateUpdate = { brand: true }
                      break
                    case FeePolicyTargetType.모델:
                      stateUpdate = { targetType: targetType, targetId: null, priority: 400 }
                      modalStateUpdate = { model: true }
                      break
                    default:
                      stateUpdate = { targetType: targetType, targetId: null, priority: null }
                      break
                  }

                  setState((prevState) => ({ ...prevState, ...stateUpdate }))
                  setModalState((prevState) => ({
                    ...prevState,
                    ...modalStateUpdate,
                  }))
                }}
              >
                <MenuItem value={"전체"}>{"전체"}</MenuItem>
                <MenuItem value={"상품"}>{"상품"}</MenuItem>
                <MenuItem value={"유저"}>{"유저"}</MenuItem>
                <MenuItem value={"상세모델"}>{"상세모델"}</MenuItem>
                <MenuItem value={"모델"}>{"모델"}</MenuItem>
                <MenuItem value={"브랜드"}>{"브랜드"}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              label={"적용 값"}
              InputLabelProps={{ shrink: true }}
              value={state.targetId ?? ""}
              disabled={!!props.feePolicy}
              autoFocus
              fullWidth
              onChange={(event) => {
                setState((prevState) => ({
                  ...prevState,
                  targetId: event.target.value as any,
                }))
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              label={"수수료 조정 사유"}
              InputLabelProps={{ shrink: true }}
              value={state.discountReasonMemo ?? ""}
              autoFocus
              fullWidth
              onChange={(event) => {
                setState((prevState) => ({
                  ...prevState,
                  discountReasonMemo: event.target.value as any,
                }))
              }}
              disabled={isEditablePeriod(props.feePolicy?.startDate)}
            />
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>계산 타입</InputLabel>
              <Select
                value={state.calculationType ?? ""}
                onChange={(params) => {
                  const calculationType = params.target.value as any
                  setState((prevState) => ({
                    ...prevState,
                    calculationType: calculationType,
                    rate: calculationType === FeePolicyCalculationType.정가 ? null : prevState.rate,
                    amount: calculationType === FeePolicyCalculationType.정률 ? null : prevState.amount,
                  }))
                }}
                disabled={isEditablePeriod(props.feePolicy?.startDate)}
              >
                <MenuItem value={FeePolicyCalculationType.정률}>{"정률"}</MenuItem>
                <MenuItem value={FeePolicyCalculationType.정가}>{"정가"}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <TextField
                type={"number"}
                label={"수수료율"}
                disabled={
                  state.calculationType === FeePolicyCalculationType.정가 ||
                  isEditablePeriod(props.feePolicy?.startDate)
                }
                value={state.rate ?? ""}
                onChange={(params) => {
                  if (validateRate(params))
                    setState((prevState) => ({ ...prevState, rate: params.target.value as any }))
                }}
                // placeholder={""}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  step: "0.1",
                }}
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <TextField
                label={"수수료금액"}
                type={"number"}
                disabled={
                  state.calculationType === FeePolicyCalculationType.정률 ||
                  isEditablePeriod(props.feePolicy?.startDate)
                }
                placeholder={""}
                InputLabelProps={{ shrink: true }}
                onChange={(params) => {
                  setState((prevState) => ({ ...prevState, amount: params.target.value as any }))
                }}
                value={state.amount ?? ""}
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                required
                label={"최대수수료"}
                type={"number"}
                value={state.maxAmount ?? ""}
                placeholder={""}
                onChange={(params) => {
                  setState((prevState) => ({ ...prevState, maxAmount: params.target.value as any }))
                }}
                InputLabelProps={{ shrink: true }}
                disabled={isEditablePeriod(props.feePolicy?.startDate)}
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                label={"최소수수료"}
                type={"number"}
                value={state.minAmount ?? ""}
                placeholder={""}
                onChange={(params) => {
                  setState((prevState) => ({ ...prevState, minAmount: params.target.value as any }))
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                disabled={isEditablePeriod(props.feePolicy?.startDate)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth style={{ display: "flex", alignItems: "start", justifyContent: "center" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ fontSize: 12, color: "gray" }}>판매시작일 적용 여부</div>
                <Tooltip style={{ height: 0 }} title={"지정한 날짜부터 판매된 상품을 대상으로 수수료를 적용합니다."}>
                  <IconButton size={"small"}>
                    <Help fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
              <Switch
                checked={state.onStartCreatedAt ?? false}
                onChange={(_event, checked) => {
                  setState((prevState) => ({
                    ...prevState,
                    onStartCreatedAt: checked,
                  }))
                }}
                disabled={isEditablePeriod(props.feePolicy?.startDate)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <DesktopDatePicker
                label="시작일"
                inputFormat="yyyy-MM-dd"
                value={state.startDate ?? null}
                onChange={(params) => {
                  setState((prevState) => ({
                    ...prevState,
                    startDate: toDateStr(params as any, true) as any,
                  }))
                }}
                disabled={isEditablePeriod(props.feePolicy?.startDate)}
                renderInput={(params) => <TextField required fullWidth {...params} />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <DesktopDatePicker
                label="종료일"
                inputFormat="yyyy-MM-dd"
                value={state.endDate ?? null}
                onChange={(params) => {
                  setState((prevState) => ({
                    ...prevState,
                    endDate: toDateStr(params as any, true) as any,
                  }))
                }}
                disabled={isEditablePeriod(props.feePolicy?.startDate)}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth style={{ display: "flex", alignItems: "start", justifyContent: "center" }}>
              <div style={{ fontSize: 12, color: "gray" }}>수수료 적용 ON/OFF</div>
              <Switch
                checked={state.onDisplay ?? false}
                onChange={(_event, checked) => {
                  setState((prevState) => ({
                    ...prevState,
                    onDisplay: checked,
                  }))
                }}
              />
            </FormControl>
          </Grid>
          <Spacer space={60} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size={"medium"} variant={"contained"} onClick={props.handleClose} color={"error"}>
          취소
        </Button>
        <Button size={"medium"} variant={"contained"} onClick={mode === "NEW" ? addFeePolicy : setFeePolicy}>
          {mode === "NEW" ? "추가" : "변경"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
