import { Button, Grid, Paper, Stack } from "@mui/material"
import DateRangePicker from "../../components/DateRangePicker"
import React, { useState } from "react"
import { DateTime } from "luxon"
import { downloadFinanceReport } from "../../apis/reportAPI"
import { toast } from "react-toastify"


const initParams = {
  startDate: DateTime.now().minus({ day: 7 }).toISODate(),
  endDate: DateTime.now().toISODate(),
}


const FinanceManager = () => {
  const [searchParams, setSearchParams] = useState(initParams)

  const handleExcelDownloadClick = async () => {
    downloadFinanceReport({ startDate: searchParams.startDate, endDate: searchParams.endDate }).then((result) => {
      toast.success("정산파일을 다운로드 요청했습니다. 파일이 준비되면 Slack으로 링크가 전달될 예정입니다.")
    }).catch(() => {
      toast.error("정산파일을 다운로드 요청하지 못했습니다.")
    })
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Stack spacing={2}>
            <DateRangePicker
              startDate={searchParams.startDate}
              endDate={searchParams.endDate}
              onChange={(change) => {
                setSearchParams({ ...searchParams, startDate: change.startDate, endDate: change.endDate })
              }}
            />
            <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
              <Button variant={"contained"} size={"small"} onClick={handleExcelDownloadClick}>엑셀 다운로드</Button>
            </Stack>
          </Stack>
        </Paper>
      </Grid>

    </Grid>)
}

export default FinanceManager