export declare interface AdminUserType {
  id?: number
  name?: string | undefined
  email?: string
  profileImageUrl?: string | undefined
  menus?: string[]
  registrationId?: string | undefined
  authorities?: AdminUserAuthorityTypeCode[]
  groupName?: string
  menuViewId?: number
}

export declare interface UpdateAdminUserType {
  id?: number
  name?: string | undefined
  authorities?: AdminAuthorityType[]
  menuViewId?: number
}

export declare interface AdminUserListSearchParams {
  id?: number
  name?: string
  email?: string
  groupName?: string

  page?: number
  size?: number
  sort?: string
}

export declare interface AdminAuthorityType {
  id?: AdminUserAuthorityTypeCode
  name: string
}

export declare interface AdminGroupType {
  id?: string
  groupName?: string
  menuViews: string[]
}

export declare interface UpdateAdminGroupType {
  id?: number
  menuViews: string[]
}

export enum AdminUserAuthorityTypeCode {
  READ_USER_PRIVACY = "READ_USER_PRIVACY",
  READ_FINANCE_DATA = "READ_FINANCE_DATA",
  SUPER_LOGIN = "SUPER_LOGIN",
  DIAGNOSIS_ROLLBACK = "DIAGNOSIS_ROLLBACK",
  PRODUCT_ROLLBACK = "PRODUCT_ROLLBACK",
}
