import {BuyOrderType} from "types/BuyOrderType"
import {toDateTimeStr} from "utils/datetimeUtil"
import {numberWithCommas} from "utils/NumberUtils"
import React, {useEffect, useMemo, useState} from "react"
import {getRepurchaseUntil} from "utils/rePurchaseGuaranteedUtils"
import {UserDetailType, UserMemoTargetTypeCode, UserType} from "../../../../types/UserType"
import QuickMemoButton from "../../../../components/QuickMemo/QuickMemoButton"
import {getUserDetail} from "../../../../apis/userAPI"
import {toast} from "react-toastify"
import {FormControlLabel, Switch} from "@mui/material"
import {SerialNumberView} from "components/SerialNumber/SerialNumberView"
import {CurrencyCode} from "../../../../types/ProductType";

type Props = {
  buyOrder?: BuyOrderType
}

const BuyOrder = ({ buyOrder }: Props) => {
  const [buyer, setBuyer] = useState<UserType | UserDetailType | undefined>(buyOrder?.buyer)
  const [unmask, setUnmask] = useState<boolean>(false)

  useEffect(() => {
    setBuyer(buyOrder?.buyer)
  }, [buyOrder])

  useEffect(() => {
    if (buyer?.id)
      getUserDetail("" + buyer?.id, unmask)
        .then((user) => setBuyer(user))
        .catch((err) => {
          toast.error("회원 정보 조회에 실패했습니다.")
          console.log("Error", err)
        })
  }, [unmask])

  const rePurchaseGuaranteedUntil = useMemo(() => {
    return !buyOrder?.product?.repurchaseGuaranteePeriod || !buyOrder?.completedAt
      ? undefined
      : getRepurchaseUntil(buyOrder?.completedAt, buyOrder?.product.repurchaseGuaranteePeriod)
  }, [buyOrder?.completedAt, buyOrder?.product?.repurchaseGuaranteePeriod])

  const reSellGuaranteedInformation = useMemo(() => {
    return !buyOrder?.product?.repurchaseGuaranteePeriod
      ? "해당없음"
      : `${buyOrder?.product?.repurchaseGuaranteePeriod}일 재매입 보증 (${(
          buyOrder?.product?.repurchaseGuaranteeRate! * 100
        ).toFixed(0)}%)`
  }, [buyOrder?.product?.repurchaseGuaranteePeriod, buyOrder?.product?.repurchaseGuaranteeRate])

  return (
    <>
      <h3>구매 주문 정보</h3>
      <table className={"basic"}>
        <tbody>
          <tr>
            <td className={"no-border"} colSpan={6} style={{ textAlign: "end" }}>
              <FormControlLabel
                style={{ color: "#ed3030" }}
                control={<Switch color="warning" value={unmask} />}
                label="개인정보 마스킹 해제"
                checked={unmask}
                onChange={(event, checked) => {
                  if (checked) {
                    checked = window.confirm(
                      "업무상 고객의 개인정보를 조회합니다." +
                        "\n\n* 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공할 경우 " +
                        "\n5년 이하의 징역 또는 5천만원 이하의 벌금에 처해질 수 있습니다."
                    )
                  }
                  setUnmask(checked)
                }}
              />
            </td>
          </tr>
          <tr>
            <td className={"ta-center"} rowSpan={10}>
              <img
                style={{ display: "block", margin: "auto", width: "200px", height: "300px", objectFit: "cover" }}
                src={buyOrder?.product?.thumbnail}
                alt=""
              />
            </td>
            <td className={"header"}>구매주문번호</td>
            <td className={"header"}>판매개시 일시</td>
            <td className={"header"}>구매신청 일시</td>
            <td className={"header"}>결제 일시</td>
            <td className={"header"}>판매방식</td>
          </tr>
          <tr>
            <td>{buyOrder?.id}</td>
            <td>{toDateTimeStr(buyOrder?.product?.startSaleAt)}</td>
            <td>{toDateTimeStr(buyOrder?.orderedAt)}</td>
            <td>{toDateTimeStr(buyOrder?.payment?.transactionAt)}</td>
            <td>{buyOrder?.product?.saleType}</td>
          </tr>
          <tr>
            <td className={"header"}>구매자</td>
            <td className={"header"}>연락처</td>
            <td className={"header"}>바이버 계정</td>
            <td className={"header"}>매입보증</td>
            <td className={"header"}>매입보증 만료</td>
          </tr>
          <tr>
            <td>
              {buyer?.name}
              <QuickMemoButton target={UserMemoTargetTypeCode.USER} targetId={buyer?.id} small />
            </td>
            <td>{buyer?.phone}</td>
            <td>{buyer?.email}</td>
            <td>{reSellGuaranteedInformation}</td>
            <td>{rePurchaseGuaranteedUntil ? toDateTimeStr(rePurchaseGuaranteedUntil) : "-"}</td>
          </tr>
          <tr>
            <td className={"header"}>시리얼 넘버</td>
            <td className={"header"} colSpan={3}>
              상품명
            </td>
            <td className={"header"} colSpan={1}>
              Ref No.
            </td>
          </tr>
          <tr>
            <td>{buyOrder?.product?.serialNo ? <SerialNumberView serialNo={buyOrder?.product?.serialNo} /> : "-"}</td>
            <td colSpan={1}>{buyOrder?.product?.brandSid}</td>
            <td colSpan={1}>{buyOrder?.product?.title}</td>
            <td colSpan={1}>{buyOrder?.product?.titleSecondary}</td>
            <td colSpan={1}>{buyOrder?.product?.detailModel?.fullRefNo}</td>
          </tr>
          <tr>
            <td className={"header"}>구매가</td>
            <td className={"header"}>서비스 수수료</td>
            <td className={"header"}>배송비</td>
            <td className={"header"}>사용 포인트</td>
            <td className={"header"}>총 할인 금액</td>
          </tr>
          <tr>
            {/*<td>{numberWithCommas(buyOrder?.product?.price)} 원</td>*/}
            {/*<td>{buyOrder?.product?.currency} {CurrencyCode.KR} {buyOrder?.currency}</td>*/}
            <td>
              {buyOrder?.product?.currency === CurrencyCode.KR ? numberWithCommas(buyOrder?.product?.price) + '원' : (buyOrder?.product?.currency === CurrencyCode.US ? buyOrder?.product?.currency + ' $' + numberWithCommas(buyOrder?.product?.price) : buyOrder?.product?.currency + ' ' + numberWithCommas(buyOrder?.product?.price))}
              <br/>
              {buyOrder?.currency != 'KRW' ? (buyOrder?.currency === 'USD' ? buyOrder?.currency + ' $' + numberWithCommas(buyOrder?.productPrice) : buyOrder?.currency + ' ' + numberWithCommas(buyOrder?.productPrice)) : ''}
            </td>
            <td>{buyOrder?.currency === 'KRW' ? (numberWithCommas((buyOrder?.buyingFee || 0) - (buyOrder?.buyingFeeDiscountAmount || 0))) + '원' : buyOrder?.currency === 'USD' ? buyOrder?.currency + ' $' + (numberWithCommas((buyOrder?.buyingFee || 0) - (buyOrder?.buyingFeeDiscountAmount || 0))) : buyOrder?.currency + ' ' + (numberWithCommas((buyOrder?.buyingFee || 0) - (buyOrder?.buyingFeeDiscountAmount || 0)))}</td>
            <td>
              {buyOrder?.currency === 'KRW' ? (numberWithCommas((buyOrder?.shippingFee || 0) - (buyOrder?.shippingFeeDiscountAmount || 0))) || "-" + '원' : buyOrder?.currency === 'USD' ? buyOrder?.currency + ' $' + (numberWithCommas((buyOrder?.shippingFee || 0) - (buyOrder?.shippingFeeDiscountAmount || 0))) : buyOrder?.currency + ' ' + (numberWithCommas((buyOrder?.shippingFee || 0) - (buyOrder?.shippingFeeDiscountAmount || 0)))}
            </td>
            <td>{buyOrder?.currency === 'KRW' ? numberWithCommas(buyOrder?.pointAmount) || "-" + '원' : buyOrder?.currency === 'USD' ? buyOrder?.currency + ' $' + numberWithCommas(buyOrder?.pointAmount) : buyOrder?.currency + ' ' + numberWithCommas(buyOrder?.pointAmount)}</td>
            <td>{buyOrder?.currency === 'KRW' ? numberWithCommas(buyOrder?.paymentStatement?.discountAmount) + '원' : buyOrder?.currency === 'USD' ? buyOrder?.currency + ' $' + numberWithCommas(buyOrder?.discountAmount) : buyOrder?.currency + ' ' + numberWithCommas(buyOrder?.discountAmount)}</td>
          </tr>
          <tr>
            <td className={"header"} colSpan={2}>바이버 프로모션</td>
            <td className={"header"}>진단</td>
            <td className={"header"}>네고 금액</td>
            <td className={"header"}>총 결제 금액</td>
          </tr>
          <tr>
            <td colSpan={2}>
              {buyOrder?.paymentStatement ? buyOrder.paymentStatement.discounts?.filter((it) => (it.type === 'PROMOTION')).map((it) => ( <div>{it.name + ' (' +  numberWithCommas(it.amount) + '원)'}</div>)) : '-'}
            </td>
            <td>
              {buyOrder?.advancedDiagnosis ? "정밀 감정진단" : "일반 감정진단"} (
              {buyOrder?.currency === 'KRW' ? numberWithCommas(buyOrder?.advancedDiagnosis ? buyOrder?.advancedDiagnosisFee : buyOrder?.diagnosisFee) + '원' : buyOrder?.currency === 'USD' ? buyOrder?.currency + ' $' + numberWithCommas(buyOrder?.advancedDiagnosis ? buyOrder?.advancedDiagnosisFee : buyOrder?.diagnosisFee) : buyOrder?.currency + ' ' + numberWithCommas(buyOrder?.advancedDiagnosis ? buyOrder?.advancedDiagnosisFee : buyOrder?.diagnosisFee)}
              )
            </td>
            <td>{buyOrder?.currency === 'KRW' ? numberWithCommas((buyOrder?.product?.negotiatedAmount || 0) * -1) + '원' : (buyOrder?.currency === 'USD' ? buyOrder?.currency + ' $' + numberWithCommas((buyOrder?.product?.negotiatedAmount || 0) * -1) : buyOrder?.currency + ' ' + numberWithCommas((buyOrder?.product?.negotiatedAmount || 0) * -1))}</td>
            <td className={"bold"}>{buyOrder?.currency === 'KRW' ? numberWithCommas(buyOrder?.paymentAmount) + '원' : buyOrder?.currency === 'USD' ? buyOrder?.currency + ' $' + numberWithCommas(buyOrder?.paymentAmount) : buyOrder?.currency + ' ' + numberWithCommas(buyOrder?.paymentAmount)}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default BuyOrder
