import styled from "@emotion/styled"
import Modal from "components/Modal"
import Button from "components/Button"
import React, { useEffect, useState } from "react"
import { TextField } from "@mui/material"
import { updateUserEmail } from "../../../../../../apis/userAPI"
import { UserDetailType } from "../../../../../../types/UserType"

type Props = {
  user: UserDetailType
  isOpen: boolean
  setUserDetail: React.Dispatch<React.SetStateAction<UserDetailType>>
  closeModal: () => void
}

const EmailChangeModal = ({ user, isOpen, setUserDetail, closeModal }: Props) => {
  const [inputValue, setInputValue] = useState("")
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (!isOpen) {
      setInputValue("")
      setIsValidEmail(true)
      setErrorMessage("")
    }
  }, [isOpen])

  const handleInputChange = (event: any) => {
    const value = event.target.value
    setInputValue(value)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    setIsValidEmail(emailRegex.test(value))

    setErrorMessage("")
  }

  const clickChange = () => {
    updateUserEmail(user.id, inputValue)
      .then(() => {
        setUserDetail((prev) => ({
          ...prev,
          email: inputValue,
        }))
        alert(`이메일 주소가 변경되었습니다.`)
        closeModal()
      })
      .catch((error) => {
        setErrorMessage(error.message)
      })
  }

  const handleCloseModal = () => {
    setInputValue("")
    setIsValidEmail(true)
    setErrorMessage("")
    closeModal()
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalContentWrapper>
        <ModalContentHeader>
          <h3>이메일 변경</h3>
        </ModalContentHeader>
        <ModalContentBody>
          <TextField
            placeholder={"변경하려는 이메일 주소를 입력하세요."}
            style={{ width: "95%" }}
            size={"small"}
            value={inputValue}
            onChange={handleInputChange}
            error={(!isValidEmail && inputValue !== "") || !!errorMessage}
            helperText={errorMessage || (!isValidEmail && inputValue !== "" ? "Invalid email address" : " ")}
          />
        </ModalContentBody>
        <ModalContentFooter>
          <ButtonWrapper>
            <Button
              buttonStyle="blue-fill"
              buttonSize="M"
              disabled={!(inputValue && isValidEmail)}
              onClick={clickChange}
            >
              변경하기
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button buttonStyle="black-border" buttonSize="M" onClick={handleCloseModal}>
              닫기
            </Button>
          </ButtonWrapper>
        </ModalContentFooter>
      </ModalContentWrapper>
    </Modal>
  )
}

export default EmailChangeModal

const ModalContentWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 15px;
`

const ModalContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  h3 {
    margin: 0 0 10px 0;
  }
`

const ModalContentBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  min-height: 70px;
`

const ModalContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const ButtonWrapper = styled.div`
  width: 70px;

  :first-child {
    margin-right: 5px;
  }
`
