import { SettlementManagerSearchBar } from "./SettlementManagerSearchBar"
import { SettlementManagerTable } from "./SettlementManagerTable"
import { useSettlementManager } from "./useSettlementManager"
import { RequestPayoutPopup } from "./RequestPayoutPopup"
import { useState } from "react"

export const SettlementManager: React.FC = () => {
  const { state, setState, fetchSettlementList, handleExcelDownload, handleRequestPayout } = useSettlementManager()

  // 팝업을 제어하는 상태
  const [openDialog, setOpenDialog] = useState(false)

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  // const handleRequestSubmit = (payoutDate: string, sellerId: string | null) => {
  //   // 요청 로직 수행
  //   console.log("정산 지급 요청 금액:", payoutDate)
  //   // toast.success("지급 요청이 완료되었습니다.")
  //   // 팝업은 RequestPayoutPopup 내부에서 닫거나, 여기서 handleCloseDialog를 호출해도 됨.
  // }

  return (
    <>
      <SettlementManagerSearchBar
        state={state}
        setState={setState}
        handleExcelDownload={handleExcelDownload}
        handleRequestPayout={handleOpenDialog}
      />
      <SettlementManagerTable
        state={state}
        dispatch={setState}
        components={[]}
        fetch={fetchSettlementList}
      />
      <RequestPayoutPopup
        open={openDialog}
        onClose={handleCloseDialog}
        onRequestAll={handleRequestPayout}
        onRequestUser={handleRequestPayout}
      />
    </>
  )
}

export default SettlementManager