import DashBoard from "pages/Dashboard"
import Brand from "pages/Brand"
import Category from "pages/Category"
import Showroom from "pages/ShowroomManager"
import DisplayDetailModelManagement from "pages/ShowroomManager/DisplayDetailModelManagement"
import Layout from "components/Layout"

import _ from "lodash"
import { Navigate } from "react-router-dom"
import DetailModelManager from "pages/ModelDbMananer/DetailModelManager"
import PriceManager from "pages/PriceManager"
import HomeManager from "pages/HomeManager"
import PreProducts from "pages/PreProduct"
import BuyOrders from "pages/BuyOrder"
import BuyOrderRefunds from "pages/BuyOrderRefund"
import ShopManager from "pages/ShopManager"
import Products from "pages/Product"
import PushManager from "pages/UserNotificationManager"
import ShippingManager from "pages/ShippingManager"
import MagazineTabPage from "pages/ContentManager/MagazineTabPage"
import AppVersions from "pages/AppVersions"
import ConsignmentSellOrder from "pages/SellOrder/Consignment"
import BeforeDiagnosisSellOrder from "pages/SellOrder/BeforeDiagnosis"
import GuaranteedSellOrders from "pages/SellOrder/RePurchaseGuaranteed"
import ConsignmentDiagnoses from "pages/Diagnoses/Consignment"
import BeforeDiagnosisDiagnoses from "pages/Diagnoses/BeforeDiagnosis"
import { RePurchaseGuaranteedDiagnoses } from "pages/Diagnoses/RePurchaseGuaranteed"
import UserListPage from "pages/User/UserListPage"
import UserPointListPage from "pages/User/UserPointListPage"
import PointManagementPage from "pages/Point/PointManagementPage"
import BuyOrderShippings from "./pages/BuyOrderShipping"
import PenaltyListPage from "pages/Penalty/PenaltyListPage"
import NoticeListPage from "pages/NoticeListPage"
import CouponCodeManagementPage from "./pages/CouponCode/CouponCodeManagementPage"
import UserCollectionListPage from "./pages/UserCollecion/UserCollectionListPage"
import FinanceManagerPage from "./pages/FinanceManager"
import { EventListPage } from "pages/Event/ListPage"
import BuyPriceOfferManager from "./pages/BuyPriceOfferManager"
import KakakuProductMappingManager from "./pages/KakakuProductMappingManager"
import KreamProductMappingManager from "./pages/KreamProductMappingManager"
import { SerialNumberSearchPage } from "pages/SerialNumber/SerialNumberSearchPage"
import { UpgradeServiceListPage } from "pages/UpgradeService/UpgradeServiceListPage"
import { FaqListPage } from "pages/Faq/FaqListPage"
import { TermListPage } from "pages/Term/TermListPage"
import { SettlementManager } from "./pages/SettlementManager"
import { ProductTrackingLogListScreen } from "pages/ProductTrackingLog/ProductTrackingLogListScreen"
import { ProvisionalPreSettlementManager } from "./pages/ProvisionalPreSettlementManager"

import ModelRegistrationRequestManager from "pages/ModelRegistrationRequestManager"
import ModelDbManager from "pages/ModelDbMananer/ModelDbManager"
import AllProducts from "./pages/AllProduct"
import { ProductStockManager } from "pages/ProductStockManager/ProductStockManager"
import { NewsLetterManager } from "./pages/NewsLetterManager"
import { PaymentAreaManager } from "./pages/PaymentAreaManager"
import Review from "./pages/Review/Review"
import TranslationColumnManager from "./pages/TranslationColumnManager"
import TranslationManager from "./pages/TranslationManager"

import { DiagnosisPrintOpen } from "./pages/Diagnoses/DiagnosisPrintOpen"
import { FeePolicyManager } from "./pages/FeePolicy/FeePolicyManager"
import RetailPriceManager from "./pages/RetailPriceManager"
import ManualAuthenticationIssuance from "./pages/Diagnoses/ManualAuthenticationIssuance"
import IssueList from "./pages/Diagnoses/IssueList"
import IssueHistory from "./pages/Diagnoses/IssueHistory"
import PointReport from "./pages/PointReport"

import ExcelDownloads from "./pages/ExcelDownloads"
import ProductRequests from "./pages/ProductRequest"
import ExchangeRateFeesListPage from "./pages/ExchangeRate/ExchangeRateFeesListPage"
import AdminUserListPage from "./pages/AdminUser/AdminUserList"
import React from "react"
import AdminGroupListPage from "./pages/AdminUser/AdminGroupList"
import CouponManagementPage from "./pages/Coupon/CouponManagementPage"

const authRouteInfo = [
  {
    element: <Layout />,
    displayAside: false,
    children: [
      {
        path: "/",
        element: <DashBoard />,
        title: "대시보드",
      },
      {
        path: "/dashboard",
        element: <DashBoard />,
        title: "대시보드",
      },
      {
        path: "/brands",
        element: <Brand />,
        title: "브랜드 관리",
      },
      {
        path: "/categories",
        element: <Category />,
        title: "카테고리 관리",
      },
      {
        path: "/showroom-manager",
        element: <Showroom />,
        title: "쇼룸 관리",
      },
      {
        path: "/showroom-manager/display-detail-models",
        element: <DisplayDetailModelManagement />,
        title: "쇼케이스 노출 상세 모델 관리",
      },
      {
        path: "/model-manager/register",
        element: <ModelRegistrationRequestManager />,
        title: "모델 관리",
      },
      {
        path: "/model-manager/model-db",
        element: <ModelDbManager />,
        title: "모델 관리",
      },
      {
        path: "/model-manager/model-db/:modelId",
        element: <DetailModelManager />,
        title: "상세모델 관리",
      },
      {
        path: "/sell-orders/consignment",
        element: <ConsignmentSellOrder />,
        title: "위탁판매 관리",
      },
      {
        path: "/sell-orders/before-diagnosis",
        element: <BeforeDiagnosisSellOrder />,
        title: "진단전판매 관리",
      },
      {
        path: "/sell-orders/guaranteed",
        element: <GuaranteedSellOrders />,
        title: "재매입상품판매 관리",
      },
      {
        path: "/diagnoses/consignment",
        element: <ConsignmentDiagnoses />,
        title: "위탁판매 진단",
      },
      {
        path: "/diagnoses/before-diagnosis",
        element: <BeforeDiagnosisDiagnoses />,
        title: "진단전판매 진단",
      },
      {
        path: "/diagnoses/guaranteed-diagnosis",
        element: <RePurchaseGuaranteedDiagnoses />,
        title: "재매입상품 진단",
      },
      {
        path: "/diagnoses/manual-authentication-issuance",
        element: <ManualAuthenticationIssuance />,
        title: "진단서 수동 발급",
      },
      {
        path: "/pre-products",
        element: <PreProducts />,
        title: "상품화 관리",
      },
      {
        path: "/products",
        element: <Products />,
        title: "판매중 상품 관리",
      },
      {
        path: "/all-products",
        element: <AllProducts />,
        title: "전체 상품 관리",
      },
      {
        path: "/buy-orders",
        element: <BuyOrders />,
        title: "구매주문 관리",
      },
      {
        path: "/buy-order-shippings",
        element: <BuyOrderShippings />,
        title: "구매배송 관리",
      },
      {
        path: "/buy-order-refunds",
        element: <BuyOrderRefunds />,
        title: "취소/반품/환불",
      },
      {
        path: "/price-manager",
        element: <PriceManager />,
        title: "시세 관리자",
      },
      {
        path: "/retail-price-manager",
        element: <RetailPriceManager />,
        title: "공식 출시가 관리",
      },
      {
        path: "/home-manager",
        element: <HomeManager />,
        title: "홈 관리자",
      },
      {
        path: "/shop-manager",
        element: <ShopManager />,
        title: "샵 관리자",
      },
      {
        path: "/magazine-manager",
        element: <MagazineTabPage />,
        title: "매거진 관리",
      },
      {
        path: "/push-manager",
        element: <PushManager />,
        title: "앱 푸시 관리",
      },
      {
        path: "/qr-code",
        element: <AllProducts />,
        title: "진행 상태",
      },
      {
        path: "/shipping-manager",
        element: <ShippingManager />,
        title: "배송 요청",
      },
      {
        path: "/provisional-pre-settlements",
        element: <ProvisionalPreSettlementManager />,
        title: "선정산 가견적 관리",
      },
      {
        path: "/app-versions",
        element: <AppVersions />,
        title: "앱 버전 관리",
      },
      {
        path: "/excel-downloads",
        element: <ExcelDownloads />,
        title: "엑셀 다운로드",
      },
      {
        path: "/users",
        element: <UserListPage />,
        title: "회원 리스트 관리",
      },
      {
        path: "/user-collections",
        element: <UserCollectionListPage />,
        title: "회원 콜렉션 조회",
      },
      { path: "/points", element: <PointManagementPage />, title: "포인트 관리" },
      {
        path: "/points/:userId",
        element: <UserPointListPage />,
        title: "회원 포인트 관리",
      },
      {
        path: "/coupon-codes",
        element: <CouponCodeManagementPage />,
        title: "쿠폰 코드 관리",
      },
      {
        path: "/coupon",
        element: <CouponManagementPage />,
        title: "쿠폰 관리",
      },
      {
        path: "/notices",
        element: <NoticeListPage />,
        title: "공지사항 관리",
      },
      {
        path: "/penalty",
        element: <PenaltyListPage />,
        title: "패널티 관리",
      },
      {
        path: "/event",
        element: <EventListPage />,
        title: "이벤트 관리",
      },
      {
        path: "/finance-manager",
        element: <FinanceManagerPage />,
        title: "재무 관리",
      },
      {
        path: "/point-report",
        element: <PointReport />,
        title: "포인트 현황",
      },
      {
        path: "/settlement-manager",
        element: <SettlementManager />,
        title: "정산 관리",
      },
      {
        path: "/buy-price-offer-manager",
        element: <BuyPriceOfferManager />,
        title: "구매 가격 제안 관리",
      },
      {
        path: "/payment-area-manager",
        element: <PaymentAreaManager />,
        title: "결제 영역 관리",
      },
      {
        path: "/kakaku/product-mapping-manager",
        element: <KakakuProductMappingManager />,
        title: "카카쿠 상품 맵핑 관리",
      },
      {
        path: "/kream/product-mapping-manager",
        element: <KreamProductMappingManager />,
        title: "크림 상품 맵핑 관리",
      },
      {
        path: "/serial",
        element: <SerialNumberSearchPage />,
        title: "시리얼 넘버 관리",
      },
      {
        path: "/exchange-rate/fees",
        element: <ExchangeRateFeesListPage />,
        title: "판매가 환율 관리",
      },
      {
        path: "/upgrade-service-orders",
        element: <UpgradeServiceListPage />,
        title: "수리 주문 관리",
      },
      {
        path: "/faq",
        element: <FaqListPage />,
        title: "자주 묻는 질문 관리",
      },
      {
        path: "/term",
        element: <TermListPage />,
        title: "약관 관리",
      },
      {
        path: "/product-tracking-logs",
        element: <ProductTrackingLogListScreen />,
        title: "입고/출고 Log",
      },
      /*
      // 2023.12.01 Justin
      // 미사용 입출고 관리 기능 제거
      {
        path: "/product-tracking/daily-expect",
        element: <ProductTrackingDailyExpectScreen />,
        title: "일일 입출고 상품",
      },
      {
        path: "/product-tracking/daily-cross-checks",
        element: <ProductTrackingDailyCrossCheckScreen />,
        title: "일일대사",
      },
      */
      /* {
        path: "/fee-policy/brand",
        element: <BrandFeePolicyManager />,
        title: "브랜드 수수료 관리",
      },
      {
        path: "/fee-policy/model",
        element: <ModelFeePolicyManager />,
        title: "모델 수수료 관리",
      },
      {
        path: "/fee-policy/detailed-model",
        element: <DetailedModelFeePolicyManager />,
        title: "상세 모델 수수료 관리",
      },
      {
        path: "/fee-policy/product",
        element: <ProductFeePolicyManager />,
        title: "상품 수수료 관리",
      },*/
      {
        path: "/fee-policy/fee",
        element: <FeePolicyManager />,
        title: "수수료 관리",
      },
      {
        path: "/product-stock",
        element: <ProductStockManager />,
        title: "재고 관리",
      },
      {
        path: "/news-letter",
        element: <NewsLetterManager />,
        title: "뉴스 관리",
      },
      {
        path: "/order-reviews",
        element: <Review />,
        title: "후기 관리",
      },
      {
        path: "/translation-columns",
        element: <TranslationColumnManager />,
        title: "번역 컬럼 관리",
      },
      {
        path: "/translation",
        element: <TranslationManager />,
        title: "번역 관리",
      },
      {
        path: "/diagnoses/print",
        element: <DiagnosisPrintOpen />,
        title: "진단서 발급 테스트 화면",
      },
      {
        path: "/diagnoses/issue-list",
        element: <IssueList />,
        title: "진단서 발급 대상",
      },
      {
        path: "/diagnoses/issue-history",
        element: <IssueHistory />,
        title: "진단서 발급 내역",
      },
      {
        path: "/product-requests",
        element: <ProductRequests />,
        title: "트레이드업 신청 내역",
      },
      {
        path: "/admin-users",
        element: <AdminUserListPage />,
        title: "어드민 회원 관리",
      },
      {
        path: "/admin-groups",
        element: <AdminGroupListPage />,
        title: "어드민 그룹 관리",
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]

function flattenRoutes(routes: any[]) {
  return _.flatMap(routes, (route: any) => {
    return [route, ...(route.children ? route.children : [])]
  })
}

export function findRoute(path: string) {
  return flattenRoutes(authRouteInfo).find((routeInfo) => {
    return path === routeInfo.path
  })
}

export function getAside(menus: string[]) {
  const menuList: { title: string; children: { path: string; title: string }[] }[] = []
  if (menus.indexOf("회원관리") !== -1) {
    menuList.push({
      title: "회원 관리",
      children: [
        { path: "/users", title: "회원 리스트" },
        { path: "/user-collections", title: "회원 콜렉션" },
        { path: "/points", title: "포인트 관리" },
        { path: "/coupon-codes", title: "쿠폰코드 관리" },
      ],
    })
  }

  if (menus.indexOf("판매관리") !== -1) {
    menuList.push({
      title: "판매 관리",
      children: [
        { path: "/sell-orders/consignment", title: "위탁판매" },
        { path: "/sell-orders/before-diagnosis", title: "진단전판매" },
        { path: "/sell-orders/guaranteed", title: "재매입상품판매" },
      ],
    })
  }

  if (menus.indexOf("진단관리") !== -1) {
    menuList.push({
      title: "진단 관리",
      children: [
        { path: "/diagnoses/consignment", title: "위탁판매" },
        { path: "/diagnoses/before-diagnosis", title: "진단전판매" },
        { path: "/diagnoses/guaranteed-diagnosis", title: "재매입상품" },
        { path: "/diagnoses/issue-list", title: "진단서 발급 대상" },
        { path: "/diagnoses/issue-history", title: "진단서 발급 내역" },
      ],
    })
  }

  if (menus.indexOf("상품관리") !== -1) {
    menuList.push({
      title: "상품 관리",
      children: [
        { path: "/all-products", title: "전체 상품" },
        { path: "/products", title: "판매중 상품" },
        { path: "/pre-products", title: "상품화" },
        { path: "/upgrade-service-orders", title: "수리 주문" },
        { path: "/provisional-pre-settlements", title: "선정산 가견적" },
      ],
    })
  }

  if (menus.indexOf("구매관리") !== -1) {
    menuList.push({
      title: "구매 관리",
      children: [
        { path: "/buy-orders", title: "구매주문 관리" },
        { path: "/buy-order-refunds", title: "취소/반품/환불" },
      ],
    })
  }

  if (menus.indexOf("배송관리") !== -1) {
    menuList.push({
      title: "배송 관리",
      children: [{ path: "/buy-order-shippings", title: "구매배송" }],
    })
  }

  if (menus.indexOf("페널티관리") !== -1) {
    menuList.push({
      title: "패널티 관리",
      children: [{ path: "/penalty", title: "패널티 리스트" }],
    })
  }

  if (menus.indexOf("콘텐츠관리") !== -1) {
    menuList.push({
      title: "콘텐츠 관리",
      children: [
        { path: "/shop-manager", title: "샵 화면" },
        { path: "/magazine-manager", title: "매거진" },
        { path: "/push-manager", title: "앱 푸시" },
        { path: "/event", title: "이벤트" },
        { path: "/news-letter", title: "뉴스" },
        { path: "/order-reviews", title: "후기" },
      ],
    })
  }

  if (menus.indexOf("정책관리") !== -1) {
    menuList.push({
      title: "정책 관리",
      children: [
        {
          path: "/fee-policy/fee",
          title: "수수료 관리",
        },
        /*{
          path: "/fee-policy/brand",
          title: "브랜드 수수료 관리",
        },
        {
          path: "/fee-policy/model",
          title: "모델 수수료 관리",
        },
        {
          path: "/fee-policy/detailed-model",
          title: "상세 모델 수수료 관리",
        },
        {
          path: "/fee-policy/product",
          title: "상품 수수료 관리",
        },*/
        { path: "/coupon", title: "쿠폰 관리" },
      ],
    })
  }

  if (menus.indexOf("DB관리") !== -1) {
    menuList.push({
      title: "DB 관리",
      children: [
        { path: "/brands", title: "브랜드" },
        { path: "/model-manager/register", title: "모델" },
        { path: "/price-manager", title: "시세" },
        { path: "/retail-price-manager", title: "공식 출시가 관리" },
        { path: "/kakaku/product-mapping-manager", title: "카카쿠 상품 맵핑" },
        { path: "/kream/product-mapping-manager", title: "크림 상품 맵핑" },
        { path: "/serial", title: "시리얼 넘버" },
        { path: "/exchange-rate/fees", title: "판매가 환율 괸리" },
      ],
    })
  }

  if (menus.indexOf("운영관리") !== -1) {
    menuList.push({
      title: "운영 관리",
      children: [
        { path: "/notices", title: "공지사항" },
        { path: "/faq", title: "FAQ" },
        { path: "/term", title: "약관/이용정책" },
        { path: "/buy-price-offer-manager", title: "구매 가격 제안 관리" },
        { path: "/payment-area-manager", title: "결제 영역 관리" },
        { path: "/product-requests", title: "트레이드업 신청 내역" },
      ],
    })
  }

  if (menus.indexOf("후기관리") !== -1) {
    menuList.push({
      title: "후기 관리",
      children: [{ path: "/order-reviews", title: "후기" }],
    })
  }

  if (menus.indexOf("정산관리") !== -1) {
    menuList.push({
      title: "정산 관리",
      children: [
        { path: "/finance-manager", title: "재무" },
        { path: "/settlement-manager", title: "정산" },
        { path: "/point-report", title: "포인트 현황" },
      ],
    })
  }

  if (menus.indexOf("상품입출고관리") !== -1) {
    menuList.push({
      title: "상품 입출고 관리",
      children: [
        { path: "/product-tracking-logs", title: "입고/출고 Log" },
        /*
        // 2023.12.01 Justin
        // 미사용 입출고 관리 기능 제거
        { path: "/product-tracking/daily-expect", title: "일일 입출고 상품" },
        { path: "/product-tracking/daily-cross-checks", title: "일일대사" },
        */
        { path: "/product-stock", title: "재고 관리" },
      ],
    })
  }

  if (menus.indexOf("기타") !== -1) {
    menuList.push({
      title: "기타",
      children: [
        { path: "/app-versions", title: "앱 버전 관리" },
        { path: "/excel-downloads", title: "엑셀 다운로드" },
      ],
    })
  }

  if (menus.indexOf("글로벌") !== -1) {
    menuList.push({
      title: "글로벌",
      children: [
        { path: "/translation", title: "번역 관리" },
        { path: "/translation-columns", title: "번역 컬럼 관리" },
      ],
    })
  }

  if (menus.indexOf("어드민관리") !== -1) {
    menuList.push({
      title: "어드민 관리",
      children: [
        { path: "/admin-users", title: "어드민 회원 관리" },
        { path: "/admin-groups", title: "어드민 그룹 관리" },
      ],
    })
  }

  //테스트중인 기능은 여기에 넣기
  return menuList
}

export default authRouteInfo
