import { BoxHorizontal, BoxVertical } from "@viver-inc/viver-ui"
import { Dispatch, FunctionComponent, SetStateAction } from "react"
import { TemplateEditorImage } from "./TemplateEditorImage"
import { EventFormStateType } from "types/EventFormStateType"
import { TemplateEditorNoticeImage } from "./TemplateEditorNoticeImage"
import { TemplateEditorHeaderImage } from "./TemplateEditorHeaderImage"
import { TemplateEditorProductTab } from "./TemplateEditorProductTab"
import { TemplatePreview } from "./TemplatePreview"

export const TemplateEditor: FunctionComponent<{
  eventFormState: EventFormStateType
  setEventFormState: Dispatch<SetStateAction<EventFormStateType>>
  onCheckImageVideoCount: (type:string) => boolean
}> = ({ eventFormState, setEventFormState , onCheckImageVideoCount}) => {
  if (eventFormState.bodyType !== "TEMPLATE") {
    return null
  }

  return (
    <BoxHorizontal>
      <BoxVertical flex={1}>
        {/*이미지 타입*/}
        <TemplateEditorImage
          templateBodyImage={eventFormState.templateBodyImage}
          setTemplateBodyImage={(templateBodyImage) => {
            setEventFormState((state) => ({ ...state, templateBodyImage }))
          }}
          onCheckImageVideoCount={onCheckImageVideoCount}
        />
        <BoxVertical height={24}>{null}</BoxVertical>
        {/*유의사항 */}
        <TemplateEditorNoticeImage
          templateBodyNoticeImage={eventFormState.templateBodyNoticeImage}
          setTemplateBodyNoticeImage={(templateBodyNoticeImage) => {
            setEventFormState((state) => ({ ...state, templateBodyNoticeImage }))
          }}
        />
        <BoxVertical height={24}>{null}</BoxVertical>
        {/*헤더 이미지*/}
        <TemplateEditorHeaderImage
          templateBodyHeaderImage={eventFormState.templateBodyHeaderImage}
          setTemplateBodyHeaderImage={(templateBodyHeaderImage) => {
            setEventFormState((state) => ({ ...state, templateBodyHeaderImage }))
          }}
        />
        <BoxVertical height={24}>{null}</BoxVertical>
        {/*상품*/}
        <TemplateEditorProductTab
          templateBodyProductTab={eventFormState.templateBodyProductTab}
          setTemplateBodyProductTab={(templateBodyProductTab) => {
            setEventFormState((state) => ({ ...state, templateBodyProductTab }))
          }}
        />
      </BoxVertical>
      <BoxHorizontal width={20}>{null}</BoxHorizontal>
      {/*미리보기*/}
      <BoxHorizontal width={400}>
        <TemplatePreview eventFormState={eventFormState} />
      </BoxHorizontal>
    </BoxHorizontal>
  )
}
