import { DateTime } from "luxon"

export type SktUniversePassType = {
  id: number
  sktUserId: string
  ciCode: string
  userId: number
  contractId: string
  signDate?: DateTime | null
  startDate?: DateTime | null
  endDate?: DateTime | null
  expireDate?: DateTime | null
  discountUseCount?: number
}

export type SktUniversePassHistoryType = {
  id: number
  status: SktUniversePassStatus
  userId: number
  signDate?: DateTime | null
  startDate?: DateTime | null
  endDate?: DateTime | null
  expireDate?: DateTime | null
  cancelRequestDate?: DateTime | null
  cancelTypeCode?: string | null
  cancelReasonCode?: string | null
  revokeCancelRequestDate?: DateTime | null
  revokeCancelReasonCode?: string | null
}

export const enum SktUniversePassStatus {
  구독 = "구독",
  해지 = "해지",
  해지_예약 = "해지 예약",
  해지_취소 = "해지 취소",
}
