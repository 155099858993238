import { additionalImageLabels, AttachmentType } from "types/DiagnosisType"
import React, { useEffect, useState } from "react"
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material"
import { toast } from "react-toastify"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import ImageUploadButton from "../../components/ImageUploadButton"
import { upload } from "../../apis/resourceAPI"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableContainer from "@mui/material/TableContainer"
import { toDateTimeStr } from "../../utils/datetimeUtil"
import styled from "@emotion/styled"

export declare interface ImageLabelType {
  label: string
  required: boolean
  uploadName: string
  onDisplaySelectable?: boolean
}

interface AttachmentImageListProps {
  init?: AttachmentType[]
  defaultImageLabels: ImageLabelType[]
  max: number
  save: Function
  readOnly?: boolean
  checkInput?: boolean
  disabled?: boolean
}

const AttachmentImageList: React.FC<AttachmentImageListProps> = ({
  init,
  defaultImageLabels,
  max,
  save,
  checkInput = true,
  readOnly = false,
  disabled = false,
}) => {
  const [defaultSlot, setDefaultSlot] = useState<AttachmentType[]>(init || [])
  const [additionalSlot, setAdditionalSlot] = useState<AttachmentType[]>([])
  const [isShowImageTooltip, setIsShowImageTooltip] = useState<boolean>(false)
  const [bulkUploading, setBulkUploading] = useState(false)

  useEffect(() => {
    setDefaultSlot(init?.filter((it) => defaultImageLabels.some((d) => d.label === it.label)) || [])
    setAdditionalSlot(init?.filter((it) => defaultImageLabels.every((d) => d.label !== it.label)) || [])
  }, [init, defaultImageLabels])

  const findLabelByUploadName = (uploadName: string): string | undefined => {
    const allLabels = [...defaultImageLabels, ...additionalImageLabels]
    const foundLabel = allLabels.find((item) => item.uploadName === uploadName)
    return foundLabel ? foundLabel.label : undefined
  }

  const handleAdd = async (files: FileList, label?: string) => {
    await upload(files).then((result) => {
      const newElement = {
        id: result.id!!,
        url: result.url,
        type: result.resourceContentType || "IMG",
        label: label || "추가",
        required: defaultImageLabels.find((it) => it.label == label)?.required,
        onDisplay: label !== "시리얼 넘버",
        createdAt: result.createdAt,
      } as AttachmentType

      if (defaultImageLabels.findIndex((d) => d.label === newElement.label) > -1) {
        setDefaultSlot((prev) => {
          const idx = prev.findIndex((it) => it.label == label)
          if (idx > -1) prev.splice(idx, 1)

          const updatedSlot: AttachmentType[] = [...prev, newElement]

          save(updatedSlot.concat(additionalSlot))
          return updatedSlot
        })
      } else {
        setAdditionalSlot((prev) => {
          const updatedSlot = [...prev]
          const idx = updatedSlot.findIndex((it) => it.label === label)

          if (idx > -1) {
            updatedSlot[idx] = newElement
          } else {
            // 해당 항목이 없으면 새로 추가
            updatedSlot.push(newElement)
          }

          const filledSlot: AttachmentType[] = fillUp(updatedSlot)

          save(defaultSlot.concat(filledSlot))
          return filledSlot
        })
      }
    })
  }

  // ----------------------------------------------------------------------
  // ### 일괄 업로드 함수를 따로 분리
  // ----------------------------------------------------------------------
  const handleUploadAll = async (files: FileList) => {
    const newAttachments: AttachmentType[] = []

    for (const file of Array.from(files)) {
      const nameWithoutExtension = file.name.substring(0, file.name.lastIndexOf("."))
      const label = findLabelByUploadName(nameWithoutExtension)

      if (!label) {
        continue
      }

      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(file)
      const result = await upload(dataTransfer.files)

      const newElement = {
        id: result.id!,
        url: result.url,
        type: result.resourceContentType || "IMG",
        label: label || "추가",
        required: defaultImageLabels.find((it) => it.label === label)?.required || false,
        onDisplay: label !== "시리얼 넘버",
        createdAt: result.createdAt,
      } as AttachmentType
      newAttachments.push(newElement)
    }

    const newDefaultSlot = [...defaultSlot]
    const newAdditionalSlot = [...additionalSlot]

    // 새로 업로드된 것들을 default / additional에 분류하여 추가 or 교체
    for (const attachment of newAttachments) {
      const isDefaultLabel = defaultImageLabels.some((d) => d.label === attachment.label)

      if (isDefaultLabel) {
        // 이미 같은 label이 있으면 교체, 없으면 추가
        const idx = newDefaultSlot.findIndex((it) => it.label === attachment.label)
        if (idx > -1) {
          newDefaultSlot[idx] = attachment
        } else {
          newDefaultSlot.push(attachment)
        }
      } else {
        const idx = newAdditionalSlot.findIndex((it) => it.label === attachment.label)
        if (idx > -1) {
          newAdditionalSlot[idx] = attachment
        } else {
          newAdditionalSlot.push(attachment)
        }
      }
    }

    // 추가 슬롯은 label 정렬
    const filledAdditional = fillUp(newAdditionalSlot)

    // 한 번에 state 업데이트 & save
    setDefaultSlot(newDefaultSlot)
    setAdditionalSlot(filledAdditional)

    save([...newDefaultSlot, ...filledAdditional])
  }

  const handleDelete = (id?: number) => {
    let idx = defaultSlot.findIndex((it) => it.id == id)
    if (idx > -1) {
      defaultSlot.splice(
        defaultSlot.findIndex((it) => it.id == id),
        1
      )
      setDefaultSlot(JSON.parse(JSON.stringify(defaultSlot)))
    }

    idx = additionalSlot.findIndex((it) => it.id == id)
    if (idx > -1) {
      // 삭제
      additionalSlot.splice(
        additionalSlot.findIndex((it) => it.id == id),
        1
      )
      // 재정렬
      const newOptionalSlot = fillUp(additionalSlot)
      setAdditionalSlot(JSON.parse(JSON.stringify(newOptionalSlot)))
    }
    save(defaultSlot.concat(fillUp(additionalSlot)))
  }

  const handleCheck = (event: any, attachment?: AttachmentType) => {
    if (attachment) {
      setDefaultSlot(
        defaultSlot.map((it) => {
          if (attachment.id == it.id) it.onDisplay = event.target.checked
          return it
        })
      )

      setAdditionalSlot(
        additionalSlot.map((it) => {
          if (attachment.id == it.id) it.onDisplay = event.target.checked
          return it
        })
      )
    }
  }

  const addAttachment = () => {
    if (defaultSlot.length + additionalSlot.length >= max) {
      toast.error(`최대 이미지 개수는 ${max}개 입니다`)
      return
    }
    const newElement = {
      id: 0,
      onDisplay: false,
      label:
        additionalSlot.findIndex((it) => it.label == `추가${additionalSlot.length + 1}`) > -1
          ? `추가${additionalSlot.length + 2}`
          : `추가${additionalSlot.length + 1}`,
    }
    // @ts-ignore
    setAdditionalSlot([...additionalSlot, newElement])
  }

  const fillUp = (slot: AttachmentType[]): AttachmentType[] => {
    return slot
      .filter((it) => it.id)
      .map((a, i) => {
        a.label = `추가${i + 1}`
        return a
      })
  }

  const move = (i: number, delta: number) => {
    if (delta == 1) {
      if (i < additionalSlot.length - 1) {
        let tmp = additionalSlot[i]
        additionalSlot[i] = additionalSlot[i + 1]
        additionalSlot[i + 1] = tmp
      }
    } else if (delta == -1) {
      if (i > 0) {
        let tmp = additionalSlot[i]
        additionalSlot[i] = additionalSlot[i - 1]
        additionalSlot[i - 1] = tmp
      }
    }
    setAdditionalSlot(fillUp(additionalSlot))
    save(defaultSlot.concat(fillUp(additionalSlot)))
  }

  const findByLabel = (label: string) => defaultSlot?.find((it) => it.label === label)
  const showImageUploadGuide = () => {
    setIsShowImageTooltip((prevState) => !prevState)
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "start", alignItems: "center", gap: 8 }}>
        <label
          style={{
            margin: "0 0 8px 0",
            gap: 6,
            display: "flex",
            justifyContent: "start",
            alignItems: "end",
          }}
          htmlFor={`contained-button-file-all`}
        >
          <input
            style={{ display: "none" }}
            accept="image/*|video/mp4"
            id="contained-button-file-all"
            multiple
            type="file"
            onChange={async (params) => {
              const filesArray = params.target.files
              if (!filesArray || filesArray.length === 0) return

              // 파일명(업로드명) 유효성 검증
              const validNames = [
                ...defaultImageLabels.map((item) => item.uploadName),
                ...additionalImageLabels.map((item) => item.uploadName),
              ]

              const hasInvalidFile = Array.from(filesArray).some((file) => {
                const nameWithoutExtension = file.name.substring(0, file.name.lastIndexOf("."))
                if (!validNames.includes(nameWithoutExtension)) {
                  toast.error(`파일명을 확인해주세요 : ${nameWithoutExtension}`)
                  return true
                }
                return false
              })

              if (hasInvalidFile) return

              setBulkUploading(true)
              await handleUploadAll(filesArray)
              setBulkUploading(false)

              params.target.value = ""
            }}
          />
          <Button variant={"contained"} size={"medium"} component="span" color={"success"} style={{ width: "180px" }}>
            이미지 일괄 업로드
          </Button>
        </label>
        <div>
          <label
            style={{ width: "200px", fontSize: 14, textDecoration: "underline", cursor: "pointer" }}
            onClick={showImageUploadGuide}
          >
            일괄 업로드 파일명 설정 가이드
          </label>
          <Dialog open={isShowImageTooltip} fullWidth onClose={showImageUploadGuide}>
            <DialogTitle>파일명 설정 가이드</DialogTitle>
            <DialogContent>
              <TableContainer style={{ maxHeight: 600, minHeight: 200 }}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">영역명</TableCell>
                      <TableCell align="center">파일명</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {defaultImageLabels.map((item) => {
                      return (
                        <TableRow>
                          <TableCell align={"center"}>{item.label}</TableCell>
                          <TableCell align={"center"}>{item.uploadName}</TableCell>
                        </TableRow>
                      )
                    })}
                    <TableRow>
                      <TableCell align={"center"}>추가1~추가16</TableCell>
                      <TableCell align={"center"}>8~23</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button size={"small"} variant={"contained"} onClick={showImageUploadGuide}>
                닫기
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>

      <div style={{ position: "relative" }}>
        <Grid container className={"ta-center"}>
          {defaultImageLabels?.map((imageLabel, idx) => (
            <Grid
              item
              xs={2}
              sx={{
                border: "1px solid #aaa",
                overflow: "auto",
              }}
            >
              <Typography fontWeight={"bold"} style={{ marginTop: "0.5rem" }}>
                {imageLabel.label}
                {imageLabel.required ? " *" : ""}
                {imageLabel.label === "전면" ? " (대표 사진)" : ""}
              </Typography>
              {checkInput && (
                <FormControlLabel
                  control={<Checkbox style={{ padding: "0 9px" }} />}
                  label="노출"
                  disabled={readOnly || imageLabel.onDisplaySelectable == false}
                  checked={findByLabel(imageLabel.label)?.onDisplay == true}
                  onChange={(event) => handleCheck(event, findByLabel(imageLabel.label))}
                />
              )}
              <CustomHr />
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "180px" }}>
                {readOnly ? (
                  findByLabel(imageLabel.label)?.url ? (
                    <img src={findByLabel(imageLabel.label)?.url} alt={imageLabel.label} height={"132px"} />
                  ) : (
                    <></>
                  )
                ) : (
                  <ImageUploadButton
                    height={"132px"}
                    width={"132px"}
                    imageUrl={findByLabel(imageLabel.label)?.url}
                    handleImage={(files) => handleAdd(files, imageLabel.label)}
                    handleDeleteImage={() => handleDelete(findByLabel(imageLabel.label)?.id)}
                  />
                )}
              </div>
              <CustomHr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "0.5rem",
                }}
              >
                <Typography variant={"caption"} textAlign={"center"} fontWeight={"bold"}>
                  {toDateTimeStr(findByLabel(imageLabel.label)?.createdAt)}
                </Typography>
              </div>
            </Grid>
          ))}
          {additionalSlot?.map((it, i) => (
            <Grid
              item
              xs={2}
              sx={{
                border: "1px solid #aaa",
                overflow: "auto",
              }}
            >
              <Typography fontWeight={"bold"} style={{ marginTop: "0.5rem" }}>
                <Button style={{ padding: 0 }} onClick={() => move(i, -1)}>
                  ⇦
                </Button>
                {it.label}
                <Button style={{ padding: 0 }} onClick={() => move(i, 1)}>
                  ⇨
                </Button>
              </Typography>
              {checkInput && (
                <FormControlLabel
                  control={<Checkbox style={{ padding: "0 9px" }} />}
                  label="노출"
                  checked={it.onDisplay}
                  onChange={(event) => handleCheck(event, it)}
                />
              )}
              <CustomHr />
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "180px" }}>
                {readOnly ? (
                  <img src={it.url} alt={it.label} height={"132px"} />
                ) : (
                  <ImageUploadButton
                    height={"132px"}
                    width={"132px"}
                    imageUrl={it.url}
                    handleImage={(files) => handleAdd(files, it.label)}
                    handleDeleteImage={() => handleDelete(it.id)}
                  />
                )}
              </div>
              <CustomHr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "0.5rem",
                }}
              >
                <Typography variant={"caption"} textAlign={"center"} fontWeight={"bold"}>
                  {toDateTimeStr(it.createdAt)}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
        {bulkUploading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 9999,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pointerEvents: "all",
            }}
          >
            <CircularProgress size={60} thickness={5} />
          </div>
        )}
      </div>
      <div style={{ textAlign: "right" }}>
        {readOnly || (
          <Button size={"medium"} variant={"outlined"} startIcon={<AddCircleIcon />} onClick={addAttachment}>
            추가
          </Button>
        )}
      </div>
    </>
  )
}

export default AttachmentImageList

const CustomHr = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 0.5rem 0;
`
